import { Injectable, Injector } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, of } from 'rxjs';
import { startWith } from 'rxjs/internal/operators/startWith';
import { catchError, finalize } from 'rxjs/operators';
import { WorkspaceState } from 'src/app/_shared/state/workspace.state';
import { AppState } from 'src/app/app.state';
import { SearchService } from 'src/app/workspace/service/search.service';
import { PropertyState } from 'src/app/workspace/state/property.state';
import { ProspektrApi } from '../api/prospektr.api';
import { PublicRecordApi } from '../api/public-record.api';
import { Json } from '../interface/common.interface';
import { FilterService } from './filter.service';

@Injectable({
  providedIn: 'root',
})
export class PropertyService {
  constructor(
    private publicRecordApi: PublicRecordApi,
    private propertyState: PropertyState,
    private snackBar: MatSnackBar,
    private workspaceState: WorkspaceState,
    private appState: AppState,
    private injector: Injector,
    private filterService: FilterService,
    private prospektrApi: ProspektrApi,
  ) { }

  hasSharedPMXProperty = true;

  getPropertyDetails(propertyObj) {
    this.workspaceState.propertyDetailsSheetLoaderValue = true;
    const searchService = this.injector.get(SearchService);

    this.publicRecordApi.getPropertyDetails(propertyObj).subscribe(
      (res) => {
        this.workspaceState.propertyDetailsSheetLoaderValue = false;
        if (res && res.status && res.statusCode === 200 && res.data) {
          let filterStyleClassfromlist = this.getPropertyStatus(res.data?.basic);
          res.data.basic['filterStyleClass'] = filterStyleClassfromlist;
          const homeValueParams = {
            City: res.data.basic?.City,
            State: res.data.basic?.StateOrProvince,
            StreetName: this.getStreetName(res.data.basic),
            StreetNumber: res.data.basic?.StreetNumber,
            Zip: res.data.basic?.PostalCode,
          };
          this.getHomeValue(homeValueParams);
          this.propertyState.activePropertyDetailsValue = res.data;
          if (this.propertyState.sharedPMXPropertyValue && this.hasSharedPMXProperty) {
            let fullAddress = res.data.basic.Address;
            searchService.getPropertyList(fullAddress);
            this.hasSharedPMXProperty = false;
          }
        } else {
          this.propertyState.activePropertyDetailsValue = {};
          if (this.propertyState.sharedPMXPropertyValue) {
            if (this.appState.authTokenValue?.idToken) {
              searchService.getLastSearch();
            } else {
              searchService.fetchUserLocation();
            }
            // this.openSnackBar('Requested property could not be found', 'snackbar-error');
            this.propertyState.sharedPMXPropertyValue = null;
          }
        }
      },
      (err) => {
        this.workspaceState.propertyDetailsSheetLoaderValue = false;
        this.propertyState.activePropertyDetailsValue = {};
        if (this.propertyState.sharedPMXPropertyValue) {
          if (this.appState.authTokenValue?.idToken) {
            searchService.getLastSearch();
          } else {
            searchService.fetchUserLocation();
          }
          this.propertyState.sharedPMXPropertyValue = null;
        }
        // this.openSnackBar('Requested property could not be found', 'snackbar-error');
      }
    );
    //  }
  }

  getStreetName(res) {
    let streetName = res.StreetName;
    if (res.StreetDirPrefix) streetName = res.StreetDirPrefix + ' ' + streetName;
    if (res.StreetSuffix) streetName = streetName + ' ' + res.StreetSuffix;
    if (res.StreetDirSuffix) streetName = streetName + ' ' + res.StreetDirSuffix;
    return streetName;
  }

  getHomeValue(params) {
    this.publicRecordApi.getHomeValue(params).subscribe((res) => {
      if (res && res.status && res.status.message && res.status.message === 'Success' && res.data) {
        this.propertyState.activeHomeValueData = res.data;
      } else {
        this.propertyState.activeHomeValueData = null;
      }
    });
  }

  getPropertyStatus(property: any): string {
    let propertyFiltered = this.filterService.isBasePropertyFiltered(property);
    if (propertyFiltered.status) {
      if (propertyFiltered.filter.includes('distressed')) return 'distressed';
      if (propertyFiltered.filter.includes('hud')) return 'hud';
      if (propertyFiltered.filter.includes('listing')) return 'listing';
      if (propertyFiltered.filter.includes('sold')) return 'sold';
      return 'filtered';
    }
    return 'nonFiltered';
  }

  // filterPropertyStatus(property) {
  //   let validFilterList = [];
  //   let status = true;
  //   if (
  //     property.Lst_StandardStatus == 'Active' ||
  //     (property.Lst_StandardStatus == 'Active Under Contract' && property.Lst_speciallistingconditions == 'Standard')
  //   ) {
  //     status = true;
  //     validFilterList.push('listing');
  //   }
  //   if (
  //     (property.Lst_StandardStatus == 'Active' || property.Lst_StandardStatus == 'Active Under Contract') &&
  //     property.Lst_specialListingConditions == 'Standard'
  //   ) {
  //     status = true;
  //     validFilterList.push('listing');
  //   }
  //   if (
  //     property.Lst_StandardStatus == 'Sold' &&
  //     property.Lst_specialListingConditions == 'Standard' &&
  //     property.Fcl_Status == null
  //   ) {
  //     status = true;
  //     validFilterList.push('sold');
  //   }
  //   if (property.Lst_StandardStatus == 'Active' && property.Lst_specialListingConditions == 'Short Sale') {
  //     status = true;
  //     validFilterList.push('distressed');
  //   }
  //   if (
  //     property.Lst_StandardStatus == 'Sold' &&
  //     property.Lst_specialListingConditions == 'Standard' &&
  //     property.Fcl_Status == 'AUCTION'
  //   ) {
  //     status = true;
  //     validFilterList.push('distressed');
  //   }
  //   if (property.Lst_StandardStatus == 'Pending' && property.Lst_specialListingConditions == 'Real Estate Owned') {
  //     status = true;
  //     validFilterList.push('distressed');
  //   }
  //   if (
  //     property.Lst_StandardStatus == 'Active' &&
  //     property.Lst_specialListingConditions == 'Standard,Fannie Mae Owned'
  //   ) {
  //     status = true;
  //     validFilterList.push('hud');
  //   }
  //   return { status, filter: validFilterList };
  // }

  getAddressComponentsFromStandardAddress(standardAddress: string): Json {
    let addressComponentList = standardAddress?.split(',');

    if (!addressComponentList || addressComponentList?.length < 3) return null;

    let stateAndZip = addressComponentList[2];
    let stateAndZipList = stateAndZip?.split(' ');
    let addressComponent: any = {
      StreetAddress: addressComponentList[0],
      City: addressComponentList[1],
      State: stateAndZipList[1],
      PostalCode: stateAndZipList[2],
    };
    return addressComponent;
  }

  getToken(): Observable<any> {
    return new Observable((observer) => {
      this.publicRecordApi.getToken().subscribe(
        (res) => {
          if (res && res.data && res.data.token) {
            observer.next(res.data.token);
          }
        },
        (err) => {
          observer.error(err.error);
        }
      );
    });
  }

  openSnackBar(message, style) {
    if (message) {
      this.snackBar.open(message, '', {
        duration: 3000,
        panelClass: [style ? style : ''],
        verticalPosition: 'top',
      });
    }
  }


  addSellerProperty
    (params): Observable<any> {
    return this.prospektrApi.addSellerProperty(params).pipe(
      startWith({ isLoading: true }), // Emits loading state first
      catchError((err) => {
        return of({ error: true, message: 'Failed to add seller property', details: err });
      }),
      finalize(() => { })
    );
  }
}
