import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { finalize, skip } from 'rxjs/operators';
import constants from 'src/constant.json';
import { AuthorizeService } from './_core/access-control/authorize.service';
import { ProspektrApi } from './_shared/api/prospektr.api';
import { ActivateModalComponent } from './_shared/component/activate-account/activate-modal.component';
import { EmailSubscribeDialogComponent } from './_shared/component/email-subscribe-dialog/email-subscribe-dialog.component';
import { SignupDialogComponent } from './_shared/component/login-signup/signup-dialog.component';
import { WorkspaceConfig } from './_shared/config/workspace.config';
import { WorkspaceMode } from './_shared/interface/workspace.interface';
import { DealAnalysisState } from './_shared/state/deal-analysis.state';
import { UserState } from './_shared/state/user.state';
import { WorkspaceState } from './_shared/state/workspace.state';
import { AppState } from './app.state';
import { ConfirmDialogComponent } from './shared/modules/alerts/confirm-dialog/confirm-dialog.component';
import { CookieStorageService } from './shared/service/cookie-storage.service';
import { VerifyLoginService } from './verify-login/verify-login.service';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  appBaseUrl: string;
  appCompanyAlias: string;
  getConnected: boolean = false;
  public signupDialogRef: MatDialogRef<SignupDialogComponent> | null = null;

  constructor(
    private appState: AppState,
    private matDialog: MatDialog,
    private prospektrApi: ProspektrApi,
    private workspaceConfig: WorkspaceConfig,
    private workspaceState: WorkspaceState,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private dealAnalysisState: DealAnalysisState,
    public authorizeService: AuthorizeService,
    private cookieStorageService: CookieStorageService,
    private userState: UserState,
    private verifyLogInService: VerifyLoginService
  ) {
    this.initSubscription();
  }

  initSubscription() {
    this.activatedRoute.queryParams.subscribe((res) => {

      if (res && res.login) {
        this.openLogInDialog();
      }
      if (res && res.verify) {
        this.openActivateAccountDialog();
      }

      if (res['deal-analysis-property-id'] || res['deal-analysis-id']) {
        this.checkDealAnalysisSheet(res);
      } else {
        this.dealAnalysisState.openDealAnalysisSheetValue = false;
      }
      if (res['subscribe'] && res['userId'] && res['type']) {
        this.openSubscribeDialog(res);
      }
      if (Object.keys(res).length != 0) {
        res = Object.keys(res)
          .filter((key) => key !== 'login')
          .reduce((obj, key) => {
            obj[key] = res[key];
            return obj;
          }, {});
        localStorage.setItem('appQueryParams', JSON.stringify(res));
      }

      if (res?.idToken || res?.accessToken) {
        const queryParams = res;
        delete queryParams['idToken'];
        delete queryParams['accessToken'];
        delete queryParams['refreshToken'];
        this.router.navigate([], {
          queryParams: queryParams,
          replaceUrl: true,
          state: { skipUpdate: true }, // TODO: check skipUpdate initially on this subscription
        });
      }
    });

    this.userState.userDetail$.subscribe((res) => {
      if (res?.userType || (res?.roles && res.roles.length > 0)) {
        let userRole = res?.userType ? res.userType : res.roles[0];
        this.userState.getUserRoleValue = userRole;
        this.cookieStorageService.setCookie('userRole', userRole, 7);
      } else {
        this.userState.getUserRoleValue = null;
      }
      if (res?.userType == 'investor' && !this.getConnected) {
        this.getConnected = true;
        this.getConnectionInfo();
      }
    });

    this.appState.companyInfo$.subscribe((res) => {
      if (res && res.appTheme) {
        this.setPageContentFromBaseUrl();
        res.appTheme.primaryColor &&
          document.documentElement.style.setProperty('--global-color-primary', res.appTheme.primaryColor);
        res.appTheme.primaryHoverColor &&
          document.documentElement.style.setProperty('--global-color-primary-hover', res.appTheme.primaryHoverColor);
        res.appTheme.primaryTextColor &&
          document.documentElement.style.setProperty('--global-color-primary-text', res.appTheme.primaryTextColor);
        res.appTheme.primaryLightColor &&
          document.documentElement.style.setProperty('--global-color-primary-light', res.appTheme.primaryLightColor);
        res.appTheme.primaryTextLightColor &&
          document.documentElement.style.setProperty(
            '--global-color-primary-text-light',
            res.appTheme.primaryTextLightColor
          );
        res.appTheme.secondaryColor &&
          document.documentElement.style.setProperty('--global-color-secondary', res.appTheme.secondaryColor);
        res.appTheme.secondaryHoverColor &&
          document.documentElement.style.setProperty(
            '--global-color-secondary-hover',
            res.appTheme.secondaryHoverColor
          );
        res.appTheme.secondaryLightColor &&
          document.documentElement.style.setProperty(
            '--global-color-secondary-light',
            res.appTheme.secondaryLightColor
          );
        res.appTheme.accentColor &&
          document.documentElement.style.setProperty('--global-color-accent', res.appTheme.accentColor);
        res.appTheme.headingFont &&
          document.documentElement.style.setProperty('--global-font-heading', res.appTheme.headingFont);
        res.appTheme.subHeadingFont &&
          document.documentElement.style.setProperty('--global-font-sub-heading', res.appTheme.subHeadingFont);
        res.appTheme.alternateColor &&
          document.documentElement.style.setProperty('--global-color-alternateColor', res.appTheme.alternateColor);
      }
      if (res?.workspaceModes && res?.workspaceModes.length) {
        this.workspaceState.workspaceModeValue = res.workspaceModes;
        let defaultModeIndex = res.workspaceModes.findIndex((mode: WorkspaceMode) => {
          return mode.isDefault;
        });
        this.workspaceState.activeWorkspaceModeValue = res.workspaceModes[defaultModeIndex >= 0 ? defaultModeIndex : 0];
      } else {
        this.workspaceState.workspaceModeValue = [this.workspaceConfig.defaultMode];
        this.workspaceState.activeWorkspaceModeValue = this.workspaceConfig.defaultMode;
      }
      if (this.workspaceState.activeWorkspaceModeValue.markerColor) {
        this.workspaceState.activeWorkspaceModeValue.markerColor?.clustered &&
          document.documentElement.style.setProperty(
            '--global-color-map-marker-clustered',
            this.workspaceState.activeWorkspaceModeValue.markerColor?.clustered
          );
        this.workspaceState.activeWorkspaceModeValue.markerColor?.nonFiltered &&
          document.documentElement.style.setProperty(
            '--global-color-map-marker-non-filtered',
            this.workspaceState.activeWorkspaceModeValue.markerColor?.nonFiltered
          );
        this.workspaceState.activeWorkspaceModeValue.markerColor?.filtered &&
          document.documentElement.style.setProperty(
            '--global-color-map-marker-filtered',
            this.workspaceState.activeWorkspaceModeValue.markerColor?.filtered
          );
        this.workspaceState.activeWorkspaceModeValue.markerColor?.sold &&
          document.documentElement.style.setProperty(
            '--global-color-map-marker-sold',
            this.workspaceState.activeWorkspaceModeValue.markerColor?.sold
          );
        this.workspaceState.activeWorkspaceModeValue.markerColor?.listing &&
          document.documentElement.style.setProperty(
            '--global-color-map-marker-listing',
            this.workspaceState.activeWorkspaceModeValue.markerColor?.listing
          );
        this.workspaceState.activeWorkspaceModeValue.markerColor?.hud &&
          document.documentElement.style.setProperty(
            '--global-color-map-marker-hud',
            this.workspaceState.activeWorkspaceModeValue.markerColor?.hud
          );
        this.workspaceState.activeWorkspaceModeValue.markerColor?.distressed &&
          document.documentElement.style.setProperty(
            '--global-color-map-marker-distressed',
            this.workspaceState.activeWorkspaceModeValue.markerColor?.distressed
          );
      }
    });

    this.appState.authToken$.pipe(skip(1)).subscribe((res) => {
      if (!res) {
        localStorage.clear();
        sessionStorage.clear();
        this.cookieStorageService.clearAllCookies();
      } else if (res?.accessToken) {
        if (!this.userState.userDetails && this.appState.authTokenValue?.idToken && !this.appState.getAppLoadingState('verifyLogin')) {
          this.verifyLogInService.verifyLogin().subscribe()
        }
      }
    });
  }

  checkDealAnalysisSheet(res) {
    if (res['deal-analysis-property-id']) {
      this.dealAnalysisState.dealAnalysisPropertyIdValue = res['deal-analysis-property-id'];
      this.dealAnalysisState.openDealAnalysisSheetValue = true;
    } else if (res['deal-analysis-id']) {
      this.dealAnalysisState.dealAnalysisIdValue = res['deal-analysis-id'];
      this.dealAnalysisState.openDealAnalysisSheetValue = true;
    }
  }

  getBaseUrl(): string {
    let baseUrl = '';
    if (window && window.location && window.location.hostname) {
      baseUrl = window.location.hostname;
    }
    return baseUrl;
  }

  setPageContentFromBaseUrl() {
    const title: any = document.querySelector(`#title`);
    const link: any = document.querySelector(`link[rel*='icon']`);
    this.appState.appCompanyAliasValue = this.appState.companyInfoValue.alias;
    link.href = this.appState.companyInfoValue.favIcon || constants.COMPANY.prospektr.favicon;
    title.textContent = this.appState.companyInfoValue.name || constants.COMPANY.prospektr.name;
  }

  getClientInfoById(param) {
    this.prospektrApi.getClientInfoById(param).subscribe(
      (res) => {
        if (res && res.data && Object.keys(res.data) && Object.keys(res.data).length) {
          this.appState.clientInfoValue = res.data;
        } else {
          this.appState.clientInfoValue = null;
        }
      },
      (error) => {
        this.appState.clientInfoValue = null;
      }
    );
  }

  getConnectionInfo() {
    this.prospektrApi.getClientInfoByUserToken().subscribe(
      (res) => {
        // if (res && res.data && Object.keys(res.data) && Object.keys(res.data).length) {
        //   this.appState.connectionInfoValue = res.data;
        //   let response = res.data[0];
        //   this.appState.clientInfoSubValue = response;
        //   this.appState.clientInfoSubValue.client.logo.dark = response.client.logo.dark || constants.darkLogo;
        //   this.appState.clientInfoSubValue.client.logo.light = response.client.logo.light || constants.lightLogo;
        //   this.appState.clientInfoSubValue.client.appTheme.primaryColor = constants.appPrimaryColor;
        //   this.appState.clientInfoSubValue.client.appTheme.secondaryColor =
        //     response.client.appTheme.secondaryColor || constants.appSecondaryColor;
        //   this.appState.clientInfoSubValue.client.name = response.client.name || constants.APPLICATION_NAME;
        // }
        if (res && res.data && Object.keys(res.data).length) {
          this.appState.connectionInfoValue = res.data;
          let response = res.data[0];
          this.appState.clientInfoSubValue = response;

          // Ensure that response.client exists before accessing its properties
          if (response.client) {
            // Ensure that response.client.logo exists
            this.appState.clientInfoSubValue.client.logo = response.client.logo || {};
            this.appState.clientInfoSubValue.client.logo.dark = response.client.logo.dark || constants.darkLogo;
            this.appState.clientInfoSubValue.client.logo.light = response.client.logo.light || constants.lightLogo;

            // Ensure that response.client.appTheme exists
            this.appState.clientInfoSubValue.client.appTheme = response.client.appTheme || {};
            this.appState.clientInfoSubValue.client.appTheme.primaryColor = response.client.appTheme.primaryColor || constants.appPrimaryColor;
            this.appState.clientInfoSubValue.client.appTheme.secondaryColor = response.client.appTheme.secondaryColor || constants.appSecondaryColor;

            // Ensure that response.client.name exists
            this.appState.clientInfoSubValue.client.name = response.client.name || constants.APPLICATION_NAME;
          }
        }

        else {
          this.appState.connectionInfoValue = null;
          this.appState.clientInfoSubValue = {
            client: {
              logo: {
                light: constants.lightLogo,
                dark: constants.darkLogo,
              },
              appTheme: {
                primaryColor: constants.appPrimaryColor,
                secondaryColor: constants.appSecondaryColor,
              },
              name: constants.APPLICATION_NAME,
            },
            user: null,
          };
        }
      },
      (error) => {
        this.appState.connectionInfoValue = null;
        this.appState.clientInfoSubValue = {
          client: {
            logo: {
              light: constants.lightLogo,
              dark: constants.darkLogo,
            },
            appTheme: {
              primaryColor: constants.appPrimaryColor,
              secondaryColor: constants.appSecondaryColor,
            },
            name: constants.APPLICATION_NAME,
          },
          user: null,
        };
      }
    );
  }

  getBrandingPartnersInfo(params): Observable<any> {
    return new Observable((observer) => {
      let response = [];
      observer.next({ isLoading: true });
      this.prospektrApi
        .getBrandingPartnersInfo(params)
        .pipe(
          finalize(() => {
            observer.next({ isLoading: false, response: response });
            observer.complete();
          })
        )
        .subscribe(
          (res) => {
            if (res && res.status && res.status === 'success' && res.data && res.data.length) {
              response = res.data;
              res.data.forEach((response) => {
                if (response?.isPrimary) {
                  this.appState.clientInfoSubValue = response;
                  this.appState.clientInfoSubValue.client.logo.dark = response.client.logo.dark || constants.darkLogo;
                  this.appState.clientInfoSubValue.client.logo.light =
                    response.client.logo.light || constants.lightLogo;
                  this.appState.clientInfoSubValue.client.appTheme.primaryColor =
                    response.client.appTheme?.primaryColor || constants?.appPrimaryColor;
                  this.appState.clientInfoSubValue.client.appTheme.secondaryColor =
                    response.client.appTheme.secondaryColor || constants.appSecondaryColor;
                  this.appState.clientInfoSubValue.client.name = response.client.name || constants.APPLICATION_NAME;
                } else {
                  this.appState.partnerInfoValue = response;
                }
              });
            } else {
              this.appState.clientInfoSubValue = {
                client: {
                  logo: {
                    light: constants.lightLogo,
                    dark: constants.darkLogo,
                  },
                  appTheme: {
                    primaryColor: constants.appPrimaryColor,
                    secondaryColor: constants.appSecondaryColor,
                  },
                  name: constants.APPLICATION_NAME,
                },
                user: null,
              };
              this.appState.partnerInfoValue = {};
            }
          },
          (err) => {
            this.appState.clientInfoSubValue = {
              client: {
                logo: {
                  light: constants.lightLogo,
                  dark: constants.darkLogo,
                },
                appTheme: {
                  primaryColor: constants.appPrimaryColor,
                  secondaryColor: constants.appSecondaryColor,
                },
                name: constants.APPLICATION_NAME,
              },
              user: null,
            };
            this.appState.partnerInfoValue = {};
          }
        );
    });
  }

  // Triggers a dialog when changes made in the page are not saved.
  initiateSaveChangeDialog(): Promise<boolean> {
    return new Promise((resolve) => {
      const matDialogRef = this.matDialog.open(ConfirmDialogComponent, {
        width: '385px',
        disableClose: true,
        hasBackdrop: true,
        data: {
          title: 'Are you sure to leave?',
          message: 'Your changes will be lost. Save your changes before you proceed.',
          primaryBtnText: 'Stay on Page',
          secondaryBtnText: 'Leave Page',
          proceedAction: () => {
            matDialogRef.close();
            resolve(false);
          },
          proceedCancelAction: () => {
            matDialogRef.close();
            resolve(true);
          },
        },
      });
    });
  }

  openLogInDialog() {
    if (this.signupDialogRef) {
      return;
    }

    this.signupDialogRef = this.matDialog.open(SignupDialogComponent, {
      width: '385px',
      disableClose: true,
      hasBackdrop: true,
    });

    this.signupDialogRef.afterClosed().subscribe(() => {
      this.signupDialogRef = null;
    });


  }

  openActivateAccountDialog() {
    const matDialogRef = this.matDialog.open(ActivateModalComponent, {
      width: '385px',
      disableClose: true,
      hasBackdrop: true,
    });
  }

  openSubscribeDialog(res) {
    const matDialogRef = this.matDialog.open(EmailSubscribeDialogComponent, {
      width: '385px',
      disableClose: true,
      hasBackdrop: true,
      data: {
        title: 'Are you sure ?',
        message: 'Do you really want to unsubscribe from marketing email?',
        primaryBtnText: 'Yes',
        secondaryBtnText: 'No',
        params: res,
        proceedAction: () => {
          matDialogRef.close();
        },
        proceedCancelAction: () => {
          matDialogRef.close();
        },
      },
    });
  }
}
