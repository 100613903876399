import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AgentAnalyticsRecord } from 'src/app/_shared/interface/agent-analytics.interface';
import { PaginatedResponse } from 'src/app/_shared/interface/common.interface';

@Injectable({
  providedIn: 'root',
})
export class ClientDashboardState {
  private investorListSub$ = new BehaviorSubject<Array<any>>([]);
  investorList$ = this.investorListSub$.asObservable();
  public get investorListValue(): Array<any> {
    return this.investorListSub$.value;
  }
  public set investorListValue(investorList: Array<any>) {
    this.investorListSub$.next(investorList);
  }

  private clientListUnderMarketingSub$ = new BehaviorSubject<Array<any>>([]);
  clientListUnderMarketing$ = this.clientListUnderMarketingSub$.asObservable();
  public get clientListUnderMarketingValue(): Array<any> {
    return this.clientListUnderMarketingSub$.value;
  }
  public set clientListUnderMarketingValue(investorList: Array<any>) {
    this.clientListUnderMarketingSub$.next(investorList);
  }

  private clientListUnderMarketingCountSub$ = new BehaviorSubject<number>(0);
  clientListUnderMarketingCount$ = this.clientListUnderMarketingCountSub$.asObservable();
  public get clientListUnderMarketingCountValue(): number {
    return this.clientListUnderMarketingCountSub$.value;
  }
  public set clientListUnderMarketingCountValue(count: number) {
    this.clientListUnderMarketingCountSub$.next(count);
  }

  private investorListCountSub$ = new BehaviorSubject<number>(0);
  investorListCount$ = this.investorListCountSub$.asObservable();
  public get investorListCountValue(): number {
    return this.investorListCountSub$.value;
  }
  public set investorListCountValue(count: number) {
    this.investorListCountSub$.next(count);
  }

  private clientUserListSub$ = new BehaviorSubject<PaginatedResponse>({ data: [], totalCount: 0 });
  clientUserList$ = this.clientUserListSub$.asObservable();
  public get clientUserListValue(): PaginatedResponse {
    return this.clientUserListSub$.value;
  }
  public set clientUserListValue(clientUserList: PaginatedResponse) {
    this.clientUserListSub$.next(clientUserList);
  }

  private loanOfficerListSub$ = new BehaviorSubject<PaginatedResponse>({ data: [], totalCount: 0 });
  loanOfficerList$ = this.loanOfficerListSub$.asObservable();
  public get loanOfficerListValue(): PaginatedResponse {
    return this.loanOfficerListSub$.value;
  }
  public set loanOfficerListValue(loanOfficerList: PaginatedResponse) {
    this.loanOfficerListSub$.next(loanOfficerList);
  }

  private agentListSub$ = new BehaviorSubject<PaginatedResponse>({ data: [], totalCount: 0 });
  agentList$ = this.agentListSub$.asObservable();
  public get agentListValue(): PaginatedResponse {
    return this.agentListSub$.value;
  }
  public set agentListValue(agentList: PaginatedResponse) {
    this.agentListSub$.next(agentList);
  }

  private clientUserDetailSub$ = new BehaviorSubject<PaginatedResponse>({ data: [], totalCount: 0 });
  clientUserDetail$ = this.clientUserDetailSub$.asObservable();
  public get clientUserDetailsValue(): PaginatedResponse {
    return this.clientUserDetailSub$.value;
  }
  public set clientUserDetailsValue(clientUserList: PaginatedResponse) {
    this.clientUserDetailSub$.next(clientUserList);
  }

  private activeAgentListSub$ = new BehaviorSubject<Array<any>>([]);
  activeAgentList$ = this.activeAgentListSub$.asObservable();
  public get activeAgentListValue(): Array<any> {
    return this.activeAgentListSub$.value;
  }
  public set activeAgentListValue(agentList: Array<any>) {
    this.activeAgentListSub$.next(agentList);
  }

  private openClientDetailsSheetSub$ = new BehaviorSubject<boolean>(false);
  openClientDetailsSheet$ = this.openClientDetailsSheetSub$.asObservable();
  public get openClientDetailsSheetValue(): boolean {
    return this.openClientDetailsSheetSub$.value;
  }
  public set openClientDetailsSheetValue(status: boolean) {
    this.openClientDetailsSheetSub$.next(status);
  }

  private openClientMarketingDetailsSheetSub$ = new BehaviorSubject<boolean>(false);
  openClientMarketingDetailsSheet$ = this.openClientMarketingDetailsSheetSub$.asObservable();
  public get openClientMarketingDetailsSheetValue(): boolean {
    return this.openClientMarketingDetailsSheetSub$.value;
  }
  public set openClientMarketingDetailsSheetValue(status: boolean) {
    this.openClientMarketingDetailsSheetSub$.next(status);
  }

  private openPropertyDetailsSheetSub$ = new BehaviorSubject<boolean>(false);
  openPropertyDetailsSheet$ = this.openPropertyDetailsSheetSub$.asObservable();
  public get openPropertyDetailsSheetValue(): boolean {
    return this.openPropertyDetailsSheetSub$.value;
  }
  public set openPropertyDetailsSheetValue(status: boolean) {
    this.openPropertyDetailsSheetSub$.next(status);
  }

  private openSentMailDataSheetSub$ = new BehaviorSubject<boolean>(false);
  openSentMailDataSheet$ = this.openSentMailDataSheetSub$.asObservable();
  public get openSentMailDataSheetValue(): boolean {
    return this.openSentMailDataSheetSub$.value;
  }
  public set openSentMailDataSheetValue(status: boolean) {
    this.openSentMailDataSheetSub$.next(status);
  }

  private openMarketingEffectivenessSheetSub$ = new BehaviorSubject<boolean>(false);
  openMarketingEffectivenessSheet$ = this.openMarketingEffectivenessSheetSub$.asObservable();
  public get openMarketingEffectivenessSheetValue(): boolean {
    return this.openMarketingEffectivenessSheetSub$.value;
  }
  public set openMarketingEffectivenessSheetValue(status: boolean) {
    this.openMarketingEffectivenessSheetSub$.next(status);
  }

  private openCreateMailSheetSub$ = new BehaviorSubject<boolean>(false);
  openCreateMailSheet$ = this.openCreateMailSheetSub$.asObservable();
  public get openCreateMailSheetValue(): boolean {
    return this.openCreateMailSheetSub$.value;
  }
  public set openCreateMailSheetValue(status: boolean) {
    this.openCreateMailSheetSub$.next(status);
  }

  private openAddSavedSearchSheetSub$ = new BehaviorSubject<boolean>(false);
  openAddSavedSearchSheet$ = this.openAddSavedSearchSheetSub$.asObservable();
  public get openAddSavedSearchSheetValue(): boolean {
    return this.openAddSavedSearchSheetSub$.value;
  }
  public set openAddPropertySearchSheetValue(status: boolean) {
    this.openAddSavedSearchSheetSub$.next(status);
  }

  private activeClientDetailsSub$ = new BehaviorSubject<any>({});
  activeClientDetails$ = this.activeClientDetailsSub$.asObservable();
  public get activeClientDetailsValue(): object {
    return this.activeClientDetailsSub$.value;
  }
  public set activeClientDetailsValue(details: object) {
    this.activeClientDetailsSub$.next(details);
  }

  private activeClientMarketingEmailDetailsSub$ = new BehaviorSubject<any>({});
  activeClientMarketingEmailDetails$ = this.activeClientMarketingEmailDetailsSub$.asObservable();
  public get activeClientMarketingEmailDetailsValue(): object {
    return this.activeClientMarketingEmailDetailsSub$.value;
  }
  public set activeClientMarketingEmailDetailsValue(details: object) {
    this.activeClientMarketingEmailDetailsSub$.next(details);
  }

  marketingEmailDetailsSub$ = new BehaviorSubject<Number>(0);
  marketingEmailDetails$ = this.marketingEmailDetailsSub$.asObservable();

  public get marketingEmailDetailsValue() {
    return this.marketingEmailDetailsSub$.value;
  }

  public set marketingEmailDetailsValue(count) {
    this.marketingEmailDetailsSub$.next(count);
  }

  private activeClientMarketingDetailsSub$ = new BehaviorSubject<any>({});
  activeClientMarketingDetails$ = this.activeClientMarketingDetailsSub$.asObservable();
  public get activeClientMarketingDetailsValue(): object {
    return this.activeClientMarketingDetailsSub$.value;
  }
  public set activeClientMarketingDetailsValue(details: object) {
    this.activeClientMarketingDetailsSub$.next(details);
  }

  private activeClientEmailHistorySub$ = new BehaviorSubject<any>({});
  activeClientEmailHistory$ = this.activeClientEmailHistorySub$.asObservable();
  public get activeClientEmailHistoryValue(): object {
    return this.activeClientEmailHistorySub$.value;
  }
  public set activeClientEmailHistoryValue(details: object) {
    this.activeClientEmailHistorySub$.next(details);
  }

  private activeSentMailSub$ = new BehaviorSubject<any>({});
  activeSentMail$ = this.activeSentMailSub$.asObservable();
  public get activeSentMailValue(): any {
    return this.activeSentMailSub$.value;
  }
  public set activeSentMailValue(details: object) {
    this.activeSentMailSub$.next(details);
  }

  private savedDealDataSub$ = new BehaviorSubject<Array<any>>([]);
  savedDealData$ = this.savedDealDataSub$.asObservable();
  public get savedDealDataValue(): Array<any> {
    return this.savedDealDataSub$.value;
  }
  public set savedDealDataValue(dealList: Array<any>) {
    this.savedDealDataSub$.next(dealList);
  }

  private savedSearchSub$ = new BehaviorSubject<Array<any>>([]);
  savedSearch$ = this.savedSearchSub$.asObservable();
  public get savedSearchValue(): Array<any> {
    return this.savedSearchSub$.value;
  }
  public set savedSearchValue(savedSearchList: Array<any>) {
    this.savedSearchSub$.next(savedSearchList);
  }

  private activityLogDataSub$ = new BehaviorSubject<Array<any>>([]);
  activityLogData$ = this.activityLogDataSub$.asObservable();
  public get activityLogDataValue(): Array<any> {
    return this.activityLogDataSub$.value;
  }
  public set activityLogDataValue(dealList: Array<any>) {
    this.activityLogDataSub$.next(dealList);
  }

  private sentMailListSub$ = new BehaviorSubject<Array<any>>([]);
  sentMailData$ = this.sentMailListSub$.asObservable();
  public get sentMailListValue(): Array<any> {
    return this.sentMailListSub$.value;
  }
  public set sentMailListValue(sentMailList: Array<any>) {
    this.sentMailListSub$.next(sentMailList);
  }

  private activePartnerListSub$ = new BehaviorSubject<Array<any>>([]);
  activePartnerList$ = this.activePartnerListSub$.asObservable();
  public get activePartnerListValue(): Array<any> {
    return this.activePartnerListSub$.value;
  }
  public set activePartnerListValue(partnerList: Array<any>) {
    this.activePartnerListSub$.next(partnerList);
  }

  private activePartnersCountSub$ = new BehaviorSubject<number>(0);
  activePartnersCount$ = this.activePartnersCountSub$.asObservable();
  public get activePartnersCountValue(): number {
    return this.activePartnersCountSub$.value;
  }
  public set activePartnersCountValue(count: number) {
    this.activePartnersCountSub$.next(count);
  }

  private agentReportSub$ = new BehaviorSubject<Array<AgentAnalyticsRecord>>([]);
  agentReport$ = this.agentReportSub$.asObservable();
  public get agentReportValue(): Array<AgentAnalyticsRecord> {
    return this.agentReportSub$.value;
  }
  public set agentReportValue(value: Array<AgentAnalyticsRecord>) {
    this.agentReportSub$.next(value);
  }

  private agentReportTotalCountSub$ = new BehaviorSubject<number>(0);
  agentReportTotalCount$ = this.agentReportTotalCountSub$.asObservable();
  public get agentReportTotalCountValue(): number {
    return this.agentReportTotalCountSub$.value;
  }
  public set agentReportTotalCountValue(value: number) {
    this.agentReportTotalCountSub$.next(value);
  }

  private savedAgentConnectionSub$ = new BehaviorSubject<Array<any>>([]);
  savedAgentConnectionData$ = this.savedAgentConnectionSub$.asObservable();
  public get savedAgentConnectionDataValue(): Array<any> {
    return this.savedAgentConnectionSub$.value;
  }
  public set savedAgentConnectionDataValue(InvestorList: Array<any>) {
    this.savedAgentConnectionSub$.next(InvestorList);
  }

  private logCountLeadDashboardSub$ = new BehaviorSubject<Array<any>>([]);
  logCountLeadDashboard$ = this.logCountLeadDashboardSub$.asObservable();
  public get logCountLeadDashboardValue(): Array<any> {
    return this.logCountLeadDashboardSub$.value;
  }
  public set logCountLeadDashboardValue(logCount: Array<any>) {
    this.logCountLeadDashboardSub$.next(logCount);
  }

  private userNoteListSub$ = new BehaviorSubject<Array<any>>([]);
  userNoteList$ = this.userNoteListSub$.asObservable();
  public get userNoteListValue(): Array<any> {
    return this.userNoteListSub$.value;
  }
  public set userNoteListValue(notes: Array<any>) {
    this.userNoteListSub$.next(notes);
  }

  private savedSearchLocationSub = new BehaviorSubject<any>(null);
  savedSearchLocation$ = this.savedSearchLocationSub.asObservable();
  public get savedSearchLocationValue(): any {
    return this.savedSearchLocationSub.value;
  }
  public set savedSearchLocationValue(status: any) {
    this.savedSearchLocationSub.next(status);
  }

  private clickedThroughSub = new BehaviorSubject<string>('');
  clickedThrough$ = this.clickedThroughSub.asObservable();
  public get clickedThroughValue(): string {
    return this.clickedThroughSub.value;
  }
  public set clickedThroughValue(value: string) {
    this.clickedThroughSub.next(value);
  }

  private myPropertyListSub$ = new BehaviorSubject<Array<any>>([]);
  myPropertyList$ = this.myPropertyListSub$.asObservable();
  public get myPropertyListValue(): Array<any> {
    return this.myPropertyListSub$.value;
  }
  public set myPropertyListValue(properties: Array<any>) {
    this.myPropertyListSub$.next(properties);
  }
}
