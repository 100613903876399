import { MediaMatcher } from '@angular/cdk/layout';
import { Location } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { AuthorizeService } from 'src/app/_core/access-control/authorize.service';
import {
  DealAnalysisShareInput,
  InsuranceAmountInput,
  lineChartCategories,
} from 'src/app/_shared/interface/deal-analysis.interface';
import { PropertyService } from 'src/app/_shared/service/property.service';
import { AppState } from 'src/app/app.state';
import { MyHomeState } from 'src/app/my-home-page/state/my-home.state';
import { lineChart } from 'src/app/shared/interface/generic-interfaces';
import { UnsavedChangesDialogComponent } from 'src/app/shared/modules/alerts/unsaved-changes-dialog/unsaved-changes-dialog/unsaved-changes-dialog.component';
import { PropertyState } from 'src/app/workspace/state/property.state';
import { environment } from 'src/environments/environment';
import { LinkShareComponent } from '../../../_shared/component/link-share/link-share.component';
import { ConfirmDialogWithControlsComponent } from '../../../shared/modules/alerts/confirm-dialog-with-controls/confirm-dialog-with-controls.component';
import { ProspektrApi } from '../../api/prospektr.api';
import { DealAnalysisConfig } from '../../config/deal-analysis.config';
import { ToNumberPipe } from '../../pipe/to-number.pipe';
import { DealAnalysisService } from '../../service/deal-analysis.service';
import { DealAnalysisState } from '../../state/deal-analysis.state';
import { UserState } from '../../state/user.state';
import { DealAnalysisSheetFacade } from './deal-analysis-sheet.facade';

@Component({
  selector: 'app-deal-analysis-sheet',
  templateUrl: './deal-analysis-sheet.component.html',
  styleUrls: ['./deal-analysis-sheet.component.scss'],
})
export class DealAnalysisSheetComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  mobileQuery: MediaQueryList;
  private mobileQueryListener: () => void;
  @ViewChild('rightSidenav') rightSidenav: MatSidenav;
  @ViewChild('leftSidenav') leftSidenav: MatSidenav;
  @Input() dealId: number;
  @Input() pmxPropertyId: number;
  listingImageBaseUrl = environment.listingImageBaseUrl;
  dealAnalysisForm = new FormGroup({});
  dealShare: boolean = true;
  showIncomeDetails: boolean = true;
  showEquityDetails: boolean = true;
  showIncomeEvalChart: boolean = false;
  showEquityEvalChart: boolean = false;
  annualCapRate: any;
  annualCashOnCash: any;
  upcomingProfitonSale;
  upcomingEquity;
  upcomingReturnOnEquityRatios;
  upcomingPropertyValue;
  upcomingMortgageBalance;
  upcomingTotalCashInvested;
  upcomingReturnOnInvestment;
  upcomingNetProceeds;
  dealDetails: any;
  intialSalesCommissionInDollor;
  initialRepairsInDollar;
  mortgageDetails = {};
  navigatedFromMyPropertyPage: boolean = false;
  mortgageStartYear: number = 0;
  currentYear: number;
  hasNoMortgageData: boolean = false;
  specificProperty: any;
  initialFormValues: any;
  valuesAfterReset: any;
  isRentalAnalysis: string;
  queryParamKeys: string[] = [];
  get f() {
    return this.dealAnalysisForm.controls;
  }

  downPaymentTypeList: Array<any> = ['$', '%'];
  errorMessage = 'Enter a valid number';
  dealAnalysisFormInput$;
  dealAnalysisFormInputValues = {};
  salePredictionSummary: Array<lineChart> = [];
  returnIndicatorSummary: Array<lineChart> = [];
  salePredictionSettings: any = {};
  returnIndicatorSettings: any = {};
  dealAnalysisFormResult$;
  activePropertyDetailsData$;
  downPaymentError: boolean;
  resetStatus: boolean = false;
  title: string;
  shareAnalysis: boolean = false;
  propertyInfo = null;
  profitOnSale = 0;
  isAdvanceMode: boolean;
  profitOnSaleYear = 0;
  profitBreakingValue = 0;
  equity = 0;
  returnOnEquityRatio;
  mortgageBalance;
  totalCashInvested;
  upcompingEquityEval;
  netProceeds;
  propertyValue;
  returnOnInvestment;
  $analyseDeal = new BehaviorSubject<any>(false);
  analyseDeal = this.$analyseDeal.asObservable().pipe(debounceTime(1000));

  computedDealAnalysisResult: any = {};
  isExpanded10 = false;
  isExpanded15 = false;
  isExpanded20 = false;
  isExpanded30 = false;

  activePropertyDetails: any = {};
  activeAVMHistory = null;
  activePropertyDetails$;
  activeAVMHistory$;
  listingDetails$;
  listingImages: Array<any> = [];

  isIncomePieDisabled: boolean = true;
  isExpensePieDisabled: boolean = false;
  isIncomePieChecked: boolean = true;
  isPieChartVisible: boolean = false;
  selectedPieChartOption: string = 'income';
  dealAnalysisYearRange;
  selectedYear = '1';
  incomePieChartData: Array<any> = [];
  incomePieChartConfig = {};
  incomePieChartConfiguration = {};
  expensePieChartData: Array<any> = [];
  expensePieChartConfig = {};
  listingId;
  PMXPropertyId;
  isLoadingDealList = true;
  dealName = 'Equity and Income Analysis';
  dealSheetHeaderName = 'Equity and Income Analysis';
  openPropertyListStatus: boolean = true;
  onDestroyNotifier$ = new Subject();
  isDealOwned: boolean = true;
  dealSheetId: any;
  formInputChange: boolean = false;
  isPropertySheetOpened = false;
  activeHomeValue: any;
  userType: string = '';
  companyName: string = '';
  isloanAmountHigh: boolean = false;
  displayProfitSaleYear = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
  ];
  equityInfo;
  initialPropertyManagementFeeInDollor;
  initialDownPaymentInDollor;
  InitialLoanAmount;
  vaccancyRateOptions = [
    {
      displayValue: 'None',
      value: 0,
    },
    {
      displayValue: 'Once a year',
      value: 1,
    },
    {
      displayValue: 'Twice a year ',
      value: 2,
    },
    {
      displayValue: 'Thrice a year',
      value: 3,
    },
    {
      displayValue: 'Once every 2 years',
      value: 0.5,
    },
    {
      displayValue: 'Once every 3 years',
      value: 0.33,
    },
    {
      displayValue: 'Once every 4 years',
      value: 0.25,
    },
  ];

  constructor(
    media: MediaMatcher,
    private formBuilder: FormBuilder,
    public dealAnalysisSheetFacade: DealAnalysisSheetFacade,
    public dealAnalysisConfig: DealAnalysisConfig,
    private dealAnalysisService: DealAnalysisService,
    private snackBar: MatSnackBar,
    private dealAnalysisState: DealAnalysisState,
    public dialog: MatDialog,
    private toNumberPipe: ToNumberPipe,
    public dialogRef: MatDialogRef<ConfirmDialogWithControlsComponent>,
    private router: Router,
    private userState: UserState,
    private authorizeService: AuthorizeService,
    private location: Location,
    private activatedRoute: ActivatedRoute,
    private propertyService: PropertyService,
    private propertyState: PropertyState,
    public appState: AppState,
    public myHomeState: MyHomeState,
    private prospektrApi: ProspektrApi,
    private cdRef: ChangeDetectorRef
  ) {
    this.mobileQuery = media.matchMedia('(max-width: 991.98px)');
    this.mobileQuery.addListener(this.mobileQueryListener);
  }
  openLogInDialog() {
    this.authorizeService.openLogInDialog();
  }
  ngOnInit() {
    this.activatedRoute.queryParamMap.subscribe(params => {
      this.queryParamKeys = Array.from(params.keys);
    });
    this.isRentalAnalysis = JSON.parse(this.activatedRoute.snapshot.queryParamMap.get('rentalAnalysis'));

    if (this.router.url.includes('/my-property') && this.isRentalAnalysis) {

      this.navigatedFromMyPropertyPage = true;
      this.currentYear = new Date().getFullYear();
      this.myHomeState.mortgageList$.pipe(takeUntil(this.onDestroyNotifier$)).subscribe(
        (mortgageHistory) => {
          if (mortgageHistory && mortgageHistory.length) {
            this.mortgageStartYear = Number(mortgageHistory[0].startDate.substring(0, 4));
            this.mortgageDetails = {
              uLoanAmount: mortgageHistory[0].principal,
              uInterestRate: mortgageHistory[0].interest,
              uLoanTerm: mortgageHistory[0].term / 12,
            };
          } else if (mortgageHistory.length == 0) {
            this.mortgageStartYear = Number(this.currentYear);
            this.hasNoMortgageData = true;
            this.mortgageDetails = {
              uLoanAmount: 0,
              uInterestRate: 3.5,
              uLoanTerm: 30,
            };
          }
        },
        (error) => {
          console.log('error in getting mortgage data', error);
        }
      );
    }

    this.salePredictionSummary = this.dealAnalysisSheetFacade.salePredictionLineChart;
    this.returnIndicatorSummary = this.dealAnalysisSheetFacade.returnIndicatorLineChart;
    this.salePredictionSettings = this.dealAnalysisSheetFacade.salePredictionSettings;
    this.returnIndicatorSettings = this.dealAnalysisSheetFacade.returnIndicatorSettings;

    this.dealAnalysisFormInput$ = this.dealAnalysisSheetFacade
      .getDealAnalysisFormInput()
      .pipe(takeUntil(this.onDestroyNotifier$))
      .subscribe((res) => {

        if (res && Object.keys(res).length) {

          if (this.navigatedFromMyPropertyPage && Object.keys(this.mortgageDetails).length > 0 && this.queryParamKeys.includes("deal-analysis-property-id") && this.isRentalAnalysis) {

            res = { ...res, ...this.mortgageDetails };
            this.dealAnalysisFormInputValues = res;
            this.dealAnalysisForm.patchValue(res);
          } else if (!this.navigatedFromMyPropertyPage) {

            this.dealAnalysisFormInputValues = res;
            this.dealAnalysisForm.patchValue(res);
          } else if (this.navigatedFromMyPropertyPage && this.queryParamKeys.includes("deal-analysis-id") && this.isRentalAnalysis) {

            this.dealAnalysisFormInputValues = res;
            this.dealAnalysisForm.patchValue(res);
          }
          this.initialDownPaymentInDollor = this.calculateDownpaymentInDollor(
            res?.uPurchasePrice,
            res?.uDownPayment ? res.uDownPayment : 20
          );
          this.intialSalesCommissionInDollor = this.calculateSalesCommissionInDollar(
            res?.uPurchasePrice, 2.5);

          this.initialRepairsInDollar = this.calculateRepairsInDollar(
            res?.uMonthlyRent,
            res?.uMaintenancePercent ? res.uMaintenancePercent : 10
          );
          this.initialPropertyManagementFeeInDollor = this.calculatePropertyManagementFeeInDollars(
            res?.uMonthlyRent,
            res?.uPropertyManagementPercent ? res.uPropertyManagementPercent : 8
          );
          if (!this.navigatedFromMyPropertyPage) {
            this.InitialLoanAmount = this.findLoanAmount(res?.uPurchasePrice, 20);
          }

          if (Object.keys(this.mortgageDetails).length > 0 && this.navigatedFromMyPropertyPage) {
            this.initialDownPaymentInDollor = res?.uPurchasePrice - res?.uLoanAmount;
            this.InitialLoanAmount = res?.uLoanAmount;
          } else if (this.navigatedFromMyPropertyPage && this.hasNoMortgageData) {
            this.InitialLoanAmount = 0;
          }

          this.updateDealAnalysisForm();
        }
      });
    this.createDealAnalysisForm();
    this.findMonthlyMortgagePayment();
    this.findInsuranceAmount();
    this.dealAnalysisForm.valueChanges.pipe(takeUntil(this.onDestroyNotifier$)).subscribe((value) => {
      if (this.dealAnalysisForm.valid) {
        this.$analyseDeal.next(value);
      }
    });

    this.dealAnalysisForm
      .get('uDownPayment')
      .valueChanges.pipe(takeUntil(this.onDestroyNotifier$))
      .subscribe((value) => {
        if (this.dealAnalysisForm.get('uDownPayment').valid && !this.resetStatus) {
          let downPayment = this.calculateDownpaymentInDollor(
            this.dealAnalysisForm.controls.uPurchasePrice.value,
            this.dealAnalysisForm.controls.uDownPayment.value
          );
          this.dealAnalysisForm.controls.uDownPaymentInDollors.setValue(Math.round(downPayment), { emitEvent: false });

          // if (!this.dealAnalysisForm.controls.uLoanAmount.dirty && this.dealAnalysisForm.get('uPurchasePrice').value) {
          if (this.dealAnalysisForm.get('uPurchasePrice').valid && this.dealAnalysisForm.get('uDownPayment').valid) {
            let loanAmount;
            if (value <= 100) {
              this.downPaymentError = false;
              loanAmount = this.findLoanAmount(this.dealAnalysisForm.get('uPurchasePrice').value, value);
              this.dealAnalysisForm.controls.uLoanAmount.setValue(Math.round(loanAmount));
            } else {
              this.downPaymentError = true;
            }
          }
        }
      });

    this.dealAnalysisForm
      .get('uDownPaymentInDollors')
      .valueChanges.pipe(takeUntil(this.onDestroyNotifier$))
      .subscribe((value) => {
        if (this.dealAnalysisForm.get('uDownPaymentInDollors').valid && !this.resetStatus) {
          let downPaymentPercentage = (value * 100) / this.dealAnalysisForm.controls.uPurchasePrice.value;
          this.dealAnalysisForm.controls.uDownPayment.setValue(Math.round(Number(downPaymentPercentage)), {
            emitEvent: false,
          });
          let loanAmount = this.dealAnalysisForm.controls.uPurchasePrice.value - value;

          this.dealAnalysisForm.controls.uLoanAmount.setValue(Math.round(loanAmount));
        }
        if (this.resetStatus) {
          this.resetStatus = false;
        }
      });

    this.dealAnalysisForm
      .get('uPurchasePrice')
      .valueChanges.pipe(takeUntil(this.onDestroyNotifier$))
      .subscribe((value) => {
        if (this.dealAnalysisForm.get('uPurchasePrice').valid) {
          if (!this.dealAnalysisForm.controls.uLoanAmount.dirty) {
            let loanAmount;
            loanAmount = this.findLoanAmount(value, this.dealAnalysisForm.get('uDownPayment').value);
            this.dealAnalysisForm.controls.uLoanAmount.setValue(Math.round(loanAmount));
          }
          if (
            this.dealAnalysisFormInputValues['uClosingCost'] &&
            isFinite(this.dealAnalysisFormInputValues['uClosingCost'])
          ) {
            this.dealAnalysisForm.controls.uClosingCost.setValue(
              Math.round(this.dealAnalysisFormInputValues['uClosingCost'])
            );
          } else if (value) {
            this.dealAnalysisForm.controls.uClosingCost.setValue(Math.round(value * 0.01));
          } else {
            this.dealAnalysisForm.controls.uClosingCost.setValue(0);
          }

          if (value) {
            let downpaymentInDollor = this.calculateDownpaymentInDollor(
              this.dealAnalysisForm.controls.uPurchasePrice.value,
              this.dealAnalysisForm.controls.uDownPayment.value
            );
            let sellerAgentCommissionInDollar = this.calculateSalesCommissionInDollar(
              this.dealAnalysisForm.controls.uPurchasePrice.value,
              this.dealAnalysisForm.controls.uSalesSellerAgentCommissionPercent.value
            );
            let buyerAgentCommissionInDollar = this.calculateSalesCommissionInDollar(
              this.dealAnalysisForm.controls.uPurchasePrice.value,
              this.dealAnalysisForm.controls.uSalesBuyerAgentCommissionPercent.value
            );
            this.dealAnalysisForm.controls.uDownPaymentInDollors.setValue(Math.round(downpaymentInDollor), {
              emitEvent: false,
            });
            this.dealAnalysisForm.controls.uSalesSellerAgentComissionInDollors.setValue(
              Math.round(sellerAgentCommissionInDollar),
              { emitEvent: false }
            );
            this.dealAnalysisForm.controls.uSalesBuyerAgentComissionInDollors.setValue(
              Math.round(buyerAgentCommissionInDollar),
              { emitEvent: false }
            );
          }
        }
      });

    this.dealAnalysisForm
      .get('uLoanAmount')
      .valueChanges.pipe(takeUntil(this.onDestroyNotifier$))
      .subscribe((value) => {
        if (this.dealAnalysisForm.get('uLoanAmount').valid) {
          if (value > this.dealAnalysisForm.get('uPurchasePrice').value) {
            this.isloanAmountHigh = true;
          } else {
            this.isloanAmountHigh = false;
            let downPaymentInDollor = (this.dealAnalysisForm.controls.uPurchasePrice.value) - (value);
            let downPaymentPercentage = (downPaymentInDollor * 100) / this.dealAnalysisForm.controls.uPurchasePrice.value;
            this.dealAnalysisForm.controls.uDownPaymentInDollors.setValue(Math.round(downPaymentInDollor), { emitEvent: false });
            this.dealAnalysisForm.controls.uDownPayment.setValue(Math.round(downPaymentPercentage), { emitEvent: false });
          }

          this.findMonthlyMortgagePayment();
        }
      });

    this.dealAnalysisForm
      .get('uInterestRate')
      .valueChanges.pipe(takeUntil(this.onDestroyNotifier$))
      .subscribe((value) => {
        if (this.dealAnalysisForm.get('uInterestRate').valid) {
          this.findMonthlyMortgagePayment();
        }
      });

    this.dealAnalysisForm
      .get('uLoanTerm')
      .valueChanges.pipe(takeUntil(this.onDestroyNotifier$))
      .subscribe((value) => {
        if (this.dealAnalysisForm.get('uLoanTerm').valid) {
          this.findMonthlyMortgagePayment();
        }
      });

    this.dealAnalysisForm
      .get('uLandValue')
      .valueChanges.pipe(takeUntil(this.onDestroyNotifier$))
      .subscribe((value) => {
        if (this.dealAnalysisForm.get('uLandValue').valid) {
          if (!this.dealAnalysisForm.controls.uInsuranceAmount.dirty) {
            this.findInsuranceAmount();
          }
        }
      });

    this.dealAnalysisForm
      .get('uFairMarketValue')
      .valueChanges.pipe(takeUntil(this.onDestroyNotifier$))
      .subscribe((value) => {
        if (this.dealAnalysisForm.get('uFairMarketValue').valid) {
          if (!this.dealAnalysisForm.controls.uInsuranceAmount.dirty) {
            this.findInsuranceAmount();
          }
        }
      });

    this.dealAnalysisForm
      .get('uFairMarketValueYoYPercent')
      .valueChanges.pipe(takeUntil(this.onDestroyNotifier$))
      .subscribe((value) => {
        if (this.dealAnalysisForm.get('uFairMarketValueYoYPercent').valid) {
          if (!this.dealAnalysisForm.controls.uInsuranceAmount.dirty) {
            this.findInsuranceAmount();
          }
        }
      });

    this.dealAnalysisForm
      .get('uSalesSellerAgentCommissionPercent')
      .valueChanges.pipe(takeUntil(this.onDestroyNotifier$))
      .subscribe((value) => {
        if (value === null || value === '') {
          this.dealAnalysisForm.controls.uSalesSellerAgentComissionInDollors.setValue(0, { emitEvent: false });
        } else if (this.dealAnalysisForm.get('uSalesSellerAgentCommissionPercent').valid && !this.resetStatus) {
          let salesSellerAgentCommission = this.calculateSalesCommissionInDollar(
            this.dealAnalysisForm.controls.uPurchasePrice.value,
            this.dealAnalysisForm.controls.uSalesSellerAgentCommissionPercent.value
          );
          this.dealAnalysisForm.controls.uSalesSellerAgentComissionInDollors.setValue(
            Math.round(salesSellerAgentCommission),
            { emitEvent: false }
          );
        }
        if (this.resetStatus) {
          this.resetStatus = false;
        }
      });

    this.dealAnalysisForm
      .get('uSalesSellerAgentComissionInDollors')
      .valueChanges.pipe(takeUntil(this.onDestroyNotifier$))
      .subscribe((value) => {
        if (value === null || value === '') {
          this.dealAnalysisForm.controls.uSalesSellerAgentCommissionPercent.setValue(0, { emitEvent: false });
        } else if (this.dealAnalysisForm.get('uSalesSellerAgentComissionInDollors').valid && !this.resetStatus) {
          let salesSellerAgentCommissionPercentage =
            (value * 100) / this.dealAnalysisForm.controls.uPurchasePrice.value;
          this.dealAnalysisForm.controls.uSalesSellerAgentCommissionPercent.setValue(
            parseFloat(salesSellerAgentCommissionPercentage.toFixed(1)),
            { emitEvent: false }
          );
        }
        if (this.resetStatus) {
          this.resetStatus = false;
        }
      });

    this.dealAnalysisForm
      .get('uSalesBuyerAgentCommissionPercent')
      .valueChanges.pipe(takeUntil(this.onDestroyNotifier$))
      .subscribe((value) => {
        if (value === null || value === '') {
          this.dealAnalysisForm.controls.uSalesBuyerAgentComissionInDollors.setValue(0, { emitEvent: false });
        } else if (this.dealAnalysisForm.get('uSalesBuyerAgentCommissionPercent').valid && !this.resetStatus) {
          let salesBuyerAgentCommissionInDollor = this.calculateSalesCommissionInDollar(
            this.dealAnalysisForm.controls.uPurchasePrice.value,
            this.dealAnalysisForm.controls.uSalesBuyerAgentCommissionPercent.value
          );
          this.dealAnalysisForm.controls.uSalesBuyerAgentComissionInDollors.setValue(
            Math.round(salesBuyerAgentCommissionInDollor),
            { emitEvent: false }
          );
        }
        if (this.resetStatus) {
          this.resetStatus = false;
        }
      });

    this.dealAnalysisForm
      .get('uSalesBuyerAgentComissionInDollors')
      .valueChanges.pipe(takeUntil(this.onDestroyNotifier$))
      .subscribe((value) => {
        if (value === null || value === '') {
          this.dealAnalysisForm.controls.uSalesBuyerAgentCommissionPercent.setValue(0, { emitEvent: false });
        } else if (this.dealAnalysisForm.get('uSalesBuyerAgentComissionInDollors').valid && !this.resetStatus) {
          let salesBuyerAgentCommissionPercentage = (value * 100) / this.dealAnalysisForm.controls.uPurchasePrice.value;
          this.dealAnalysisForm.controls.uSalesBuyerAgentCommissionPercent.setValue(
            parseFloat(salesBuyerAgentCommissionPercentage.toFixed(1)),
            { emitEvent: false }
          );
        }
        if (this.resetStatus) {
          this.resetStatus = false;
        }
      });

    this.dealAnalysisForm
      .get('uMaintenancePercent')
      .valueChanges.pipe(takeUntil(this.onDestroyNotifier$))
      .subscribe((value) => {
        if (this.dealAnalysisForm.get('uMaintenancePercent').valid && !this.resetStatus) {
          let repairInDollors = this.calculateRepairsInDollar(
            this.dealAnalysisForm.controls.uMonthlyRent.value || 0,
            this.dealAnalysisForm.controls.uMaintenancePercent.value || 10
          );
          this.dealAnalysisForm.controls.uMaintenanceInDollors.setValue(Math.round(repairInDollors), {
            emitEvent: false,
          });
        }
        if (this.resetStatus) {
          this.resetStatus = false;
        }
      });

    this.dealAnalysisForm
      .get('uMaintenanceInDollors')
      .valueChanges.pipe(takeUntil(this.onDestroyNotifier$))
      .subscribe((value) => {
        if (this.dealAnalysisForm.get('uMaintenanceInDollors').valid && !this.resetStatus) {
          let propertyManagementFeePercentage =
            (value * 100) /
            (this.dealAnalysisForm.controls.uMonthlyRent.value
              ? this.dealAnalysisForm.controls.uMonthlyRent.value * 12
              : 0);
          propertyManagementFeePercentage = isFinite(propertyManagementFeePercentage)
            ? propertyManagementFeePercentage
            : 0;
          this.dealAnalysisForm.controls.uMaintenancePercent.setValue(Math.round(propertyManagementFeePercentage), {
            emitEvent: false,
          });
        }
        if (this.resetStatus) {
          this.resetStatus = false;
        }
      });

    this.dealAnalysisForm
      .get('uPropertyManagementPercent')
      .valueChanges.pipe(takeUntil(this.onDestroyNotifier$))
      .subscribe((value) => {
        if (this.dealAnalysisForm.get('uPropertyManagementPercent').valid && !this.resetStatus) {
          let propertyManagementFeeInDollors = this.calculatePropertyManagementFeeInDollars(
            this.dealAnalysisForm.controls.uMonthlyRent.value || 0,
            this.dealAnalysisForm.controls.uPropertyManagementPercent.value || 8
          );
          this.dealAnalysisForm.controls.uPropertyManagementInDollors.setValue(
            Math.round(propertyManagementFeeInDollors),
            { emitEvent: false }
          );
        }
        if (this.resetStatus) {
          this.resetStatus = false;
        }
      });

    this.dealAnalysisForm
      .get('uMonthlyRent')
      .valueChanges.pipe(takeUntil(this.onDestroyNotifier$))
      .subscribe((value) => {
        if (this.dealAnalysisForm.get('uMonthlyRent').valid && !this.resetStatus) {
          let repairInDollors = this.calculateRepairsInDollar(
            this.dealAnalysisForm.controls.uMonthlyRent.value,
            this.dealAnalysisForm.controls.uMaintenancePercent.value
          );
          this.dealAnalysisForm.controls.uMaintenanceInDollors.setValue(Math.round(repairInDollors), {
            emitEvent: false,
          });

          let propertyManagementFeeInDollor = this.calculatePropertyManagementFeeInDollars(
            this.dealAnalysisForm.controls.uMonthlyRent.value,
            this.dealAnalysisForm.controls.uPropertyManagementPercent.value
          );
          this.dealAnalysisForm.controls.uPropertyManagementInDollors.setValue(
            Math.round(propertyManagementFeeInDollor),
            { emitEvent: false }
          );
        }

        if (this.resetStatus) {
          this.resetStatus = false;
        }
      });

    this.dealAnalysisForm
      .get('uPropertyManagementInDollors')
      .valueChanges.pipe(takeUntil(this.onDestroyNotifier$))
      .subscribe((value) => {
        if (this.dealAnalysisForm.get('uPropertyManagementInDollors').valid && !this.resetStatus) {
          let propertyManagementFeePercentage =
            (value * 100) /
            (this.dealAnalysisForm.controls.uMonthlyRent.value
              ? this.dealAnalysisForm.controls.uMonthlyRent.value * 12
              : 0);
          propertyManagementFeePercentage = isFinite(propertyManagementFeePercentage)
            ? propertyManagementFeePercentage
            : 0;

          this.dealAnalysisForm.controls.uPropertyManagementPercent.setValue(
            Math.round(propertyManagementFeePercentage),
            { emitEvent: false }
          );
        }
        if (this.resetStatus) {
          this.resetStatus = false;
        }
      });

    this.dealAnalysisFormResult$ = this.dealAnalysisSheetFacade
      .getDealAnalysisData()
      .pipe(takeUntil(this.onDestroyNotifier$))
      .subscribe((res) => {
        if (res && Object.keys(res).length) {
          this.dealAnalysisYearRange = Object.keys(res);
          this.returnIndicatorSettings.xAxis.categories = this.dealAnalysisYearRange;
          this.salePredictionSettings.xAxis.categories = this.dealAnalysisYearRange;

          this.formatLineChartData();
          this.validatePieChartData();
        }
      });
    if (this.navigatedFromMyPropertyPage) {
      this.dealAnalysisSheetFacade.displayAnalysisResults$.pipe(takeUntil(this.onDestroyNotifier$)).subscribe((res) => {
        this.computedDealAnalysisResult = res;
      });
    }
    if (!this.navigatedFromMyPropertyPage) {
      this.dealAnalysisSheetFacade.displayAnalysisResult$.pipe(takeUntil(this.onDestroyNotifier$)).subscribe((res) => {
        this.computedDealAnalysisResult = res;
      });
    }

    this.propertyState.propertyDetailsSheetInput$.pipe(takeUntil(this.onDestroyNotifier$)).subscribe((data: any) => {
      if (data) {
        this.propertyInfo = data;
      }
    });

    this.propertyState.activeHomeValue$.subscribe((res) => {
      this.activeHomeValue = res;
    });

    this.activePropertyDetails$ = this.dealAnalysisState.activePropertyDetails$
      .pipe(takeUntil(this.onDestroyNotifier$))
      .subscribe((res) => {
        if (res == null) {
          return;
        }
        if (res.basic) {
          const homeValueParams = {
            City: res.basic?.City,
            State: res.basic?.StateOrProvince,
            StreetName: this.propertyService.getStreetName(res.basic),
            StreetNumber: res.basic?.StreetNumber,
            Zip: res.basic?.PostalCode,
          };
          this.propertyService.getHomeValue(homeValueParams);
        }
        if (!this.dealDetails?._id) {
          let propertyDetails = {
            ...res?.avmList,
            ...res?.basic,
            ...res?.basic?.Lst_ListPrice,
          };
          this.dealAnalysisSheetFacade.setDefaultDealAnalysisValues(propertyDetails);
        }

        if (res?.basic?.Address) {
          this.PMXPropertyId = res.basic.PMXPropertyId;
          let propertyStreetAddress = res.basic.Address.split(',');
          res.basic['propertyAddress'] = propertyStreetAddress[0];
        }

        if (res.avmList) {
          this.activeAVMHistory = res.avmList;
        }

        this.activePropertyDetails = res.basic;

        if (res && res.listing) {
          let af1 = 0,
            af2 = 0,
            listings;
          listings = res.listing;
          if (this.dealAnalysisState.isSavedDealActiveValue == false) {
            if (this.myHomeState.DealAnalysisHomeValue && this.myHomeState.DealAnalysisHomeValue['customHoaValue']) {
              this.dealAnalysisForm.controls.uHoaFee.setValue(this.myHomeState.DealAnalysisHomeValue['customHoaValue']);
            }
            if (this.appState.appCompanyAliasValue == 'opscan') {
              this.dealAnalysisForm.controls.uHoaFee.setValue(0);
            } else if (
              Object.keys(this.myHomeState.DealAnalysisHomeValue).length === 0 &&
              this.appState.appCompanyAliasValue != 'opscan'
            ) {
              if (listings) {
                if (listings.AssociationFee) {
                  if (!listings.AssociationFeeFrequency || listings.AssociationFeeFrequency === 'Not Applicable') {
                    af1 = 0;
                  } else {
                    af1 = this.calculateAssociationFee(
                      this.toNumberPipe.transform(listings.AssociationFee),
                      listings.AssociationFeeFrequency
                    );
                  }
                } else {
                  af1 = 0;
                }
                if (listings.AssociationFee2) {
                  if (!listings.AssociationFee2Frequency || listings.AssociationFee2Frequency === 'Not Applicable') {
                    af2 = 0;
                  } else {
                    af2 = this.calculateAssociationFee(
                      this.toNumberPipe.transform(listings.AssociationFee2),
                      listings.AssociationFee2Frequency
                    );
                  }
                } else {
                  af2 = 0;
                }
                af1 = this.toNumberPipe.transform(af1);
                af2 = this.toNumberPipe.transform(af2);
                if (!af1) {
                  af1 = 0;
                }
                if (!af2) {
                  af2 = 0;
                }
                if (typeof af1 == typeof af2) {
                  this.dealAnalysisForm.controls.uHoaFee.setValue(af1 + af2);
                } else {
                  this.dealAnalysisForm.controls.uHoaFee.setValue(0);
                }
              }
            }
          } else {
            let hoaFeeValue = isNaN(this.dealAnalysisForm.controls.uHoaFee.value)
              ? 0
              : Math.round(this.dealAnalysisForm.controls.uHoaFee.value);
            this.dealAnalysisForm.controls.uHoaFee.setValue(hoaFeeValue);
          }
        }
      });

    this.userType = this.userState.userDetails?.userType;
    this.companyName = this.appState && this.appState.companyInfoValue.alias;
    this.initialFormValues = this.dealAnalysisForm.value;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.userState.userDetail$.pipe(takeUntil(this.onDestroyNotifier$)).subscribe(() => {
      if (!this.appState.authTokenValue?.accessToken) {
        this.openLogInDialog();
        return;
      }
      if (changes?.dealId?.currentValue != changes?.dealId?.previousValue && changes?.dealId?.currentValue) {
        this.dealAnalysisById(changes?.dealId?.currentValue);
      }
      if (
        changes?.pmxPropertyId?.currentValue != changes?.pmxPropertyId?.previousValue &&
        changes?.pmxPropertyId?.currentValue
      ) {
        this.getDealsDetailsFromPmxId(changes?.pmxPropertyId?.currentValue);
      }
    });
  }

  onInputBlur(controlName: string) {
    const control = this.dealAnalysisForm.get(controlName);
    if (controlName == 'uInsuranceAmount') {
      if (control && !control.value) this.dealAnalysisState.isuInsuranceAmountEdited = true;
      else this.dealAnalysisState.isuInsuranceAmountEdited = false;
    }
    if (control && !control.value) {
      control.setValue('0');
      this.removeError(controlName);
    }
  }

  onInputEnter(controlName: string) {
    const control = this.dealAnalysisForm.get(controlName);
    if (controlName == 'uInsuranceAmount') {
      if (control && !control.value) this.dealAnalysisState.isuInsuranceAmountEdited = true;
      else this.dealAnalysisState.isuInsuranceAmountEdited = false;
    }
    if (control && !control.value) {
      control.setValue('0');
      this.removeError(controlName);
    }
  }

  validateNumber(event: KeyboardEvent): void {
    const keyCode = event.keyCode;

    // List of keys that we want to allow regardless of numeric input
    const excludedKeys = [8, 37, 39, 46]; // Backspace, ArrowLeft, ArrowRight, Delete

    // Check if the input field already contains a decimal point
    const input = event.target as HTMLInputElement;
    const hasDecimalPoint = input.value.includes('.');

    // Allow numeric keys (0-9), numpad keys (0-9), decimal point, and excluded keys
    const isNumberKey = keyCode >= 48 && keyCode <= 57; // Top row numbers
    const isNumpadKey = keyCode >= 96 && keyCode <= 105; // Numpad numbers
    const isDecimalPoint = keyCode === 190 || keyCode === 110; // Decimal point from top row or numpad

    // Prevent entering a second decimal point
    if (isDecimalPoint && hasDecimalPoint) {
      event.preventDefault();
      return;
    }

    // Allow only numbers, a single decimal point, and excluded keys
    if (!(isNumberKey || isNumpadKey || isDecimalPoint || excludedKeys.includes(keyCode))) {
      event.preventDefault();
    }
  }

  private removeError(controlName: string) {
    const control = this.dealAnalysisForm.get(controlName);
    if (control) {
      control.setErrors(null);
    }
  }
  toggleIncomeDetails() {
    this.showIncomeDetails = !this.showIncomeDetails;
  }

  toggleEquityDetails() {
    this.showEquityDetails = !this.showEquityDetails;
  }

  dealAnalysisById(dealId) {
    this.dealAnalysisService
      .fetchDealsById(dealId)
      .pipe(takeUntil(this.onDestroyNotifier$))
      .subscribe((response: any) => {
        if (response.data) {
          this.dealDetails = response.data;
          this.dealSheetHeaderName = response.data.name;
          this.getPropertyDetailsForDeal(response.data);
          this.dealAnalysisState.defaultDealAnalysisInputValue = response.data.computationInput;
          this.dealAnalysisState.isSavedDealActiveValue = true;
          this.dealAnalysisFormInputValues['id'] = response.data._id;
          if (response.data.isOwned == 'false') {
            this.isDealOwned = false;
          }
        } else {
          this.openSnackBar(response.error.message, 'snackbar-warning');
          this.isLoadingDealList = false;
          this.dealAnalysisState.openDealAnalysisSheetValue = false;
          this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams: {},
          });
        }
      });
  }

  openingPropertySheet() {
    this.isPropertySheetOpened = true;
  }
  getPropertyDetailsForDeal(deal) {
    this.dealAnalysisService.getPropertyDetails({ pmxPropertyId: deal.PMXPropertyId, type: ['basic', 'avm'] });
    this.isLoadingDealList = false;
  }
  getDealsDetailsFromPmxId(PMXPropertyId) {
    this.dealAnalysisService.getPropertyDetails({
      pmxPropertyId: PMXPropertyId,
      type: ['basic', 'avm', 'listing'],
    });
    this.isLoadingDealList = false;
  }

  ngAfterViewInit() {
    this.dealAnalysisForm.valueChanges.pipe(takeUntil(this.onDestroyNotifier$)).subscribe((res) => {
      if (this.dealAnalysisForm.dirty) {
        this.formInputChange = true;
      }
    });

    this.dealAnalysisState.dealAnalysisResult.subscribe((res) => {
      if (res) {
        let result;
        if (this.navigatedFromMyPropertyPage) {
          let yearList = this.computedDealAnalysisResult?.termList;
          if (yearList && yearList.length) {
            result = this.dealAnalysisSheetFacade.displayEquityInfo(res, yearList);
          }
        } else if (!this.navigatedFromMyPropertyPage) {
          result = this.dealAnalysisSheetFacade.displayEquityInfo(res, this.displayProfitSaleYear);
        }
        if (result && result['profitOnSale']) {
          let i;
          this.profitOnSaleYear = 0;
          const profitOnSale = result['profitOnSale'].findIndex((value) => value > 10000);
          if (profitOnSale >= 0) {
            this.profitOnSaleYear = profitOnSale + 1;
            i = profitOnSale;
          }

          if (this.profitOnSaleYear > 0) {
            this.profitOnSale = result['profitOnSale'][i];
            this.equity = result['equity'][i];
            this.returnOnEquityRatio = result['returnOnEquityRatio'][i];
            this.totalCashInvested = result['totalCashInvested'][i];
            this.returnOnInvestment = result['returnOnInvestmentRatio'][i];
            this.netProceeds = result['netProceed'][i];
            this.propertyValue = result['propertyValue'][i];
            this.mortgageBalance = result['mortgageBalance'][i];

            if (this.profitOnSaleYear >= 1 && this.profitOnSaleYear <= 9) {
              this.upcompingEquityEval = 10;
              this.upcomingProfitonSale = result['profitOnSale'][9];
              this.upcomingEquity = result['equity'][9];
              this.upcomingReturnOnEquityRatios = result['returnOnEquityRatio'][9];
              this.upcomingTotalCashInvested = result['totalCashInvested'][9];
              this.upcomingReturnOnInvestment = result['returnOnInvestmentRatio'][9];
              this.upcomingNetProceeds = result['netProceed'][9];
              this.upcomingPropertyValue = result['propertyValue'][9];
              this.upcomingMortgageBalance = result['mortgageBalance'][9];
            } else if (this.profitOnSaleYear >= 10 && this.profitOnSaleYear <= 19) {
              this.upcompingEquityEval = 20;
              this.upcomingProfitonSale = result['profitOnSale'][19];
              this.upcomingEquity = result['equity'][19];
              this.upcomingReturnOnEquityRatios = result['returnOnEquityRatio'][19];
              this.upcomingTotalCashInvested = result['totalCashInvested'][19];
              this.upcomingReturnOnInvestment = result['returnOnInvestmentRatio'][19];
              this.upcomingNetProceeds = result['netProceed'][19];
              this.upcomingPropertyValue = result['propertyValue'][19];
              this.upcomingMortgageBalance = result['mortgageBalance'][19];
            } else if (this.profitOnSaleYear >= 20 && this.profitOnSaleYear <= 29) {
              this.upcompingEquityEval = 30;
              this.upcomingProfitonSale = result['profitOnSale'][29];
              this.upcomingEquity = result['equity'][29];
              this.upcomingReturnOnEquityRatios = result['returnOnEquityRatio'][29];
              this.upcomingTotalCashInvested = result['totalCashInvested'][29];
              this.upcomingReturnOnInvestment = result['returnOnInvestmentRatio'][29];
              this.upcomingNetProceeds = result['netProceed'][29];
              this.upcomingPropertyValue = result['propertyValue'][29];
              this.upcomingMortgageBalance = result['mortgageBalance'][29];
            } else if (this.profitOnSaleYear === 30) {
              this.upcompingEquityEval = 30;
              this.upcomingProfitonSale = '--';
              this.upcomingEquity = '--';
              this.upcomingReturnOnEquityRatios = '--';
              this.upcomingTotalCashInvested = '--';
              this.upcomingReturnOnInvestment = '--';
              this.upcomingNetProceeds = '--';
              this.upcomingPropertyValue = '--';
              this.upcomingMortgageBalance = '--';
            }
          } else {
            this.upcompingEquityEval = 0;
            this.upcomingProfitonSale = 0;
            this.upcomingEquity = 0;
            this.upcomingReturnOnEquityRatios = 0;
            this.upcomingTotalCashInvested = 0;
            this.upcomingReturnOnInvestment = 0;
            this.upcomingNetProceeds = 0;
            this.upcomingPropertyValue = 0;
            this.upcomingMortgageBalance = 0;
            this.profitOnSale = 0;
            this.equity = 0;
            this.returnOnEquityRatio = 0;
            this.totalCashInvested = 0;
            this.returnOnInvestment = 0;
            this.netProceeds = 0;
            this.propertyValue = 0;
            this.mortgageBalance = 0;
          }
        }
      }
    });
  }

  ngOnDestroy() {
    this.dealAnalysisSheetFacade.closeDealAnalysisSheet();
    this.dealAnalysisFormResult$.unsubscribe();
    this.activePropertyDetails$.unsubscribe();
    this.onDestroyNotifier$.next();
    this.onDestroyNotifier$.complete();
    this.dealAnalysisState.dealAnalysisPropertyIdValue = 0;
    this.dealAnalysisState.activePropertyDetailsValue = null;
    this.dealAnalysisState.dealAnalysisIdValue = 0;
  }

  updateDealAnalysisForm() {
    this.dealAnalysisForm.patchValue({
      uPropertyManagementInDollors: this.initialPropertyManagementFeeInDollor,
      uMaintenanceInDollors: this.initialRepairsInDollar,
      uDownPaymentInDollors: this.initialDownPaymentInDollor,
      uLoanAmount: this.InitialLoanAmount || 0,
      uSalesSellerAgentComissionInDollors: this.intialSalesCommissionInDollor,
      uSalesBuyerAgentComissionInDollors: this.intialSalesCommissionInDollor,
    });
  }

  createDealAnalysisForm() {

    this.dealAnalysisForm = this.formBuilder.group({
      uPurchasePrice: [
        isFinite(this.dealAnalysisFormInputValues['uPurchasePrice'])
          ? Math.round(Number(this.dealAnalysisFormInputValues['uPurchasePrice']))
          : 0,
        [Validators.required, ValidateForMax],
      ],
      uDownPayment: [
        isFinite(this.dealAnalysisFormInputValues['uDownPayment'])
          ? Math.round(Number(this.dealAnalysisFormInputValues['uDownPayment']))
          : 20,
        [Validators.required, Validators.max(100)],
      ],
      uDownPaymentInDollors: [
        this.initialDownPaymentInDollor,
        [Validators.required, ValidateForDownPayment('uPurchasePrice')],
      ],

      uLandValue: [
        isFinite(this.dealAnalysisFormInputValues['uLandValue'])
          ? Math.round(Number(this.dealAnalysisFormInputValues['uLandValue']))
          : 0,
        [Validators.required, ValidateForMax],
      ],
      uClosingCost: [
        isFinite(this.dealAnalysisFormInputValues['uClosingCost'])
          ? Math.round(Number(this.dealAnalysisFormInputValues['uClosingCost']))
          : Math.round(this.dealAnalysisFormInputValues['uPurchasePrice'] * 0.01) || 0,
        [Validators.required, ValidateForMax],
      ],
      uRenovationCost: [
        isFinite(this.dealAnalysisFormInputValues['uRenovationCost'])
          ? Math.round(Number(this.dealAnalysisFormInputValues['uRenovationCost']))
          : 0,
        [ValidateForMax],
      ],
      uLoanAmount: [
        isFinite(this.dealAnalysisFormInputValues['uLoanAmount'])
          ? Math.round(Number(this.dealAnalysisFormInputValues['uLoanAmount']))
          : this.findLoanAmount(this.dealAnalysisFormInputValues['uPurchasePrice'], 20),
        [Validators.required, ValidateForMax],
      ],
      uInterestRate: [
        isFinite(this.dealAnalysisFormInputValues['uInterestRate'])
          ? Number(this.dealAnalysisFormInputValues['uInterestRate'])
          : this.appState.appCompanyAliasValue == 'opscan'
            ? 6.5
            : 3.5,
        [Validators.required, Validators.min(0), Validators.max(20)],
      ],
      uLoanTerm: [
        isFinite(this.dealAnalysisFormInputValues['uLoanTerm'])
          ? Math.round(Number(this.dealAnalysisFormInputValues['uLoanTerm']))
          : 30,
        [Validators.required, ValidateForTerm],
      ],
      uMonthlyMortgagePayment: [
        {
          value: isFinite(this.dealAnalysisFormInputValues['uMonthlyMortgagePayment'])
            ? Math.round(Number(this.dealAnalysisFormInputValues['uMonthlyMortgagePayment']))
            : 0,
          disabled: true,
        },
      ],
      uPropertyTaxAmount: [
        isFinite(this.dealAnalysisFormInputValues['uPropertyTaxAmount'])
          ? Math.round(Number(this.dealAnalysisFormInputValues['uPropertyTaxAmount']))
          : 0,
        [Validators.required, ValidateForMax],
      ],
      // uInsuranceAmount: [
      //   isFinite(this.dealAnalysisFormInputValues['uInsuranceAmount'])
      //     ? Math.round(Number(this.dealAnalysisFormInputValues['uInsuranceAmount']))
      //     : 0,
      //   [Validators.required, ValidateForMax],
      // ],
      uInsuranceAmount: [
        isFinite(this.dealAnalysisFormInputValues['uInsuranceAmount'])
        ,
        [Validators.required, ValidateForMax],
      ],
      uHoaFee: [
        isFinite(this.dealAnalysisFormInputValues['uHoaFee'])
          ? Math.round(Number(this.dealAnalysisFormInputValues['uHoaFee']))
          : 0,
        [ValidateForMax],
      ],
      uOtherExpenses: [
        isFinite(this.dealAnalysisFormInputValues['uOtherExpenses'])
          ? Number(this.dealAnalysisFormInputValues['uOtherExpenses'])
          : 5,
        [Validators.min(0), Validators.max(100)],
      ],
      uHoaFeeYoYPercent: [
        isFinite(this.dealAnalysisFormInputValues['uHoaFeeYoYPercent'])
          ? Number(this.dealAnalysisFormInputValues['uHoaFeeYoYPercent'])
          : 0,
        [Validators.min(0), Validators.max(100)],
      ],
      uPropertyManagementPercent: [
        isFinite(this.dealAnalysisFormInputValues['uPropertyManagementPercent'])
          ? Math.round(Number(this.dealAnalysisFormInputValues['uPropertyManagementPercent']))
          : 8,
        [Validators.required, Validators.min(0), Validators.max(100)],
      ],
      uPropertyManagementInDollors: [this.initialPropertyManagementFeeInDollor, [Validators.required]],
      uMaintenancePercent: [
        isFinite(this.dealAnalysisFormInputValues['uMaintenancePercent'])
          ? Math.round(Number(this.dealAnalysisFormInputValues['uMaintenancePercent']))
          : 10,
        [Validators.required, Validators.min(0), Validators.max(100)],
      ],
      uMaintenanceInDollors: [this.initialRepairsInDollar, [Validators.required]],
      uImprovementPercent: [
        isFinite(this.dealAnalysisFormInputValues['uImprovementPercent'])
          ? Number(this.dealAnalysisFormInputValues['uImprovementPercent'])
          : 0,
        [Validators.min(0), Validators.max(100)],
      ],
      uWarrantyServiceCost: [
        isFinite(this.dealAnalysisFormInputValues['uWarrantyServiceCost'])
          ? Math.round(Number(this.dealAnalysisFormInputValues['uWarrantyServiceCost']))
          : 0,
        [ValidateForMax],
      ],
      uWarrantyServiceYoYPercent: [
        isFinite(this.dealAnalysisFormInputValues['uWarrantyServiceYoYPercent'])
          ? Number(this.dealAnalysisFormInputValues['uWarrantyServiceYoYPercent'])
          : 0,
        [Validators.min(0), Validators.max(100)],
      ],
      uAccountingCost: [
        isFinite(this.dealAnalysisFormInputValues['uAccountingCost'])
          ? Math.round(Number(this.dealAnalysisFormInputValues['uAccountingCost']))
          : 500,
        [Validators.required, ValidateForMax],
      ],
      uAccountingYoYPercent: [
        isFinite(this.dealAnalysisFormInputValues['uAccountingYoYPercent'])
          ? Number(this.dealAnalysisFormInputValues['uAccountingYoYPercent'])
          : 0,
        [Validators.min(0), Validators.max(100)],
      ],
      uMonthlyRent: [
        isFinite(this.dealAnalysisFormInputValues['uMonthlyRent'])
          ? Math.round(Number(this.dealAnalysisFormInputValues['uMonthlyRent']))
          : 0,
        [Validators.required, ValidateForMax],
      ],
      uMonthlyRentYoYPercent: [
        isFinite(this.dealAnalysisFormInputValues['uMonthlyRentYoYPercent'])
          ? Number(this.dealAnalysisFormInputValues['uMonthlyRentYoYPercent'])
          : this.appState.appCompanyAliasValue == 'opscan'
            ? 0
            : 2,
        [Validators.min(0), Validators.max(100)],
      ],
      uRenterChurn: [
        isFinite(this.dealAnalysisFormInputValues['uRenterChurn'])
          ? Number(this.dealAnalysisFormInputValues['uRenterChurn'])
          : 0,
        [Validators.min(0), Validators.max(100)],
      ],
      uTenantSearchDuration: [
        isFinite(this.dealAnalysisFormInputValues['uTenantSearchDuration'])
          ? Math.round(Number(this.dealAnalysisFormInputValues['uTenantSearchDuration']))
          : 0,
        [ValidateForTenantSearchTime],
      ],
      uAssessedPropertyValue: [
        isFinite(this.dealAnalysisFormInputValues['uAssessedPropertyValue'])
          ? Math.round(Number(this.dealAnalysisFormInputValues['uAssessedPropertyValue']))
          : 0,
        [Validators.required, ValidateForMax],
      ],
      uAssessedPropertyValueYoYPercent: [
        isFinite(this.dealAnalysisFormInputValues['uAssessedPropertyValueYoYPercent'])
          ? Number(this.dealAnalysisFormInputValues['uAssessedPropertyValueYoYPercent'])
          : 0,
        [Validators.min(0), Validators.max(100)],
      ],
      uFairMarketValue: [
        isFinite(this.dealAnalysisFormInputValues['uFairMarketValue'])
          ? Math.round(Number(this.dealAnalysisFormInputValues['uFairMarketValue']))
          : 0,
        [Validators.required, ValidateForMax],
      ],
      uFairMarketValueYoYPercent: [
        isFinite(this.dealAnalysisFormInputValues['uFairMarketValueYoYPercent'])
          ? Number(this.dealAnalysisFormInputValues['uFairMarketValueYoYPercent'])
          : 0,
        [Validators.min(0), Validators.max(100)],
      ],
      uSalesCommissionPercent: [5, [Validators.required, Validators.min(0), Validators.max(100)]],
      uSalesSellerAgentCommissionPercent: [
        this.dealAnalysisFormInputValues['uSalesSellerAgentCommissionPercent']
          ? Number(this.dealAnalysisFormInputValues['uSalesSellerAgentCommissionPercent'])
          : 2.5,
        [Validators.required, Validators.min(0), Validators.max(100)],
      ],
      uSalesBuyerAgentCommissionPercent: [
        isFinite(this.dealAnalysisFormInputValues['uSalesBuyerAgentCommissionPercent'])
          ? Number(this.dealAnalysisFormInputValues['uSalesBuyerAgentCommissionPercent'])
          : 2.5,
        [Validators.required, Validators.min(0), Validators.max(100)],
      ],

      uSalesSellerAgentComissionInDollors: [this.intialSalesCommissionInDollor, [Validators.required]],
      uSalesBuyerAgentComissionInDollors: [this.intialSalesCommissionInDollor, [Validators.required]],
      uState: this.dealAnalysisFormInputValues['uState'],
    });

    if (this.dealAnalysisFormInputValues['dealName']) {
      this.dealName = this.dealAnalysisFormInputValues['dealName'];
      this.dealSheetHeaderName = this.dealAnalysisFormInputValues['dealName'];
    }

    this.analyseDeal.pipe(takeUntil(this.onDestroyNotifier$)).subscribe((formValue) => {
      if (formValue) {
        // if (!this.dealAnalysisForm.controls.uInsuranceAmount.dirty && !this.dealAnalysisState.isSavedDealActiveValue) {
        //   formValue.uInsuranceAmount = 0;
        // }
        formValue.uPropertyCurrentValue =
          this.activeHomeValue?.HomeValue ||
          (this.activeAVMHistory && this.activeAVMHistory.length > 0 ? this.activeAVMHistory[0]?.AVMValue : 0);

        this.dealAnalysisSheetFacade.setAnalyseDealParams(
          formValue,
          this.navigatedFromMyPropertyPage,
          this.mortgageStartYear
        );
      }
    });
  }

  closeDealAnalysisSheet() {

    if (!this.dealAnalysisForm.dirty) {
      this.resetAndNavigate();
    }

    if (this.dealAnalysisForm.dirty && !this.navigatedFromMyPropertyPage) {
      this.resetAndNavigate();
    }

    if (this.dealAnalysisForm.dirty && this.navigatedFromMyPropertyPage) {
      const unsavedDialogRef = this.dialog.open(UnsavedChangesDialogComponent, {
        width: '400px',
        data: { message: 'Do you want to close the page without saving the deal?' },
        disableClose: true,
      });
      unsavedDialogRef.afterClosed().subscribe((confirmSave) => {
        if (confirmSave) {
          this.resetAndNavigate();
        }
      });

    }


    // if (!this.isEmptyObject(this.myHomeState.DealAnalysisHomeValue)) {
    //   let dealPageValue = {
    //     customPotentialMonthly: this.dealAnalysisForm.value.uMonthlyRent,
    //     customHoaValue: this.dealAnalysisForm.value.uHoaFee,
    //     customInsuranceValue: this.dealAnalysisForm.value.uInsuranceAmount,
    //     customPurchaseValue: this.dealAnalysisForm.value.uPurchasePrice,
    //   };
    //   this.myHomeState.DealAnalysisHomeValue = dealPageValue;
    //   }
    // this.activePropertyDetails = {};
    // this.dealAnalysisSheetFacade.closeDealAnalysisSheet();
    // this.resetStatus = false;
    // this.router.navigate([], {
    //   queryParams: {
    //     'deal-analysis-property-id': null,
    //     'deal-analysis-id': null,
    //   },
    //   queryParamsHandling: 'merge',
    // });





  }

  resetDirty() {
    this.dealAnalysisForm.markAsPristine();
  }

  resetAndNavigate() {
    this.activePropertyDetails = {};
    this.dealAnalysisSheetFacade.closeDealAnalysisSheet();
    this.resetStatus = false;
    this.router.navigate([], {
      queryParams: {
        'deal-analysis-property-id': null,
        'deal-analysis-id': null,
      },
      queryParamsHandling: 'merge',
    });
  }

  onViewChange(event) {
    this.selectedPieChartOption = event.value;
    this.validatePieChartData();
  }

  onYearChange(event) {
    this.selectedYear = event.value;
    this.validatePieChartData();
  }

  formatIncomePieChart() {
    let incomeSummary: any = this.dealAnalysisSheetFacade.formatIncomePieChartData(this.selectedYear);

    let incomeChartCategories = Object.keys(incomeSummary);
    let incomeSummaryData = [];
    incomeChartCategories.forEach((category) => {
      let categoryObj = this.dealAnalysisSheetFacade.incomePieChartData.find((el) => el.key === category);
      if (categoryObj && incomeSummary[category]) {
        incomeSummaryData.push({ ...categoryObj, ...{ y: incomeSummary[category] } });
      }
    });
    this.incomePieChartData = incomeSummaryData;
    this.incomePieChartConfig = {
      type: 'pie',
      data: this.incomePieChartData,
      series: [{ type: 'pie', name: 'Value', colorByPoint: true, data: this.incomePieChartData }],
    };
  }

  formatExpensePieChart() {
    let expenseSummary: any = this.dealAnalysisSheetFacade.formatExpensePieChartData(this.selectedYear);
    let expenseChartCategories = Object.keys(expenseSummary);
    let expenseSummaryData = [];
    expenseChartCategories.forEach((category) => {
      let categoryObj = this.dealAnalysisSheetFacade.expensePieChartData.find((el) => el.key === category);
      if (categoryObj && expenseSummary[category]) {
        expenseSummaryData.push({ ...categoryObj, ...{ y: expenseSummary[category] } });
      }
    });
    this.expensePieChartData = expenseSummaryData;
    this.expensePieChartConfig = {
      type: 'pie',
      data: this.expensePieChartData,
      series: [{ type: 'pie', name: 'Value', colorByPoint: true, data: this.expensePieChartData }],
    };
  }

  changeIncomePieSettings(displayOption) {
    if (displayOption) {
      this.isIncomePieDisabled = false;
      this.isExpensePieDisabled = false;
    } else {
      this.isIncomePieDisabled = false;
      this.isExpensePieDisabled = true;
    }
    this.formatIncomePieChart();
    this.isIncomePieChecked = true;
    this.isPieChartVisible = true;
  }

  changeExpensePieSettings(displayOption) {
    if (displayOption) {
      this.isIncomePieDisabled = false;
      this.isExpensePieDisabled = false;
    } else {
      this.isIncomePieDisabled = true;
      this.isExpensePieDisabled = false;
    }
    this.formatExpensePieChart();
    this.isIncomePieChecked = false;
    this.isPieChartVisible = true;
  }

  validatePieChartData() {
    let sumOfIncome = 0;
    let sumOfExpense = 0;
    let dealAnalysisSummary = this.dealAnalysisSheetFacade.dealAnalysisResultValue;
    if (dealAnalysisSummary[this.selectedYear] && dealAnalysisSummary[this.selectedYear].revenue) {
      sumOfIncome += dealAnalysisSummary[this.selectedYear].revenue.operatingIncome;
      sumOfIncome += dealAnalysisSummary[this.selectedYear].revenue.monthlyRent;
    }
    if (dealAnalysisSummary[this.selectedYear] && dealAnalysisSummary[this.selectedYear].expense) {
      sumOfExpense += dealAnalysisSummary[this.selectedYear].expense.propertyTax;
      sumOfExpense += dealAnalysisSummary[this.selectedYear].expense.insurance;
      sumOfExpense += dealAnalysisSummary[this.selectedYear].expense.hoaFee;
      sumOfExpense += dealAnalysisSummary[this.selectedYear].expense.propertyManagement;
      sumOfExpense += dealAnalysisSummary[this.selectedYear].expense.maintenance;
      sumOfExpense += dealAnalysisSummary[this.selectedYear].expense.warrantyService;
      sumOfExpense += dealAnalysisSummary[this.selectedYear].expense.accounting;
    }
    if (!sumOfIncome && !sumOfExpense) {
      this.isPieChartVisible = false;
    } else if (sumOfIncome && sumOfExpense) {
      if (this.selectedPieChartOption === 'income') {
        this.changeIncomePieSettings(true);
      } else if (this.selectedPieChartOption === 'expense') {
        this.changeExpensePieSettings(true);
      }
    } else if (!sumOfExpense) {
      this.changeIncomePieSettings(false);
      this.selectedPieChartOption = 'income';
    } else if (!sumOfIncome) {
      this.changeExpensePieSettings(false);
      this.selectedPieChartOption = 'expense';
    }
  }

  formatLineChartData() {
    let lineChartData: lineChartCategories = this.dealAnalysisSheetFacade.formatLineChartData();
    let lineChartCategories = Object.keys(lineChartData);
    let salePredictionData = [];
    let returnIndicatorData = [];

    lineChartCategories.forEach((category) => {
      let salePrediction = this.salePredictionSummary.find((el) => el.key === category);
      let returnIndicator = this.returnIndicatorSummary.find((el) => el.key === category);
      if (salePrediction) {
        salePredictionData.push({ ...salePrediction, ...{ data: lineChartData[category] } });
      } else if (returnIndicator) {
        returnIndicatorData.push({ ...returnIndicator, ...{ data: lineChartData[category] } });
      }
    });
    this.salePredictionSummary = salePredictionData;
    this.returnIndicatorSummary = returnIndicatorData;
  }

  openDialog(type) {
    let _this = this;
    if (type == 'save') {
      this.title = 'Save the Analysis';
    } else if (type == 'share') {
      this.title = 'Save the Analysis before sharing';
    }
    _this.dialogRef = this.dialog.open(ConfirmDialogWithControlsComponent, {
      data: {
        title: this.title,
        inputValue: this.dealAnalysisFormInputValues['dealName']
          ? this.dealAnalysisFormInputValues['dealName']
          : this.dealName,
        proceedAction(dealName) {
          _this.dealName = dealName;
          _this.saveDeal('saveAs');
        },
        closeDialog() {
          _this.dialogRef.close();
        },
      },
    });
  }

  openRentalDialog(type) {

    if (type = 'saveRentalAnalysis') {
      this.title = 'Save Rental Analysis';
    }

    this.dialogRef = this.dialog.open(ConfirmDialogWithControlsComponent, {
      data: {
        title: this.title,
        inputValue: this.dealAnalysisFormInputValues['dealName']
          ? this.dealAnalysisFormInputValues['dealName']
          : this.dealName,
        proceedAction: (dealName) => {
          this.dealName = dealName;
          this.saveRentalAnalysisDeal('saveAs');
        },
        closeDialog: () => {
          this.dialogRef.close();
        },
      },
    });
  }

  saveRentalAnalysisDeal(type) {

    if (!this.dealAnalysisFormInputValues['id'] && type == 'saveRentalAnalysis') {
      this.openRentalDialog('saveRentalAnalysis');
      return;
    }
    if (!this.dealAnalysisForm.valid) {
      this.openSnackBar('Enter valid details', 'snackbar-warning');
      return;
    }
    if (!this.dealName) {
      this.openSnackBar('Deal Name Required', 'snackbar-warning');
      return;
    }

    let params: any = {
      PMXPropertyId: this.PMXPropertyId,
      listingId: this.listingId,
      name: this.dealName,
      fullAddress: this.activePropertyDetails.Address,
      computationInput: this.dealAnalysisForm.value,
      streetAddress: this.activePropertyDetails.propertyAddress,
      city: this.activePropertyDetails.City,
      state: this.activePropertyDetails.StateOrProvince,
      zipCode: this.activePropertyDetails.PostalCode,
      dealType: 'saveRental-Analysis' || null,
      isAdvanceMode: this.isAdvanceMode || false

    };

    if (type != 'saveAs') {
      params.id = this.dealAnalysisFormInputValues['id'] ? this.dealAnalysisFormInputValues['id'] : null;
    }
    this.dealAnalysisService
      .saveDeal(params)
      .pipe(takeUntil(this.onDestroyNotifier$))
      .subscribe((response: any) => {
        if (response && response.data && response.data.id) {
          this.resetDirty();
          this.dealAnalysisFormInputValues['id'] = response.data.id;

          this.dealAnalysisService
            .getSavedDealAnalysisByAddress({ PMXPropertyId: this.PMXPropertyId }).pipe(takeUntil(this.onDestroyNotifier$)).subscribe((res: any) => {
              if (res && res?.savedDeals && res?.savedDeals.length) {
                this.myHomeState.rentalDealsListValue = res?.savedDeals[0]?.deals;
              } else {
                this.myHomeState.rentalDealsListValue = [];
              }
            });
          this.cdRef.detectChanges();
          // const url = this.router
          //   .createUrlTree([], {
          //     relativeTo: this.activatedRoute,
          //     queryParams: { 'deal-analysis-id': response?.data?.id },
          //   })
          //   .toString();
          // this.location.go(url);
          if (Object.keys(this.dialogRef).length != 0) {
            this.dialogRef.close();
          }
          // this.dealSheetHeaderName = this.dealName;
          // this.formInputChange = false;
          // if (this.shareAnalysis == true) {
          //   this.shareDeal();
          //   this.shareAnalysis = false;
          // }
        }
      });
  }




  saveDeal(type) {
    if (!this.dealAnalysisFormInputValues['id'] && type == 'save') {
      this.openDialog('save');
      return;
    }
    if (!this.dealAnalysisForm.valid) {
      this.openSnackBar('Enter valid details', 'snackbar-warning');
      return;
    }
    if (!this.dealName) {
      this.openSnackBar('Deal Name Required', 'snackbar-warning');
      return;
    }

    let params: any = {
      PMXPropertyId: this.PMXPropertyId,
      listingId: this.listingId,
      name: this.dealName,
      fullAddress: this.activePropertyDetails.Address,
      computationInput: this.dealAnalysisForm.value,
      streetAddress: this.activePropertyDetails.propertyAddress,
      city: this.activePropertyDetails.City,
      state: this.activePropertyDetails.StateOrProvince,
      zipCode: this.activePropertyDetails.PostalCode,
    };
    if (type != 'saveAs') {
      params.id = this.dealAnalysisFormInputValues['id'] ? this.dealAnalysisFormInputValues['id'] : null;
    }
    this.dealAnalysisService
      .saveDeal(params)
      .pipe(takeUntil(this.onDestroyNotifier$))
      .subscribe((response: any) => {
        if (response && response.data && response.data.id) {
          this.dealAnalysisFormInputValues['id'] = response.data.id;
          const url = this.router
            .createUrlTree([], {
              relativeTo: this.activatedRoute,
              queryParams: { 'deal-analysis-id': response.data.id },
            })
            .toString();
          this.location.go(url);
          if (Object.keys(this.dialogRef).length != 0) {
            this.dialogRef.close();
          }
          this.dealSheetHeaderName = this.dealName;
          this.formInputChange = false;
          if (this.shareAnalysis == true) {
            this.shareDeal();
            this.shareAnalysis = false;
          }
        }
      });
  }
  shareDeal() {
    if (
      (!this.dealAnalysisFormInputValues['id'] && !this.dealAnalysisState.isSavedDealActiveValue) ||
      this.formInputChange
    ) {
      this.shareAnalysis = true;
      this.openDialog('share');
      return;
    }
    const matDialogRef = this.dialog.open(LinkShareComponent, {
      width: '500px',
      height: 'auto',
      disableClose: true,
      hasBackdrop: true,

      data: {
        shareStatus: this.dealShare,
        _id: this.dealAnalysisFormInputValues['id'],
        dealData: this.dealAnalysisState.dealIdValue,
        proceedAction: (sendData) => {
          let shareData: DealAnalysisShareInput = {
            recipientName: sendData.recipientName,
            recipientEmail: sendData.recipientEmail,
            name: sendData.senderName,
            email: sendData.senderEmail,
            id: this.dealAnalysisFormInputValues['id'],
          };
          matDialogRef.close();
          this.dealAnalysisService
            .shareDealAnalysis(shareData)
            .pipe(takeUntil(this.onDestroyNotifier$))
            .subscribe((res: any) => {
              this.openSnackBar(res, 'snackbar-success');
            });
        },
      },
    });
  }

  isEmptyObject(obj) {
    return obj && Object.keys(obj).length === 0;
  }

  private openSnackBar(message, style) {
    if (message) {
      this.snackBar.open(message, '', {
        duration: 3000,
        panelClass: [style ? style : ''],
        verticalPosition: 'top',
      });
    }
  }

  reset() {
    this.resetStatus = true;
    let resetParams = {};


    if (!this.dealAnalysisState.isSavedDealActiveValue) {
      resetParams = {
        uPurchasePrice: Math.round(this.dealAnalysisFormInputValues['uPurchasePrice']) || 0,
        uDownPayment: 20,
        uDownPaymentInDollors: this.initialDownPaymentInDollor,
        uLandValue: Math.round(this.dealAnalysisFormInputValues['uLandValue']) || 0,
        uClosingCost: Math.round(this.dealAnalysisFormInputValues['uPurchasePrice'] * 0.01) || 0,
        uRenovationCost: 0,
        uMonthlyMortgagePayment: this.dealAnalysisFormInputValues['uMonthlyMortgagePayment']
          ? Math.round(this.dealAnalysisFormInputValues['uMonthlyMortgagePayment'])
          : 0,
        uLoanAmount:
          this.myHomeState.mortgageListValue && this.myHomeState.mortgageListValue[0]?.principal
            ? this.myHomeState.mortgageListValue[0]?.principal
            : this.findLoanAmount(this.dealAnalysisFormInputValues['uPurchasePrice'], 20),
        uInterestRate:
          this.appState.appCompanyAliasValue == 'opscan'
            ? 6.5
            : this.myHomeState.mortgageListValue && this.myHomeState.mortgageListValue[0]?.interest
              ? this.myHomeState.mortgageListValue[0].interest
              : 3.5,
        // uInterestRate: this.appState.appCompanyAliasValue == 'opscan' ? 6.5 : 3.5,
        // uLoanTerm: 30,
        uLoanTerm:
          this.myHomeState.mortgageListValue && this.myHomeState.mortgageListValue[0]?.term
            ? this.myHomeState.mortgageListValue[0]?.term / 12
            : 30,

        uPropertyTaxAmount: Math.round(this.dealAnalysisFormInputValues['uPropertyTaxAmount']) || 0,
        uInsuranceAmount:
          this.myHomeState.DealAnalysisHomeValue && this.myHomeState.DealAnalysisHomeValue['customInsuranceValue']
            ? this.myHomeState.DealAnalysisHomeValue['customInsuranceValue'] * 12
            : 0,
        uHoaFee:
          this.myHomeState.DealAnalysisHomeValue && this.myHomeState.DealAnalysisHomeValue['customHoaValue']
            ? this.myHomeState.DealAnalysisHomeValue['customHoaValue']
            : 0,
        uHoaFeeYoYPercent: 0,
        uPropertyManagementPercent: 8,
        uPropertyManagementInDollors: this.initialPropertyManagementFeeInDollor,
        uMaintenancePercent: 10,
        uMaintenanceInDollors: this.initialRepairsInDollar,
        uImprovementPercent: 0,
        uWarrantyServiceCost: 0,
        uWarrantyServiceYoYPercent: 0,
        uAccountingCost: 500,
        uAccountingYoYPercent: 0,
        uMonthlyRent: Math.round(this.dealAnalysisFormInputValues['uMonthlyRent']) || 0,
        uMonthlyRentYoYPercent: 0,
        uRenterChurn: 0,
        uTenantSearchDuration: 0,
        uAssessedPropertyValue: Math.round(this.dealAnalysisFormInputValues['uAssessedPropertyValue']) || 0,
        uAssessedPropertyValueYoYPercent: 0,
        uFairMarketValue: Math.round(this.dealAnalysisFormInputValues['uFairMarketValue']) || 0,
        uFairMarketValueYoYPercent: 2,
        uSalesCommissionPercent: 5,
        uSalesSellerAgentComissionInDollors: this.intialSalesCommissionInDollor,
        uSalesBuyerAgentComissionInDollors: this.intialSalesCommissionInDollor,
        uSalesSellerAgentCommissionPercent: 2.5,
        uSalesBuyerAgentCommissionPercent: 2.5,
        uOtherExpenses: 5,
        uState: this.dealAnalysisFormInputValues['uState'],
      };
      this.dealAnalysisForm.setValue(resetParams);
      this.findInsuranceAmount();
    } else {
      resetParams = {
        uPurchasePrice: isFinite(this.dealAnalysisFormInputValues['uPurchasePrice'])
          ? Math.round(Number(this.dealAnalysisFormInputValues['uPurchasePrice']))
          : 0,

        uDownPayment: isFinite(this.dealAnalysisFormInputValues['uDownPayment'])
          ? Math.round(Number(this.dealAnalysisFormInputValues['uDownPayment']))
          : 20,
        uDownPaymentInDollors: this.initialDownPaymentInDollor,
        uLandValue: isFinite(this.dealAnalysisFormInputValues['uLandValue'])
          ? Math.round(Number(this.dealAnalysisFormInputValues['uLandValue']))
          : 0,
        uClosingCost: isFinite(this.dealAnalysisFormInputValues['uClosingCost'])
          ? Math.round(Number(this.dealAnalysisFormInputValues['uClosingCost']))
          : Math.round((this.dealAnalysisFormInputValues['uPurchasePrice'] * 1) / 100) || 0,
        uRenovationCost: isFinite(this.dealAnalysisFormInputValues['uRenovationCost'])
          ? Math.round(Number(this.dealAnalysisFormInputValues['uRenovationCost']))
          : 0,
        uLoanAmount: isFinite(this.dealAnalysisFormInputValues['uLoanAmount'])
          ? Math.round(Number(this.dealAnalysisFormInputValues['uLoanAmount']))
          : this.findLoanAmount(this.dealAnalysisFormInputValues['uPurchasePrice'], 20),
        uInterestRate: isFinite(this.dealAnalysisFormInputValues['uInterestRate'])
          ? Number(this.dealAnalysisFormInputValues['uInterestRate'])
          : this.appState.appCompanyAliasValue == 'opscan'
            ? 6.5
            : 3.5,
        uLoanTerm: isFinite(this.dealAnalysisFormInputValues['uLoanTerm'])
          ? Math.round(Number(this.dealAnalysisFormInputValues['uLoanTerm']))
          : 30,
        uMonthlyMortgagePayment: isFinite(this.dealAnalysisFormInputValues['uMonthlyMortgagePayment'])
          ? Math.round(Number(this.dealAnalysisFormInputValues['uMonthlyMortgagePayment']))
          : 0,
        uPropertyTaxAmount: isFinite(this.dealAnalysisFormInputValues['uPropertyTaxAmount'])
          ? Math.round(Number(this.dealAnalysisFormInputValues['uPropertyTaxAmount']))
          : 0,
        uInsuranceAmount: isFinite(this.dealAnalysisFormInputValues['uInsuranceAmount'])
          ? Math.round(Number(this.dealAnalysisFormInputValues['uInsuranceAmount']))
          : 0,
        uHoaFee: isFinite(this.dealAnalysisFormInputValues['uHoaFee'])
          ? Math.round(Number(this.dealAnalysisFormInputValues['uHoaFee']))
          : 0,
        uHoaFeeYoYPercent: isFinite(this.dealAnalysisFormInputValues['uHoaFeeYoYPercent'])
          ? Number(this.dealAnalysisFormInputValues['uHoaFeeYoYPercent'])
          : 0,
        uPropertyManagementPercent: isFinite(this.dealAnalysisFormInputValues['uPropertyManagementPercent'])
          ? Number(this.dealAnalysisFormInputValues['uPropertyManagementPercent'])
          : 8,
        uPropertyManagementInDollors: this.initialPropertyManagementFeeInDollor,
        uMaintenancePercent: isFinite(this.dealAnalysisFormInputValues['uMaintenancePercent'])
          ? Number(this.dealAnalysisFormInputValues['uMaintenancePercent'])
          : 10,
        uMaintenanceInDollors: this.initialRepairsInDollar,
        uImprovementPercent: isFinite(this.dealAnalysisFormInputValues['uImprovementPercent'])
          ? Number(this.dealAnalysisFormInputValues['uImprovementPercent'])
          : 0,
        uWarrantyServiceCost: isFinite(this.dealAnalysisFormInputValues['uWarrantyServiceCost'])
          ? Math.round(Number(this.dealAnalysisFormInputValues['uWarrantyServiceCost']))
          : 0,
        uWarrantyServiceYoYPercent: isFinite(this.dealAnalysisFormInputValues['uWarrantyServiceYoYPercent'])
          ? Number(this.dealAnalysisFormInputValues['uWarrantyServiceYoYPercent'])
          : 0,
        uAccountingCost: isFinite(this.dealAnalysisFormInputValues['uAccountingCost'])
          ? Math.round(Number(this.dealAnalysisFormInputValues['uAccountingCost']))
          : 500,
        uAccountingYoYPercent: isFinite(this.dealAnalysisFormInputValues['uAccountingYoYPercent'])
          ? Number(this.dealAnalysisFormInputValues['uAccountingYoYPercent'])
          : 0,
        uMonthlyRent: isFinite(this.dealAnalysisFormInputValues['uMonthlyRent'])
          ? Math.round(Number(this.dealAnalysisFormInputValues['uMonthlyRent']))
          : 0,
        uMonthlyRentYoYPercent: isFinite(this.dealAnalysisFormInputValues['uMonthlyRentYoYPercent'])
          ? Number(this.dealAnalysisFormInputValues['uMonthlyRentYoYPercent'])
          : 0,
        uRenterChurn: isFinite(this.dealAnalysisFormInputValues['uRenterChurn'])
          ? Number(this.dealAnalysisFormInputValues['uRenterChurn'])
          : 0,
        uTenantSearchDuration: isFinite(this.dealAnalysisFormInputValues['uTenantSearchDuration'])
          ? Math.round(Number(this.dealAnalysisFormInputValues['uTenantSearchDuration']))
          : 0,
        uAssessedPropertyValue: isFinite(this.dealAnalysisFormInputValues['uAssessedPropertyValue'])
          ? Math.round(Number(this.dealAnalysisFormInputValues['uAssessedPropertyValue']))
          : 0,
        uAssessedPropertyValueYoYPercent: isFinite(this.dealAnalysisFormInputValues['uAssessedPropertyValueYoYPercent'])
          ? Number(this.dealAnalysisFormInputValues['uAssessedPropertyValueYoYPercent'])
          : 0,
        uFairMarketValue: isFinite(this.dealAnalysisFormInputValues['uFairMarketValue'])
          ? Math.round(Number(this.dealAnalysisFormInputValues['uFairMarketValue']))
          : 0,
        uFairMarketValueYoYPercent: isFinite(this.dealAnalysisFormInputValues['uFairMarketValueYoYPercent'])
          ? Number(this.dealAnalysisFormInputValues['uFairMarketValueYoYPercent'])
          : 2,
        uSalesCommissionPercent: isFinite(this.dealAnalysisFormInputValues['uSalesCommissionPercent'])
          ? Number(this.dealAnalysisFormInputValues['uSalesCommissionPercent'])
          : 5,
        uSalesSellerAgentCommissionPercent: isFinite(
          this.dealAnalysisFormInputValues['uSalesSellerAgentCommissionPercent']
        )
          ? Number(this.dealAnalysisFormInputValues['uSalesSellerAgentCommissionPercent'])
          : 2.5,
        uSalesBuyerAgentCommissionPercent: isFinite(
          this.dealAnalysisFormInputValues['uSalesBuyerAgentCommissionPercent']
        )
          ? Number(this.dealAnalysisFormInputValues['uSalesBuyerAgentCommissionPercent'])
          : 2.5,
        uOtherExpenses: isFinite(this.dealAnalysisFormInputValues['uOtherExpenses'])
          ? Number(this.dealAnalysisFormInputValues['uOtherExpenses'])
          : 5,
        uSalesComissionInDollors: this.intialSalesCommissionInDollor,
        uState: this.dealAnalysisFormInputValues['uState'],
      };
      this.dealAnalysisForm.setValue(resetParams);
    }
    this.valuesAfterReset = this.dealAnalysisForm.value
  }

  toggleChart(type) {
    if (type === 'incomeEvaluation') {
      this.showIncomeEvalChart = !this.showIncomeEvalChart;
    } else if (type === 'equityEvaluation') this.showEquityEvalChart = !this.showEquityEvalChart;
  }

  findLoanAmount(purchasePrice, downPayment) {
    if (purchasePrice) {
      const loanAmount = purchasePrice - purchasePrice * (downPayment / 100);
      return Math.round(loanAmount);
    } else {
      return 0;
    }
  }

  findMonthlyMortgagePayment() {
    const monthlyMortgageInput = {
      uLoanAmount: this.dealAnalysisForm.controls.uLoanAmount.value,
      uInterestRate: this.dealAnalysisForm.controls.uInterestRate.value / 100,
      uLoanTerm: this.dealAnalysisForm.controls.uLoanTerm.value,
      uYear: 1,
    };
    const monthlyMortgagePayment = this.dealAnalysisSheetFacade.findMonthlyMortgagePayment(monthlyMortgageInput);

    if (
      monthlyMortgagePayment &&
      monthlyMortgagePayment.monthlyMortgage &&
      (Number.isNaN(monthlyMortgagePayment.monthlyMortgage) || !isFinite(monthlyMortgagePayment.monthlyMortgage))
    ) {
      this.dealAnalysisForm.controls.uMonthlyMortgagePayment.setValue(0);
    } else {
      this.dealAnalysisForm.controls.uMonthlyMortgagePayment.setValue(
        monthlyMortgagePayment.monthlyMortgage ? monthlyMortgagePayment.monthlyMortgage : 0
      );
    }
  }

  findInsuranceAmount() {

    const insuranceAmountInput: InsuranceAmountInput = {
      uInsuranceAmount:
        this.dealAnalysisForm.controls.uInsuranceAmount.dirty || this.dealAnalysisState.isSavedDealActiveValue
          ? this.dealAnalysisForm.controls.uInsuranceAmount.value
          : this.myHomeState.DealAnalysisHomeValue['customInsuranceValue']
            ? this.myHomeState.DealAnalysisHomeValue['customInsuranceValue'] * 12 : this.dealAnalysisForm.controls.uInsuranceAmount.value ? this.dealAnalysisForm.controls.uInsuranceAmount.value
              : 0,

      uLandValue: this.dealAnalysisForm.controls.uLandValue.value,
      uFairMarketValue: this.dealAnalysisForm.controls.uFairMarketValue.value,
      uPurchasePrice: this.dealAnalysisForm.controls.uPurchasePrice.value,
      uFairMarketValueYoYRatio: 0,
      uState: this.dealAnalysisFormInputValues['uState'],
      uYear: 1,
    };
    let insuranceAmount;
    if (
      // (insuranceAmountInput.uLandValue < insuranceAmountInput.uFairMarketValue ||
      //   this.dealAnalysisState.isSavedDealActiveValue)
      // &&
      this.appState.appCompanyAliasValue != 'opscan'
    ) {
      insuranceAmount = this.dealAnalysisSheetFacade.findInsuranceAmount(insuranceAmountInput);
    } else {
      insuranceAmount = 0;
    }

    if (
      (insuranceAmount &&
        insuranceAmount.insurance &&
        (Number.isNaN(insuranceAmount.insurance) || !isFinite(insuranceAmount.insurance))) ||
      !insuranceAmount
    ) {
      this.dealAnalysisForm.controls.uInsuranceAmount.setValue(0);
    } else {
      this.dealAnalysisForm.controls.uInsuranceAmount.setValue(Math.round(insuranceAmount.insurance));
      this.findMonthlyMortgagePayment();
    }

  }

  calculateAssociationFee(associationFee, associationFeeFrequency) {
    switch (associationFeeFrequency) {
      case 'Annually':
      case 'Yearly':
        return associationFee;
      case 'Monthly':
        return associationFee * 12;
      case 'Daily':
        return associationFee * 365;
      case 'Weekly':
        return associationFee * 52;
      case 'Bi-Weekly':
        return associationFee * 26;
      case 'Bi-Monthly':
        return associationFee * 6;
      case 'Quarterly':
        return associationFee * 4;
      case 'Semi Annual':
      case 'Semi-Annual':
      case 'Semiannually':
      case 'Semi-Annually':
        return associationFee * 2;
      case 'Seasonal':
        return associationFee;
    }
  }

  calculateSalesCommissionInDollar(purchasePrice, commission) {
    let salesCommissionInDollar = (purchasePrice * commission) / 100;
    return Math.round(salesCommissionInDollar);
  }

  calculateRepairsInDollar(monthlyRent, repairPercentage) {
    let annualRent = monthlyRent * 12;
    let repairsInDollar = (annualRent * repairPercentage) / 100;
    return Math.round(repairsInDollar);
  }

  calculatePropertyManagementFeeInDollars(monthlyRent, propertyManagemenPercentage) {
    let annualRent = monthlyRent * 12;
    let propertyManagementInDollar = (annualRent * propertyManagemenPercentage) / 100;
    return Math.round(propertyManagementInDollar);
  }
  calculateDownpaymentInDollor(purchasePrice, downPaymentPercentage) {
    let downPaymentInDollor = (purchasePrice * downPaymentPercentage) / 100;
    return Math.round(downPaymentInDollor);
  }



  toggleYear(endYear, expand) {
    const yearSet = new Set(this.dealAnalysisSheetFacade.displayYearList$.value);
    let yearList;
    switch (endYear) {
      case 10:
        yearList = [6, 7, 8, 9];
        this.isExpanded10 = expand;
        break;
      case 15:
        yearList = [11, 12, 13, 14];
        this.isExpanded15 = expand;
        break;
      case 20:
        yearList = [16, 17, 18, 19];
        this.isExpanded20 = expand;
        break;
      case 30:
        yearList = [21, 22, 23, 24, 25, 26, 27, 28, 29];
        this.isExpanded30 = expand;
        break;
    }
    expand ? yearList.forEach((year) => yearSet.add(year)) : yearList.forEach((year) => yearSet.delete(year));
    this.dealAnalysisSheetFacade.updateDisplayYear(Array.from(yearSet).sort((first, second) => first - second));
  }

  toggleAdvanceMode(): void {
    this.isAdvanceMode = !this.isAdvanceMode;
    if (this.isAdvanceMode) this.dealAnalysisState.isAdvancedModeOPenValue = true;
    else this.dealAnalysisState.isAdvancedModeOPenValue = false;
    this.analyseDeal.pipe(takeUntil(this.onDestroyNotifier$)).subscribe((formValue) => {
      if (formValue) {
        // if (!this.dealAnalysisForm.controls.uInsuranceAmount.dirty && !this.dealAnalysisState.isSavedDealActiveValue) {
        //   formValue.uInsuranceAmount = this.myHomeState.DealAnalysisHomeValue['customInsuranceValue']
        //     ? this.myHomeState.DealAnalysisHomeValue['customInsuranceValue'] * 12
        //     : 0;
        // }
        formValue.uPropertyCurrentValue =
          this.activeHomeValue?.HomeValue ||
          (this.activeAVMHistory && this.activeAVMHistory.length > 0 ? this.activeAVMHistory[0]?.AVMValue : 0);

        this.dealAnalysisSheetFacade.setAnalyseDealParams(
          formValue,
          this.navigatedFromMyPropertyPage,
          this.mortgageStartYear
        );
      }
    });
  }

  isNumber(value) {
    return !isNaN(parseInt(value, 10));
  }
}

function ValidateForMax(control: AbstractControl): { [key: string]: any } | null {
  return null;
}

function ValidateForTenantSearchTime(control: AbstractControl): { [key: string]: any } | null {
  if (control.value && control.value > 12) {
    return { termLimitInvalid: true };
  }
  return null;
}

function ValidateForTerm(control: AbstractControl): { [key: string]: any } | null {
  if ((control.value && control.value >= 50) || control.value <= 0) {
    return { termLimitInvalid: true };
  }
  return null;
}

function ValidateForDownPayment(uPurchasePriceControlName: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const formGroup = control.parent;
    if (!formGroup) return null;
    const uPurchasePriceControl = formGroup.get(uPurchasePriceControlName);
    if (uPurchasePriceControl) {
      const uPurchasePrice = uPurchasePriceControl.value;
      const downPayment = control.value;
      if (downPayment && downPayment > uPurchasePrice) {
        return { downPaymentLimitInvalid: true };
      }
    }
    return null;
  };
}
