import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserState {
  plans = new BehaviorSubject<any>([]);
  connectedUsers = new BehaviorSubject<any>([]);
  connectedAgents = new BehaviorSubject<any>([]);
  couponValue = new BehaviorSubject<any>({});
  totalPlans = new BehaviorSubject<any>(0);

  private businessAreaListSub$ = new BehaviorSubject<Array<any>>(null);
  businessAreaList$ = this.businessAreaListSub$.asObservable();

  private interestedAreaListSub$ = new BehaviorSubject<Array<any>>([]);
  interestedAreaList$ = this.interestedAreaListSub$.asObservable();

  private userClientSub$ = new BehaviorSubject<any>(null);
  userClient$ = this.userClientSub$.asObservable();

  private brandInfoSub$ = new BehaviorSubject<any>(null);
  brandInfo$ = this.brandInfoSub$.asObservable();

  private userClientGetInProgressSub$ = new BehaviorSubject<boolean>(false);
  userClientGetInProgress$ = this.userClientGetInProgressSub$.asObservable();

  private userDetailSub$ = new BehaviorSubject<any>(null);
  userDetail$ = this.userDetailSub$.asObservable();

  private userLocationSub$ = new BehaviorSubject<any>({});
  userLocation$ = this.userLocationSub$.asObservable();

  private workspaceSub$ = new BehaviorSubject<any>({});
  workspace$ = this.workspaceSub$.asObservable();

  public get businessAreaListValue(): Array<any> {
    return this.businessAreaListSub$.value;
  }

  public set businessAreaListValue(array: Array<any>) {
    this.businessAreaListSub$.next(array);
  }

  public get interestedAreaListValue(): Array<any> {
    return this.interestedAreaListSub$.value;
  }

  public set interestedAreaListValue(array: Array<any>) {
    this.interestedAreaListSub$.next(array);
  }

  public get userClient() {
    return this.userClientSub$.value;
  }

  public set userClient(client) {
    this.userClientSub$.next(client);
  }

  public get brandInfo() {
    return this.brandInfoSub$.value;
  }

  public set brandInfo(brand: any) {
    this.brandInfoSub$.next(brand);
  }
  public set userClientGetInProgress(status) {
    this.userClientGetInProgressSub$.next(status);
  }

  private getBusinessAreaInProgressSub$ = new BehaviorSubject<boolean>(false);
  getBusinessAreaInProgress$ = this.getBusinessAreaInProgressSub$.asObservable();

  public get getBusinessAreaInProgressValue(): boolean {
    return this.getBusinessAreaInProgressSub$.value;
  }

  public set getBusinessAreaInProgressValue(status: boolean) {
    this.getBusinessAreaInProgressSub$.next(status);
  }

  public get userDetails() {
    return this.userDetailSub$.value;
  }

  public set userDetails(logUser) {
    this.userDetailSub$.next(logUser);
  }

  public get userLocation() {
    return this.userLocationSub$.value;
  }

  public set userLocation(location) {
    this.userLocationSub$.next(location);
  }

  public get workspace() {
    return this.workspaceSub$.value;
  }

  public set workspace(value) {
    this.workspaceSub$.next(value);
  }

  private userPlanStatus$ = new BehaviorSubject<any>(null);
  userPlanStatus = this.userPlanStatus$.asObservable();

  public get getUserPlanStatusValue() {
    return this.userPlanStatus$.value;
  }
  public set getUserPlanStatusValue(status) {
    this.userPlanStatus$.next(status);
  }

  private userEditStatus$ = new BehaviorSubject<boolean>(false);
  userEditStatus = this.userEditStatus$.asObservable();

  public get getUserEditStatusValue(): boolean {
    return this.userEditStatus$.value;
  }
  public set getUserEditStatusValue(status: boolean) {
    this.userEditStatus$.next(status);
  }

  private editUserValue$ = new BehaviorSubject<any>(null);
  editUserValue = this.editUserValue$.asObservable();

  public get getEditUserValue() {
    return this.editUserValue$.value;
  }
  public set getEditUserValue(status) {
    this.editUserValue$.next(status);
  }

  private userRoleValue$ = new BehaviorSubject<string>(null);
  userRoleValue = this.userRoleValue$.asObservable();

  public get getUserRoleValue() {
    return this.userRoleValue$.value;
  }
  public set getUserRoleValue(status) {
    this.userRoleValue$.next(status);
  }

  private mlsListSub$ = new BehaviorSubject<Array<any>>(null);
  mlsList$ = this.mlsListSub$.asObservable();

  public get mlsListValue(): Array<any> {
    return this.mlsListSub$.value;
  }

  public set mlsListValue(array: Array<any>) {
    this.mlsListSub$.next(array);
  }

  private connectedAgentsMlsListSub$ = new BehaviorSubject<Array<any>>(null);
  connectedAgentsMlsList$ = this.connectedAgentsMlsListSub$.asObservable();

  public get connectedAgentsMlsListValue(): Array<any> {
    return this.connectedAgentsMlsListSub$.value;
  }

  public set connectedAgentsMlsListValue(array: Array<any>) {
    this.connectedAgentsMlsListSub$.next(array);
  }

  private appraisersMlsListSub$ = new BehaviorSubject<Array<any>>(null);
  appraisersMlsList$ = this.appraisersMlsListSub$.asObservable();

  public get appraisersMlsListValue(): Array<any> {
    return this.appraisersMlsListSub$.value;
  }

  public set appraisersMlsListValue(array: Array<any>) {
    this.appraisersMlsListSub$.next(array);
  }

  private cmaConfigurationSub$ = new BehaviorSubject<Array<any>>(null);
  cmaConfigurationList$ = this.cmaConfigurationSub$.asObservable();
  public get cmaConfigurationListValue(): Array<any> {
    return this.cmaConfigurationSub$.value;
  }
  public set cmaConfigurationListValue(array: Array<any>) {
    this.cmaConfigurationSub$.next(array);
  }
}
