<div class="sample-mortgage">
  <div class="page1">
    <div class="row">
      <div class="col-md-12 col-12">
        <img src="../../../../assets/images/prop-img.png" class="img-fluid" />
      </div>
    </div>

    <div class="valuetest-value-box">
      <div class="valuetest-values">
        <div class="value-box-header1">VALUE TESTED:</div>
        <div class="value-box-value1">$460,414</div>
      </div>
      <div class="valuetest-values">
        <div class="value-box-header2">RANGE OF SOLD COMPARABLES:</div>
        <div class="value-box-value2">$466,537 - $541,000</div>
      </div>
      <div class="valuetest-values">
        <div class="value-box-header2">RANGE OF PRICE PER SF:</div>
        <div class="value-box-value2">$248 - $288</div>
      </div>
    </div>
    <p>Research incorporates MLS data and insights provided by indu fc - a licensed appraiser in the state of DE.</p>
    <p>Contact: indu fc | 4444444444</p>
    <p>Disclaimer: This is not an appraisal and should not be used in lieu of an appraisal.</p>
  </div>
  <div class="page2">
    <div class="mortgage-header">COMPARABLE SEARCH CRITERIA</div>
    <p class="sub-header">
      ValueTest.ai employs expandable appraisal parameters refined by AI-driven comparable selection
    </p>
    <div class="table-responsive search-criteria">
      <table>
        <thead>
          <tr>
            <td colspan="4">Set Parameters (Based on Subject's Characteristics)</td>
            <td>Weighting</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Distance</td>
            <td>Radius</td>
            <td>0 Miles</td>
            <td>1 Mile</td>
            <td>40%</td>
          </tr>
          <tr>
            <td>Living Area</td>
            <td>+/-10%</td>
            <td>1,691</td>
            <td>2,065</td>
            <td>30%</td>
          </tr>
          <tr>
            <td>Lot Size</td>
            <td>+/-50%</td>
            <td>2,178</td>
            <td>6,534</td>
            <td>Not Scored</td>
          </tr>
          <tr>
            <td>Stories</td>
            <td>+/-0</td>
            <td>--</td>
            <td>--</td>
            <td>Not Scored</td>
          </tr>
          <tr>
            <td>Year Built</td>
            <td>+/-5 Years</td>
            <td>--</td>
            <td>--</td>
            <td>Not Scored</td>
          </tr>
          <tr>
            <td>Year Built</td>
            <td>6 Months</td>
            <td>07/28/2024</td>
            <td>01/28/2025</td>
            <td>30%</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div>
      <span class="mortgage-header"> HOW TO USE VALUETEST.AI RESEARCH </span>
      <div class="research-use-box">
        <p class="research-use">
          1. This is preliminary appraisal research consisting of up to 4 sales/2 listings combining appraisal
          parameters and AI similarity scoring. There are no values or adjustments just a range of sales that may be on
          an appraiser's radar.
        </p>
        <p class="research-use">
          2. Click on the comps to review interior photos/street views of the comps to make your pricing adjustments
          based on condition, upgrades, location and market conditions.
        </p>
        <p class="research-use">
          3. Take into account net to seller, as seller concessions on the comparables provided may be directly deducted
          dollar for dollar by an appraiser.
        </p>
        <p class="research-use">
          4. Expanded search is available where your market demands larger search filters or to get the entire research
          picture.
        </p>
        <p class="research-use">
          5. 45 Day Refresh Available for listing appointments, price reductions and CMA reruns when under contract.
        </p>
      </div>
    </div>
    <div>
      <h5 class="mls-header">Bright MLS ®</h5>
      <p>
        The real estate listing information and related content displayed is provided exclusively for consumers'
        personal, non- commercial use and may not be used for any purpose other than to identify prospective properties
        consumers may be interested in purchasing or selling. This information and related content is deemed reliable
        but is not guaranteed accurate by Bright MLS ® . Copyright © 2025 Bright MLS ® . All rights reserved.
      </p>
    </div>
  </div>

  <div class="page3">
    <div class="subject-box">
      <h5 class="subject">Subject - 537 Arrowgrass Ln, New Castle, DE 19720 | MLS #: DENC2065466</h5>
      <button class="offmarket-btn">Off-Market</button>
    </div>
    <mat-divider class="divider"></mat-divider>
    <section>
      <div class="house-details">
        <div class="detail">Beds<br />2</div>
        <div class="detail">Baths<br />2.0</div>
        <div class="detail">Living Area<br />1,878</div>
        <div class="detail">Lot Size<br />4356</div>
        <div class="detail">Year Built<br />--</div>
        <div class="detail">Sold Date<br />08/26/2024</div>
        <div class="detail">Sold Price<br />$460,414</div>
        <div class="detail">Rent Amount<br />--</div>
        <div class="detail">Lien Amount<br />$339,119</div>
      </div>
    </section>
    <div class="row mt-3">
      <div class="col-md-6 col-12"><img src="../../../../assets/images/sample-property.png" class="img-fluid" /></div>
      <div class="col-md-6 col-12"><img src="../../../../assets/images/map-img.png" class="img-fluid" /></div>
    </div>
    <!-- <div class="image-box">
      <div>
        <img src="../../../../assets/images/sample-property.png" class="image-fluid" />
      </div>
      <div>
        <img src="../../../../assets/images/map-img.png" class="image-fluid" />
      </div>
    </div> -->
    <div class="key-details-box">
      <h6 class="key-details-header">Key Details</h6>
      <div class="key-details">
        <div class="key-details-section-1">
          <div><b>Type:</b> Townhouse</div>
          <div><b>County:</b> New Castle County</div>
          <div><b>Subdivision:</b> --</div>
        </div>
        <div class="key-details-section-2">
          <div><b>Style:</b> Colonial</div>
          <div><b>Stories:</b> --</div>
          <div><b>Garages:</b> 2.00</div>
        </div>
        <div class="key-details-section-3">
          <div><b>Construction:</b> Blown-In Insulation,Concrete,CPVC/PVC,Glass,Stick Built,Vinyl Siding</div>
          <div><b>Fireplaces:</b> --</div>
        </div>
        <div class="key-details-section-4">
          <div><b>List Price:</b> $ 460,414</div>
          <div><b>Original List Price:</b> $ 460,414</div>
          <div><b>Listing Date:</b> 07/26/2024</div>
          <div><b>Off Market Date:</b> 11/14/2024</div>
          <div><b>Price Per Sq Ft:</b> $ 245</div>
        </div>
      </div>
    </div>
    <mat-divider class="divider"></mat-divider>
    <div class="features">
      <h6 class="feature-header">Features</h6>
      <div class="feature-box">
        <div class="feature-box-1">
          <div><b>Association YN:</b> Y</div>
          <div><b>Association Fee:</b> $ 195 / Monthly</div>
          <div><b>Interior Features:</b> --</div>
          <div><b>Exterior Features:</b> --</div>
        </div>
        <div class="feature-box-2">
          <div><b>Heating:</b> 90% Forced Air, Propane - Leased</div>
          <div><b>Cooling:</b> Central A/C,Electric</div>
        </div>
        <div class="feature-box-3">
          <div><b>Pool:</b> Y</div>
          <div><b>Parking Features:</b> Attached Garage,Driveway,Garage - Front Entry</div>
          <div><b>Community Features:</b> --</div>
        </div>
        <div class="feature-box-4">
          <div><b>Basement:</b> Full,Unfinished, Basement</div>
          <div><b>Basement SqFt:</b> 0</div>
          <div><b>Water Source:</b> --</div>
        </div>
      </div>
    </div>
    <mat-divider class="divider"></mat-divider>
    <div class="remarks-box">
      <h6 class="remarks-header">Remarks</h6>
      <p>
        The Magnolia II villa features 2 bedrooms, 2 baths with the option of adding 2 additional bedrooms and 1
        additional bath upstairs. Amenities for HOA include: Outdoor Deck, Great Room, Sports Bar, Fitness Center,
        Indoor Pool, Gathering Room, Craft Room, Library, and Large Community Kitchen. Common area maintenance, lawn
        care, trash pick-up, snow removal and mulch (1x a year) all included in HOA fees. 198 home community. *Photos
        shown may have upgrades that are optional and are used for marketing purposes.
      </p>
    </div>
  </div>
  <div class="page4">
    <div class="map"></div>
    <div class="table-responsive prop-list">
      <table>
        <thead>
          <tr>
            <td>#</td>
            <td class="ads-sticky">Dis</td>
            <td>Address</td>
            <td>GLA</td>
            <td>Bed/Bath</td>
            <td>Lt size</td>
            <td>Pool</td>
            <td>Close</td>
            <td>DOM</td>
            <td>List Price</td>
            <td>per Sf</td>
            <td>Last Close Price</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>S</td>
            <td>--</td>

            <td>
              537 Arrowgrass Ln<br />
              New Castle , DE 19720
            </td>

            <td>1,878</td>

            <td>2 / 2.0</td>

            <td>4,356</td>

            <td>Y</td>
            <td>08/26/2024</td>
            <td>--</td>
            <td>$460,414</td>
            <td>$245</td>
            <td>$460,414</td>
          </tr>
          <tr>
            <td>S</td>
            <td>--</td>

            <td>
              537 Arrowgrass Ln<br />
              New Castle , DE 19720
            </td>

            <td>1,878</td>

            <td>2 / 2.0</td>

            <td>4,356</td>

            <td>Y</td>
            <td>08/26/2024</td>
            <td>--</td>
            <td>$460,414</td>
            <td>$245</td>
            <td>$460,414</td>
          </tr>
          <tr>
            <td>S</td>
            <td>--</td>

            <td>
              537 Arrowgrass Ln<br />
              New Castle , DE 19720
            </td>

            <td>1,878</td>

            <td>2 / 2.0</td>

            <td>4,356</td>

            <td>Y</td>
            <td>08/26/2024</td>
            <td>--</td>
            <td>$460,414</td>
            <td>$245</td>
            <td>$460,414</td>
          </tr>
          <tr>
            <td>S</td>
            <td>--</td>

            <td>
              537 Arrowgrass Ln<br />
              New Castle , DE 19720
            </td>

            <td>1,878</td>

            <td>2 / 2.0</td>

            <td>4,356</td>

            <td>Y</td>
            <td>08/26/2024</td>
            <td>--</td>
            <td>$460,414</td>
            <td>$245</td>
            <td>$460,414</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="page5">
    <div class="table-responsive prop-list">
      <table>
        <thead>
          <tr>
            <td colspan="5">155 Results 18 Active 93 Sold</td>
          </tr>
          <tr>
            <td>Inventory Analysis</td>
            <td>7-12</td>
            <td>4-6</td>
            <td>0-3</td>
            <td>Trend</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Total # of Comparables Sales (Settled)</td>
            <td>50</td>
            <td>28</td>
            <td>15</td>
            <td></td>
          </tr>
          <tr>
            <td>Absorption Rate</td>
            <td>08.33</td>
            <td>09.33</td>
            <td>5</td>
            <td></td>
          </tr>
          <tr>
            <td>Total # of Comparables (Active)</td>
            <td>21</td>
            <td>19</td>
            <td>19</td>
            <td></td>
          </tr>
          <tr>
            <td>Months of Housing Supply</td>
            <td>2.52</td>
            <td>2.036</td>
            <td>3.8</td>
            <td></td>
          </tr>
          <tr>
            <td>Median Comparable Sale Price</td>
            <td>$369,950</td>
            <td>$382,500</td>
            <td>$343,000</td>
            <td></td>
          </tr>
          <tr>
            <td>Median Comparable List Price</td>
            <td>$466,537</td>
            <td>$471,990</td>
            <td>$449,888</td>
            <td></td>
          </tr>
          <tr>
            <td>Median Comparable Listing DOM</td>
            <td>40</td>
            <td>73</td>
            <td>13</td>
            <td></td>
          </tr>
          <tr>
            <td>Median Comparable Sales DOM</td>
            <td>50</td>
            <td>43</td>
            <td>58</td>
            <td></td>
          </tr>
          <tr>
            <td>Median Sale Price as % of List Price</td>
            <td>100</td>
            <td>100</td>
            <td>100</td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="mortgage-header">MORTGAGE REVIEW</div>
  <div class="table-responsive">
    <table class="table set-parameters">
      <tbody>
        <tr>
          <th>Total Principal</th>
          <td>$341,500</td>
        </tr>
        <tr>
          <th>Estimated Loan Balance</th>
          <td>$339,119</td>
        </tr>
        <tr>
          <th>Current Estimated Property Value</th>

          <td>$460,414</td>
        </tr>
        <tr>
          <th>Net Equity</th>
          <td>$121,295</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="table-responsive prop-list">
    <h3 class="prop-tbl mb-3">Mortgage Details</h3>
    <table>
      <thead>
        <tr>
          <td>Loan Date</td>
          <td class="ads-sticky">Principal</td>
          <td>Interest Rate</td>
          <td>Rate Type</td>
          <td>Loan Term</td>
          <td>Est. Monthly Payment</td>
          <td>Est. Balance</td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>May 13, 2024</td>
          <td>$341,500</td>

          <td>6.79</td>

          <td>--</td>

          <td>360</td>

          <td>$2,224</td>

          <td>$339,119</td>

          <!-- <td>
                {{
                  comps.estimatedLoanBalance
                    ? (comps.estimatedLoanBalance | currency: 'USD':'symbol':'0.0-0')
                    : '--'
                }}
              </td> -->
        </tr>
      </tbody>
    </table>
  </div>
  <div id="footer">
    <table cellspacing="0" cellpadding="5" width="100%">
      <!-- <tr *ngIf="reportDetails?.coBrandingDisclaimer">
            <td>
              {{ reportDetails?.coBrandingDisclaimer }}
            </td>
          </tr> -->
      <tr>
        <td>
          The data elements, property characteristics, analytics, market insights, listing details, commentary and other
          information contained in this report are sourced and calculated from county assessor and MLS data. This report
          is provided solely for general business information purposes. All data is deemed reliable, but is not
          guaranteed.
        </td>
      </tr>
    </table>
  </div>
</div>
<div mat-dialog-actions class="mb-0">
  <div class="w-100 text-right">
    <button mat-button color="primary" (click)="proceedCancelAction()">Close</button>
  </div>
</div>
