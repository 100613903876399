import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class OpportunityScannerApi {
  prospektrBackendBaseURL = environment.prospektrBackendBaseURL;
  // prospektrBackendBaseURL = "http://localhost:3000/";

  opportunitiesApi = 'opportunity';
  getZoningCodesApi = 'getZoningCodes';
  getNeighborhoodApi = 'getNeighborhood';
  getOpportunityByIdApi = 'getOpportunityById';
  getZoningLimitsApi = 'getZoningLimits';
  calculateROIApi = 'calculateROI';
  fetchComparablesApi = 'fetchComparables';
  saveOpportunityApi = 'saveOpportunity';
  downloadReportApi = 'downloadRoiAnalysisReport';
  addOpportunityApi = 'addOpportunity';

  constructor(private http: HttpClient) { }

  getNeighborhood(): Observable<any> {
    return new Observable((observer) => {
      this.http
        .get(`${this.prospektrBackendBaseURL}${this.getNeighborhoodApi}`)
        .pipe(
          finalize(() => {
            observer.complete();
          })
        )
        .subscribe(
          (res) => {
            observer.next(res);
          },
          (err) => {
            observer.next({});
          }
        );
    });
  }

  getOpportunities(params): Observable<any> {
    return new Observable((observer) => {
      this.http
        .post(`${this.prospektrBackendBaseURL}${this.opportunitiesApi}`, params)
        .pipe(
          finalize(() => {
            observer.complete();
          })
        )
        .subscribe(
          (res) => {
            observer.next(res);
          },
          (err) => {
            observer.next({});
          }
        );
    });
  }

  getZoningCodes(params): Observable<any> {
    return new Observable((observer) => {
      this.http
        .post(`${this.prospektrBackendBaseURL}${this.getZoningCodesApi}`, params)
        .pipe(
          finalize(() => {
            observer.complete();
          })
        )
        .subscribe(
          (res) => {
            observer.next(res);
          },
          (err) => {
            observer.next({});
          }
        );
    });
  }

  getZoningLimits(params): Observable<any> {
    return new Observable((observer) => {
      this.http
        .post(`${this.prospektrBackendBaseURL}${this.getZoningLimitsApi}`, params)
        .pipe(
          finalize(() => {
            observer.complete();
          })
        )
        .subscribe(
          (res) => {
            observer.next(res);
          },
          (err) => {
            observer.next({});
          }
        );
    });
  }

  calculateROI(params): Observable<any> {
    return new Observable((observer) => {
      this.http
        .post(`${this.prospektrBackendBaseURL}${this.calculateROIApi}`, params)
        .pipe(
          finalize(() => {
            observer.complete();
          })
        )
        .subscribe(
          (res) => {
            observer.next(res);
          },
          (err) => {
            observer.next({});
          }
        );
    });
  }

  fetchComparables(params): Observable<any> {
    return new Observable((observer) => {
      this.http
        .post(`${this.prospektrBackendBaseURL}${this.fetchComparablesApi}`, params)
        .pipe(
          finalize(() => {
            observer.complete();
          })
        )
        .subscribe(
          (res) => {
            observer.next(res);
          },
          (err) => {
            observer.next({});
          }
        );
    });
  }

  getOpportunityById(id): Observable<any> {
    return new Observable((observer) => {
      this.http
        .get(`${this.prospektrBackendBaseURL}${this.getOpportunityByIdApi}/${id}`)
        .pipe(
          finalize(() => {
            observer.complete();
          })
        )
        .subscribe(
          (res) => {
            observer.next(res);
          },
          (err) => {
            observer.next({});
          }
        );
    });
  }

  saveOpportunity(params): Observable<any> {
    return new Observable((observer) => {
      this.http
        .post(`${this.prospektrBackendBaseURL}${this.saveOpportunityApi}`, params)
        .pipe(
          finalize(() => {
            observer.complete();
          })
        )
        .subscribe(
          (res) => {
            observer.next(res);
          },
          (err) => {
            observer.next({});
          }
        );
    });
  }

  downloadRoiAnalysisReport(params): Observable<any> {
    const httpOptions = {
      responseType: 'blob' as 'json',
    };
    return new Observable((observer) => {
      this.http
        .post(`${this.prospektrBackendBaseURL}${this.downloadReportApi}`, params, httpOptions)
        .pipe(
          finalize(() => {
            observer.complete();
          })
        )
        .subscribe(
          (res) => {
            observer.next(res);
          },
          (err) => {
            observer.next({});
          }
        );
    });
  }

  addOpportunity(params): Observable<any> {
    return new Observable((observer) => {
      this.http
        .post(`${this.prospektrBackendBaseURL}${this.addOpportunityApi}`, params)
        .pipe(
          finalize(() => {
            observer.complete();
          })
        )
        .subscribe(
          (res) => {
            observer.next(res);
          },
          (err) => {
            observer.next({});
          }
        );
    });
  }
}
