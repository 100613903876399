import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { ReportsService } from 'src/app/reports/reports.service';
import { ConfirmDialogComponent } from 'src/app/shared/modules/alerts/confirm-dialog/confirm-dialog.component';
import { ProspektrApi } from '../../api/prospektr.api';
import { UserState } from '../../state/user.state';
import { SampleReportComponent } from '../sample-report/sample-report.component';

@Component({
  selector: 'app-plan-information',
  templateUrl: './plan-information.component.html',
  styleUrls: ['./plan-information.component.scss'],
})
export class PlanInformationComponent implements OnInit {
  onDestroyNotifier$ = new Subject();
  plans = [];
  code = new FormControl();
  currentCode: any;
  totalPlans: any;
  connectedLoanOfficers: any;
  loanOfficer = new FormControl();
  selectedPlan: any = [];
  loading = true;
  spin = false;
  activePlanId: any;
  currentPlan: any = [];
  currentIndex: any;
  subscriptionEndDate: string;
  connectedLoanOfficerId: any;
  activePromoCode: any;
  existingSubscriptionEndDate: any;
  isConnected: boolean;
  activePlan: any;
  nextPlan: any;
  nextPlanId: any;
  nextLoanOfficer: any;
  nextPlanStartDate: string;
  isIntrial: boolean;
  loanOfficerChange: boolean;
  promoCodeChange: boolean;
  initialLO: any;

  @Input() userRole;
  activePlanName: any;

  constructor(
    private reportsService: ReportsService,
    private userState: UserState,
    private prospektrApi: ProspektrApi,
    private snackBar: MatSnackBar,
    public dialog: MatDialog
  ) { }

  async ngOnInit(): Promise<void> {
    this.loading = true;
    this.updatePlans();
  }

  updatePlans() {
    this.userState.userPlanStatus.pipe(
      takeUntil(this.onDestroyNotifier$),
      tap((data) => {
        if (data && !data.trialExpired && data.daysRemaining) {
          this.isIntrial = true;
        } else {
          this.isIntrial = false;
        }
        this.activePlanId = data?.planId;
        this.activePlanName = data?.name;
        this.connectedLoanOfficerId = data?.loanOfficerId ?? '';
        this.initialLO = this.connectedLoanOfficerId;
        this.activePromoCode = data?.discounts;
        this.existingSubscriptionEndDate = data?.existingSubscriptionEndDate;
        this.nextPlan = data?.transitionToPlanDetails || data?.transitionToSubscriptionDetails;
      }),
      switchMap(() => {
        return this.reportsService.getPlans('').pipe(
          tap(() => console.log('Fetching plans'))
        );
      }),
      switchMap(() => {
        return this.userState.plans.pipe(
          takeUntil(this.onDestroyNotifier$),
          tap((plansData) => {
            this.plans = plansData.sort((a, b) => b.priority - a.priority);
          })
        );
      }),
      tap(() => {
        // Check if we have an activePlanId and select the correct plan
        let i = this.plans.findIndex((x) => x.id == this.activePlanId);
        if (this.activePlanId && i != -1) {
          this.selectedPlan = this.plans[i];
          this.activePlan = this.plans[i];
        } else {
          // If no valid activePlanId, use the 'unlimited' plan as default
          let defaultPlanIndex = this.plans.findIndex((x) => x.planType == 'unlimited');
          this.selectedPlan = this.plans[defaultPlanIndex];
          this.activePlanId = this.plans[defaultPlanIndex]?.id;
          this.subscribePlan("resetToUnlimitedPlan");
        }

        // Apply discount if promo code exists
        if (this.activePromoCode) {
          let planIndex = this.plans.findIndex((x) => x.id == this.activePlanId);
          if (planIndex != -1) {
            this.plans[planIndex]['discountPrice'] = this.activePromoCode.discountedPrice;
            this.plans[planIndex]['offerApplied'] = true;
            this.plans[planIndex]['promoCode'] = this.activePromoCode.promoCode;
          }
        }

        // Handle next plan transition if it exists
        if (this.nextPlan) {
          this.nextPlanId = this.nextPlan?.planId || this.nextPlan._id;
          this.nextLoanOfficer = this.nextPlan?.loanOfficerId || '';
          let date = new Date(this.existingSubscriptionEndDate);
          let month = date.getMonth() + 1;
          this.nextPlanStartDate = `${date.getDate()}/${month}/${date.getFullYear()}`;
        }
      })
    ).subscribe({
      next: (finalData) => {
        this.loading = false;
      },
      error: (err) => {
        console.error('Error during API operations:', err);
      }
    });
  }

  viewSampleReport() {
    const matDialogRef = this.dialog.open(SampleReportComponent, {
      width: '70%',
      height: '100%',
      disableClose: false,
      hasBackdrop: true,
      data: {
        proceedAction: () => {
          matDialogRef.close();
        },
      },
    });
  }

  applyCode(id, index) {
    if (index != -1) {
      this.currentIndex = index;
      this.spin = true;
    }
    let obj = {
      planId: id,
      promoCode: this.code.value,
    };
    let i = this.plans.findIndex((x) => x.id == id);
    this.prospektrApi
      .applyCode(obj)
      .pipe(takeUntil(this.onDestroyNotifier$))
      .subscribe(
        (res: any) => {
          this.spin = false;
          this.openSnackBar(res.message, 'snackbar-success');
          this.plans[i]['discountPrice'] = res.data.discountedPrice;
          this.plans[i]['offerApplied'] = true;
          this.plans[i]['promoCode'] = this.code.value;
          this.currentCode = this.plans[i]['promoCode'];
        },
        (err: any) => {
          this.spin = false;
          this.plans[i]['offerApplied'] = false;
          this.plans[i]['discountPrice'] = '';
          this.code.reset();
          this.openSnackBar(err.error.message, 'snackbar-warning');
        }
      );
  }

  removeCode(id) {
    let i = this.plans.findIndex((x) => x.id == id);
    this.plans[i]['offerApplied'] = false;
    this.plans[i]['discountPrice'] = '';
    this.plans[i]['promoCode'] = '';
    this.code.reset();
  }

  ngOnDestroy() {
    this.onDestroyNotifier$.next();
    this.onDestroyNotifier$.complete();
  }

  save() {
    if(this.activePlan.priority > this.selectedPlan.priority && this.activePlan.planType == 'subscription' && this.selectedPlan.planType == 'subscription'){
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        data: {
          message: `Your current ${this.activePlan.product} subscription will be terminated immediately. You will be charged for the  ${this.selectedPlan.product}`,
          title: 'Are you sure you want to proceed?',
          proceedAction: (status, callBack) => {
            this.subscribePlan();
            dialogRef.close();
          },
          proceedCancelAction(cancelCallBack) {
          },
        },
        disableClose: true,
        hasBackdrop: true,
      });
    } else {
      this.subscribePlan();
    }
    // if (this.isIntrial == true && this.selectedPlan.interval == 'month') {
    //   const dialogRef = this.dialog.open(WarningMsgComponent, {
    //     width: '250px',
    //     disableClose: true,
    //   });
    //   dialogRef.afterClosed().subscribe((result) => {
    //     if (result == 'cancel') {
    //     } else {
    //       this.subscribePlan();
    //     }
    //   });
    // } else {
    //   this.subscribePlan();
    // }
  }

  subscribePlan(flag = null) {
    this.loading = true;
    // this.initialLO == this.loanOfficer.value ? (this.loanOfficerChange = false) : (this.loanOfficerChange = true);
    this.activePromoCode?.promoCode == this.selectedPlan?.promoCode
      ? (this.promoCodeChange = false)
      : (this.promoCodeChange = true);
    let obj = {
      planId: this.selectedPlan.id,
      promoCode: this.selectedPlan?.promoCode,
      loanOfficerId: this.loanOfficer.value,
      loanOfficerChange: this.loanOfficerChange,
      promoCodeChange: this.promoCodeChange,
    };
    this.prospektrApi
      .subscribePlan(obj)
      .pipe(takeUntil(this.onDestroyNotifier$))
      .subscribe(
        (res: any) => {
          this.currentPlan = this.selectedPlan;
          this.code.reset();
          if (res.data.existingSubscriptionEndDate) {
            let date = new Date(res.data.existingSubscriptionEndDate);
            let month = date.getMonth() + 1;
            this.subscriptionEndDate = date.getDate() + '/' + month + '/' + date.getFullYear();
            this.openSnackBar(
              res.message + '.' + 'This Plan will be effective only after ' + this.subscriptionEndDate,
              'snackbar-success'
            );
          } else {
            if (!flag) {
              this.openSnackBar(res.message, 'snackbar-success');
            }
          }
          this.reportsService.getPlanStatus();
          setTimeout(() => {
            this.updatePlans();
          }, 3000);
        },
        (err: any) => {
          this.loading = false;
          this.code.reset();
          this.openSnackBar(err.error.message, 'snackbar-error');
        }
      );
  }

  choosePlan(id) {
    this.activePlanId = id;
    this.connectedLoanOfficerId = this.loanOfficer.value;
    let i = this.plans.findIndex((x) => x.id == id);
    this.selectedPlan = this.plans[i];
  }

  private openSnackBar(message, style) {
    if (message) {
      this.snackBar.open(message, '', {
        duration: 3000,
        panelClass: [style ? style : ''],
        verticalPosition: 'top',
      });
    }
  }
}
