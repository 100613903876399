import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Observable, of } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { PublicRecordApi } from 'src/app/_shared/api/public-record.api';
import { ReportsApi } from 'src/app/_shared/api/reports.api';
import { environment } from 'src/environments/environment';
import { ProspektrApi } from '../_shared/api/prospektr.api';
import { StripeApi } from '../_shared/api/stripe.api';
import { UserState } from '../_shared/state/user.state';
import { CmaState } from '../cma/cma.state';
import { ReportsState } from './reports.state';

@Injectable({
  providedIn: 'root',
})
export class ReportsService {
  upgradeSnackbar: MatSnackBarRef<any>;
  listingData: any;

  constructor(
    private userState: UserState,
    private reportsState: ReportsState,
    private ReportsApi: ReportsApi,
    private snackbar: MatSnackBar,
    private publicRecordApi: PublicRecordApi,
    private sanitizer: DomSanitizer,
    private stripeApi: StripeApi,
    private prospektrApi: ProspektrApi,
    private cmaState: CmaState,
  ) { }

  getPlanStatus() {
    this.ReportsApi.getPlanStatus().subscribe(
      (res: any) => {
        if (res && res.statusCode == 200 && res.status == 'OK' && res.data) {
          this.userState.getUserPlanStatusValue = res.data;
        }
      },
      (err: any) => {
        console.log('getPlanStatus err : ', err);
      }
    );
  }


  getPlans(id) {
    let obj = {
      loanOfficerId: id,
    };
    return this.prospektrApi.getPlans(obj).pipe(
      tap((res: any) => {
        if (res && res.statusCode === 200 && res.status === 'OK' && res.data) {
          this.userState.plans.next(res.data);  // Update the plans state
          this.userState.totalPlans.next(res.data.length);  // Update total plans count
        }
      }),
      catchError((error) => {
        // Handle errors here (if any)
        console.error('getPlans error:', error);
        return of(null);
      })
    );
  }

  getConnectedUsers() {
    this.prospektrApi.getConnectedUsers().subscribe(
      (res: any) => {
        this.userState.connectedUsers.next(res.userData);
      },
      (err: any) => {
        console.log('getConnectedUsers err : ', err);
      }
    );
  }

  getConnectedAgents() {
    this.prospektrApi.getConnectedAgents().subscribe(
      (res: any) => {
        this.userState.connectedAgents.next(res.userData);
      },
      (err: any) => {
        console.log('getConnectedAgents err : ', err);
      }
    );
  }

  getSavedCards() {
    this.stripeApi.getSavedCards().subscribe(
      (response) => {
        if (
          response &&
          response.status == 'OK' &&
          response.statusCode == 200 &&
          response.data &&
          response.data.length > 0
        ) {
          this.reportsState.isPaymentCardAdded = true;
        } else {
          if (this.reportsState.isPaymentCardAdded) {
            this.reportsState.isPaymentCardAdded = false;
          }
        }
      },
      (error) => {
        if (this.reportsState.isPaymentCardAdded) {
          this.reportsState.isPaymentCardAdded = false;
        }
        console.error('Error fetching saved cards:', error);
      }
    );
  }

  downloadReportAsHtml(params) {
    this.reportsState.isLoadingReport = true;

    this.ReportsApi.downloadReportAsHtml(params).subscribe(
      (res: any) => {
        if (res && res.status == 'success' && res.data) {
          this.reportsState.createReportResponseValue = res;
        } else {
          this.reportsState.createReportResponseValue = res;
        }
        this.reportsState.isLoadingReport = false;
      },
      (err: any) => {
        console.log('downloadReportAsHtml err : ', err);
        this.reportsState.createReportResponseValue = err;
        this.reportsState.isLoadingReport = false;
      }
    );
  }

  downloadReportAsPdf(params) {
    this.reportsState.isDownloadingReport = true;

    this.ReportsApi.downloadReport(params).subscribe(
      (res: any) => {
        let url: SafeResourceUrl;
        const file = new Blob([res], { type: 'application/pdf' });
        let pdfUrl = URL.createObjectURL(file);
        url = this.sanitizer.bypassSecurityTrustResourceUrl(pdfUrl);
        this.reportsState.valueReportPdfUrl = url;
      },
      (err: any) => {
        console.log('downloadReport err : ', err);
        this.reportsState.isDownloadingReport = false;
      }
    );
  }

  downloadReport(params, name) {
    this.reportsState.isLoadingReport = true;

    this.ReportsApi.downloadReport(params).subscribe(
      (res: any) => {
        var downloadURL = URL.createObjectURL(res);
        var link = document.createElement('a');
        link.href = downloadURL;
        link.download = name + '.pdf';
        link.click();
        this.reportsState.isLoadingReport = false;
      },
      (err: any) => {
        console.log('downloadReport err : ', err);
        this.reportsState.isLoadingReport = false;
      }
    );
  }

  createReport(params) {
    this.reportsState.isLoadingReport = true;

    this.ReportsApi.createReport(params).subscribe(
      (res: any) => {
        this.reportsState.createReportResponseValue = res;
      },
      (err: any) => {
        this.reportsState.createReportResponseValue = err;
      }
    );
  }

  updateReport(params) {
    this.reportsState.isDownloadingReport = true;

    this.ReportsApi.updateReport(params).subscribe(
      (res: any) => {
        this.reportsState.createReportResponseValue = res;
      },
      (err: any) => {
        this.reportsState.createReportResponseValue = err;
      }
    );
  }

  refreshReport(params) {
    this.reportsState.isDownloadingReport = true;

    this.ReportsApi.refreshReport(params).subscribe(
      (res: any) => {
        this.reportsState.createReportResponseValue = res;
      },
      (err: any) => {
        this.reportsState.createReportResponseValue = err;
      }
    );
  }

  // applyCode(params) {
  //   this.prospektrApi.applyCode(params).subscribe(
  //     (res: any) => {
  //       this.userState.couponValue.next(res.data);
  //       if (res && res.status && res.status === 'OK' && res.data) {
  //         this.userState.couponValue.next(res.data);
  //       } else {
  //         this.userState.couponValue.next(null);
  //       }
  //     },
  //     (err: any) => {
  //       console.log('Error: ', err);
  //     }
  //   );
  // }

  getAllReports(params) {
    this.reportsState.isLoadingReport = true;

    this.ReportsApi.getAllReports(params).subscribe(
      (res: any) => {
        if (res && res.status && res.status === 'OK' && res.data) {
          this.reportsState.reportsListValue = res.data && res.data.paginatedResults ? res.data.paginatedResults : [];
          this.reportsState.reportsCountValue = res.data && res.data.totalRecords ? res.data.totalRecords : 0;
        } else {
          this.reportsState.reportsListValue = [];
          this.reportsState.reportsCountValue = 0;
        }
        this.reportsState.isLoadingReport = false;
      },
      (err: any) => {
        console.log('getAllReports err : ', err);
        this.reportsState.isLoadingReport = false;
      }
    );
  }

  getStatisticsData(params) {
    return new Observable((observer) => {
      observer.next({ isLoading: true });
      this.ReportsApi.getStatisticsData(params)
        .pipe(
          finalize(() => {
            observer.next({ isLoading: false });
            observer.complete();
          })
        )
        .subscribe(
          (res: any) => {
            if (res && res.data) {
              this.reportsState.statisticsDataListValue = res.data.data;
              this.reportsState.statiticsTotalCountValue = res.data.totalRecord;
            } else {
              this.reportsState.statisticsDataListValue = [];
              this.reportsState.statiticsTotalCountValue = 0;
            }
          },
          (err) => {
            this.reportsState.statisticsDataListValue = [];
            this.reportsState.statiticsTotalCountValue = 0;
          }
        );
    });
  }

  getReportById(params) {
    this.reportsState.isLoadingReport = true;

    this.ReportsApi.getReportById(params).subscribe(
      (res: any) => {
        if (res && res.status && res.status === 'OK' && res.data) {
          this.reportsState.reportsValue = res.data ? res.data : null;
        } else {
          this.reportsState.reportsValue = null;
        }
      },
      (err: any) => {
        console.log('getReportById err : ', err);
        this.reportsState.isLoadingReport = false;
      }
    );
  }

  getSharedReport(params) {
    this.reportsState.isLoadingReport = true;

    this.ReportsApi.getSharedReport(params).subscribe(
      (res: any) => {
        if (res && res.status && res.status === 'OK' && res.data) {
          this.reportsState.reportsValue = res.data ? res.data : null;
        } else {
          this.reportsState.reportsValue = null;
        }
      },
      (err: any) => {
        console.log('getReportById err : ', err);
        this.reportsState.isLoadingReport = false;
      }
    );
  }

  getListingDetails(params, flag = null) {
    if (flag == 'cma') this.cmaState.isLoadingCreateReport = true;
    else this.reportsState.isLoadingCreateReport = true;

    this.listingData = null;
    this.ReportsApi.getListingDetails(params).subscribe(
      (res: any) => {
        if (res && res.Status && res.Status.Message === 'Success' && res.ListingDetails) {
          if (res.ImageURLs && res.ImageURLs.length > 0) {
            res.ListingDetails[0].imgUrl = res.ImageURLs[0];
          }
          this.listingData = res.ListingDetails[0];
        }
        this.getProperty(params, flag);
      },
      (err: any) => {
        this.getProperty(params, flag);
        console.log('getListingDetails : err : ', err);
      }
    );
  }

  searchPropertyDetails(params, flag = null) {
    if (flag == 'cma') this.cmaState.isLoadingCreateReport = true;
    else this.reportsState.isLoadingCreateReport = true;
    this.ReportsApi.searchPropertyDetails(params).subscribe(
      (res: any) => {
        if (res && res.Status && res.Status.Message === 'Success' && res.Data) {
          if (flag == 'cma') {
            this.cmaState.listPropertySetValue = res.Data.Properties;
            this.cmaState.listPropertySetCount = res.Data.Count;
          } else {
            this.reportsState.listPropertySetValue = res.Data.Properties;
            this.reportsState.listPropertySetCount = res.Data.Count;
          }

        } else {
          this.openSnackbar('Property not found', false);
        }
        if (flag == 'cma') this.cmaState.isLoadingCreateReport = false;
        else this.reportsState.isLoadingCreateReport = false;
      },
      (err: any) => {
        console.log('searchPropertyDetails : err : ', err);
        this.openSnackbar('Property not found', false);
        if (flag == 'cma') this.cmaState.isLoadingCreateReport = false;
        else this.reportsState.isLoadingCreateReport = false;
      }
    );
  }

  getProperty(addressObj, flag = null) {
    let propertyTypes = ['basic', 'listing'];
    this.publicRecordApi.getPropertyDetails({ address: addressObj, type: propertyTypes }).subscribe(
      (res) => {
        if (res && res.status && res.statusCode === 200 && res.data) {
          this.formatPropertyData(res.data, flag);
        } else {
          this.openSnackbar('Property not found', false);
        }
      },
      (err: any) => {
        console.log('getPropertyDetails : err : ', err);
        this.openSnackbar('Property not found', false);
        if (flag == 'cma') this.cmaState.isLoadingCreateReport = false;
        else this.reportsState.isLoadingCreateReport = false;
      }
    );
  }

  deleteReport(reportId) {
    this.reportsState.isLoadingReport = true;

    this.ReportsApi.deleteReport({ reportId: reportId }).subscribe(
      (res: any) => {
        this.getAllReports({});
        this.openSnackbar(res.message, true);
      },
      (err: any) => {
        this.reportsState.isLoadingReport = false;
        this.openSnackbar(err.message, false);
        console.log('reportId err : ', err);
      }
    );
  }

  formatPropertyData(data, flag) {
    let propertyData;

    if (data.listing) {
      propertyData = data.listing;
    } else if (data.basic) {
      propertyData = data.basic;
    } else {
      this.openSnackbar('Property not found', false);
      return;
    }

    if (data.imageList) {
      propertyData['imgUrl'] = data.imageList[0];
    }

    let valueTarget = null;
    if (data.basic) {
      let basicData = data.basic;
      if (basicData?.Lst_StandardStatus == 'Active') {
        valueTarget = basicData.Lst_ListPrice;
      } else if (basicData?.Lst_StandardStatus == 'Sold' && basicData?.Lst_CloseDate) {
        if (this.calculateDateDifference(basicData.Lst_CloseDate) < 183) {
          valueTarget = basicData.Lst_ClosePrice;
        }
      }
    }

    let propertyDetails = {
      Address:
        this.listingData && this.listingData.Address
          ? this.listingData.Address
          : propertyData && propertyData.Address
            ? propertyData.Address
            : propertyData['StreetAddress'] +
            ', ' +
            propertyData['City'] +
            ', ' +
            propertyData['StateOrProvince'] +
            ' ' +
            propertyData['PostalCode'],

      // imgUrl:
      //   this.listingData && this.listingData.imgUrl
      //     ? this.listingData.imgUrl
      //     : propertyData && propertyData.imgUrl
      //       ? propertyData.imgUrl
      //       : null,

      numberOfImages: data?.imageList ? data.imageList.length : 0,
      imgUrl:
        this.listingData
          ? environment.listingImageBaseUrlAlt + '?ListingId=' + (this.listingData.ListingId || 'null') + '&imageName=1' : null,

      ListingId:
        this.listingData && this.listingData.ListingId
          ? this.listingData.ListingId
          : propertyData && propertyData.Lst_ListingId
            ? propertyData.Lst_ListingId
            : null,
      PMXPropertyId:
        this.listingData && this.listingData.PMXPropertyId
          ? this.listingData.PMXPropertyId
          : propertyData && propertyData.PMXPropertyId
            ? propertyData.PMXPropertyId
            : null,

      PropertySubType:
        this.listingData && this.listingData.PropertySubType
          ? this.listingData.PropertySubType
          : propertyData && propertyData.PropertySubType
            ? propertyData.PropertySubType
            : null,
      LivingArea:
        this.listingData && this.listingData.LivingArea != ''
          ? this.listingData.LivingArea
          : propertyData && propertyData.LivingArea
            ? propertyData.LivingArea
            : null,
      YearBuilt:
        this.listingData && this.listingData.YearBuilt != ''
          ? this.listingData.YearBuilt
          : propertyData && propertyData.YearBuilt
            ? propertyData.YearBuilt
            : null,
      BedroomsTotal:
        this.listingData && this.listingData.BedroomsTotal != ''
          ? this.listingData.BedroomsTotal
          : propertyData && propertyData.BedroomsTotal
            ? propertyData.BedroomsTotal
            : null,
      BathroomsTotalInteger:
        this.listingData && this.listingData.BathroomsTotalInteger != ''
          ? this.listingData.BathroomsTotalInteger
          : propertyData && propertyData.BathroomsTotalInteger
            ? propertyData.BathroomsTotalInteger
            : null,
      LotSizeSquareFeet:
        this.listingData && this.listingData.LotSizeSquareFeet != ''
          ? this.listingData.LotSizeSquareFeet
          : propertyData && propertyData.LotSizeSquareFeet
            ? propertyData.LotSizeSquareFeet
            : null,

      Parking: this.listingData && this.listingData.ParkingTotal != ''
        ? this.listingData.ParkingTotal
        : propertyData && propertyData.ParkingSpaces != ''
          ? propertyData.ParkingSpaces
          : null,

      Stories: this.listingData && this.listingData.StoriesTotal != ''
        ? this.listingData.StoriesTotal
        : propertyData && propertyData.StoriesTotal != ''
          ? propertyData.StoriesTotal
          : null,

      MonthsBack: null,

      StreetAddress:
        this.listingData && this.listingData.UnparsedAddress != ''
          ? this.listingData.UnparsedAddress
          : propertyData?.StreetAddress
            ? propertyData.StreetAddress
            : propertyData?.UnparsedAddress
              ? propertyData.UnparsedAddress
              : null,
      City:
        this.listingData && this.listingData.City
          ? this.listingData.City
          : propertyData && propertyData.City
            ? propertyData.City
            : null,
      StateOrProvince:
        this.listingData && this.listingData.StateOrProvince
          ? this.listingData.StateOrProvince
          : propertyData && propertyData.StateOrProvince
            ? propertyData.StateOrProvince
            : null,
      PostalCode:
        this.listingData && this.listingData.PostalCode
          ? this.listingData.PostalCode
          : propertyData && propertyData.PostalCode
            ? propertyData.PostalCode
            : null,

      valueTarget: valueTarget,
      MlsBoard:
        this.listingData && this.listingData.MlsBoard
          ? this.listingData.MlsBoard
          : propertyData && propertyData.Lst_MlsBoard
            ? propertyData.Lst_MlsBoard
            : null,
    };

    if (flag == 'cma') {
      this.cmaState.listPropertySetValue = [propertyDetails];
      this.cmaState.listPropertySetCount = 1;
      this.cmaState.isLoadingCreateReport = false;
    } else {
      this.reportsState.listPropertySetValue = [propertyDetails];
      this.reportsState.listPropertySetCount = 1;
      this.reportsState.isLoadingCreateReport = false;
    }
  }

  openSnackbar(upgradeMsg, success = false) {
    this.upgradeSnackbar = this.snackbar.open(upgradeMsg, 'Dismiss', {
      duration: 5000,
      panelClass: [success ? 'snackbar-success' : 'snackbar-error'],
      verticalPosition: 'top',
    });
  }

  calculateDateDifference(date1: string): number {
    const parsedDate1 = new Date(date1);
    const parsedDate2 = new Date();

    const differenceInMs = Math.abs(parsedDate1.getTime() - parsedDate2.getTime());
    const differenceInDays = differenceInMs / (1000 * 3600 * 24);

    return differenceInDays;
  }


  updateValueReport(params): Observable<{ isLoading?: boolean; data?: any }> {
    return new Observable((observer) => {
      observer.next({ isLoading: true });
      this.ReportsApi.updateValueReport(params)
        .pipe(
          finalize(() => {
            observer.next({ isLoading: false });
            observer.complete();
          })
        )
        .subscribe(
          (res: any) => {
            observer.next({ data: res });
          },
          (err) => {
            observer.next({ data: null });
          }
        );
    });
  }

  shareReport(params): Observable<{ isLoading?: boolean; data?: any }> {
    return new Observable((observer) => {
      observer.next({ isLoading: true });
      this.ReportsApi.shareReport(params)
        .pipe(
          finalize(() => {
            observer.next({ isLoading: false });
            observer.complete();
          })
        )
        .subscribe(
          (res: any) => {
            observer.next({ data: res });
          },
          (err) => {
            observer.next({ data: null });
          }
        );
    });
  }

  shareReportToLoanOfficer(params): Observable<{ isLoading?: boolean; data?: any }> {
    return new Observable((observer) => {
      observer.next({ isLoading: true });
      this.ReportsApi.shareReportToLoanOfficer(params)
        .pipe(
          finalize(() => {
            observer.next({ isLoading: false });
            observer.complete();
          })
        )
        .subscribe(
          (res: any) => {
            observer.next({ data: res });
          },
          (err) => {
            observer.next({ data: null });
          }
        );
    });
  }
}
