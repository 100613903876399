import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { MapMarker } from 'src/app/_shared/interface/marker.interface';

@Injectable({
  providedIn: 'root',
})
export class MarkerState {
  private mapMarkerListSub$ = new BehaviorSubject<MapMarker>(null);
  mapMarkerList$ = this.mapMarkerListSub$.asObservable();
  get mapMarkerListValue(): MapMarker {
    return this.mapMarkerListSub$.value;
  }
  set mapMarkerListValue(markerSet: MapMarker) {
    if (this.mapMarkerListSub$.value && Object.values(this.mapMarkerListSub$.value).length)
      Object.values(this.mapMarkerListSub$.value).forEach((marker) => {
        marker.setMap(null);
      });
    this.mapMarkerListSub$.next(markerSet);
  }

  private renderMarkerSub$ = new BehaviorSubject<boolean>(false);
  renderMarker$ = this.renderMarkerSub$.asObservable();
  get renderMarkerValue(): boolean {
    return this.renderMarkerSub$.value;
  }
  set renderMarkerValue(value: boolean) {
    this.renderMarkerSub$.next(value);
  }

  private googleMarkerListSub = new BehaviorSubject<Array<any>>([]);
  googleMarkerList$ = this.googleMarkerListSub.asObservable();
  get googleMarkerListValue() {
    return this.googleMarkerListSub.value;
  }
  set googleMarkerListValue(markerList) {
    this.googleMarkerListSub.next(markerList);
  }
}
