import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, EMPTY, forkJoin, Observable } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, finalize, map, switchMap, take } from 'rxjs/operators';
import { AppState } from 'src/app/app.state';
import { PropertyFilterState } from 'src/app/property-filter/state/property-filter.state';
import { WorkspaceConfig } from 'src/app/_shared/config/workspace.config';
import { Json } from 'src/app/_shared/interface/common.interface';
import {
  ClusteredProperty,
  MapCluster,
  MapProperty,
  PartialProperty,
} from 'src/app/_shared/interface/property.interface';
import { FilterService } from 'src/app/_shared/service/filter.service';
import { FavoriteState } from 'src/app/_shared/state/favorite.state';
import { WorkspaceState } from '../../_shared/state/workspace.state';
import { PublicRecordApi } from '../api/public-record.api';
import { ListState } from '../state/list.state';
import { MapState } from '../state/map.state';
import { MarkerState } from '../state/marker.state';
import { PropertyState } from '../state/property.state';
import { SearchState } from '../state/search.state';

@Injectable({
  providedIn: 'root',
})
export class PublicRecordService {
  propertiesInBoundingBoxSub$ = new BehaviorSubject<any>(null);
  constructor(
    private favoriteState: FavoriteState,
    private filterService: FilterService,
    private listState: ListState,
    private mapState: MapState,
    private markerState: MarkerState,
    private propertyState: PropertyState,
    private publicRecordApi: PublicRecordApi,
    private searchState: SearchState,
    private workspaceConfig: WorkspaceConfig,
    private workspaceState: WorkspaceState,
    private propertyFilterState: PropertyFilterState,
    private appState: AppState
  ) {
    // this.initApiTrigger();
  }

  /**
   * Subscribe to all states whose change should trigger the Property Search APIs
   * The following changes will trigger the APIs as per the configuration :
   *    - Change in Search Address
   *    - Change in Map Zoom level
   *    - Change in Workspace View from Map to List or vice versa
   *    - Change in Applied Filters
   *    - Page change in Cards View or List View
   */
  initApiTrigger() {
    combineLatest([
      this.searchState.searchAddress$,
      this.mapState.mapZoom$,
      this.mapState.mapBounds$,
      // this.workspaceState.workspaceView$,
      this.workspaceState.activeWorkspaceMode$,
      // this.filterState.appliedFilterSet$,
      this.propertyFilterState.appliedFilter$,
      this.propertyFilterState.propertySortFields$,
    ])
      .pipe(
        debounceTime(500),
        distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)),
        switchMap((combinedResult) => {
          if (
            combinedResult[0].fullAddress &&
            combinedResult[1] &&
            combinedResult[2] &&
            Object.keys(combinedResult[2]).length
          ) {
            this.triggerSearchAPIs(
              combinedResult[0],
              combinedResult[1],
              combinedResult[2],
              combinedResult[3],
              combinedResult[4]
              // combinedResult[5]
            );
            this.listState.setPaginationValue({
              pageSize: 20,
              pageNumber: 1,
              sortFields: this.propertyFilterState.propertySortFields.sortField
                ? this.propertyFilterState.propertySortFields.sortField
                : this.appState && this.appState.companyInfoValue.alias == 'opscan'
                ? 'Lst_ListingContractDate'
                : 'Lst_ListPrice',
              sortOrder: this.propertyFilterState.propertySortFields.sortOrder || 'DESC',
            });
            return this.listState.pagination$.pipe(
              debounceTime(100),
              map((paginationRes) => {
                return { combinedResult, paginationRes };
              })
            );
          } else return EMPTY;
        })
      )
      .subscribe((res) => {
        if (res) {
          this.triggerPaginationAPIs(
            res.combinedResult[0],
            res.combinedResult[1],
            res.combinedResult[2],
            res.combinedResult[3],
            res.combinedResult[4],
            // res.combinedResult[5],
            res.paginationRes
          );
        }
      });
  }

  triggerSearchAPIs(search, zoom, boundingBox, workspaceMode, filter) {
    const activeConfigList = this.workspaceConfig.publicRecordApiConfig.filter((config) => {
      return (
        // this.isValidView(config.view, view) &&
        this.isValidMode(config.defaultMode, workspaceMode.isDefault) &&
        this.isValidZoom(config.minZoom, config.maxZoom, zoom) &&
        this.isValidCategory(config.category, filter) &&
        config.updatedState.includes('map')
      );
    });

    activeConfigList.forEach((config) => {
      // config.apis.forEach((api) => {
      switch (config.api) {
        case 'clustersInBoundingBox':
          this.getClustersInBoundingBox(boundingBox, filter, zoom);
          break;

        case 'listingInBoundingBox':
          this.getListingsInBoundingBox(boundingBox, filter, {}, config.updatedState);
          break;

        case 'propertiesInBoundingBox':
          this.getPropertiesInBoundingBox(boundingBox, filter, {}, config.updatedState);
          break;

        case 'propertiesByGeo':
          this.getPropertiesByGeo(search, filter, {}, config.updatedState);
          break;

        case 'allPropertiesInBoundingBox':
          this.getAllPropertiesInBoundingBox(boundingBox, filter);
          break;
      }
      // });
    });

    this.markerState.renderMarkerValue = true;
  }

  triggerPaginationAPIs(search, zoom, boundingBox, workspaceMode, filter, listPagination) {
    const activeConfigList = this.workspaceConfig.publicRecordApiConfig.filter((config) => {
      // const isValidView = this.isValidView(config.view, view);
      const isValidMode = this.isValidMode(config.defaultMode, workspaceMode.isDefault);
      const isValidZoom = this.isValidZoom(config.minZoom, config.maxZoom, zoom);
      const isValidCategory = this.isValidCategory(config.category, filter);
      const isSingleState = config.updatedState.length == 1;
      const includesListState = config.updatedState.includes('list');
      return isValidMode && isValidZoom && isValidCategory && isSingleState && includesListState;
    });

    activeConfigList.forEach((config) => {
      // if (config.paginatedResult) {
      //   filter.PageSize = listPagination.pageSize;
      //   filter.PageNumber = listPagination.pageNumber;
      //   filter.SortFields = listPagination.sortField;
      //   filter.SortOrder = listPagination.sortOrder;
      // }

      this.listState.isPaginatedResultValue = true;

      // config.apis.forEach((api) => {
      switch (config.api) {
        case 'listingInBoundingBox':
          this.getListingsInBoundingBox(boundingBox, filter, listPagination, 'list');
          break;

        case 'propertiesInBoundingBox':
          this.getPropertiesInBoundingBox(boundingBox, filter, listPagination, 'list');
          break;

        case 'propertiesByGeo':
          this.getPropertiesByGeo(search, filter, listPagination, 'list');
          break;
      }
      // });
    });
  }

  getClustersInBoundingBox(boundingBox, filters, zoom) {
    this.propertyState.searchApiProgressValue = true;
    this.publicRecordApi
      .getClustersInBoundingBoxWithFilters({ boundingBox, filters, zoom: parseInt(zoom) - 3 })
      .pipe(
        debounceTime(100),
        take(1),
        finalize(() => {
          this.propertyState.searchApiProgressValue = false;
        })
      )
      .subscribe((res) => {
        if (!res || !res.data || !res.data.Records || !res.data.Records.length) {
          if (res.status != 'ZOOM_LIMIT_REACHED') this.propertyState.mapClusterSetValue = null;
          this.propertyState.noPropertyViewValue = res.status;
        } else {
          // this.propertyState.boundingBoxClustersValue = res.data.Records as ClusteredProperty[];
          this.propertyState.mapPropertySetValue = null;
          this.propertyState.mapClusterSetValue = this.generateMapClusterSetFromPropertyList(res.data.Records);
        }
      });
  }

  getListingsInBoundingBox(boundingBox, filters, pagination, updateStateList) {
    if (updateStateList.includes('map')) {
      pagination.pageNumber = 1;
      pagination.pageSize = 20000;
      this.listState.isPaginatedResultValue = false;
      this.propertyState.searchApiProgressValue = true;
    }
    if (updateStateList.includes('list')) this.propertyState.paginationApiProgressValue = true;
    this.publicRecordApi
      .getListingsInBoundingBoxWithFilters({ boundingBox, filters, ...pagination })
      .pipe(
        debounceTime(100),
        take(1),
        finalize(() => {
          if (updateStateList.includes('map')) this.propertyState.searchApiProgressValue = false;
          if (updateStateList.includes('list')) this.propertyState.paginationApiProgressValue = false;
        })
      )
      .subscribe((res) => {
        if (!res || !res.data || !res.data.Records || !res.data.Records.length) {
          this.updatePropertyState(null, updateStateList);
          this.propertyState.propertyCountValue = {
            totalRecord: null,
            receivedRecord: null,
          };
          this.propertyState.noPropertyViewValue = res.status;
        } else {
          const expandedResponse = this.expandMinifiedResponseFromApi(res.data.FieldNameMapping, res.data.Records);
          // this.propertyState.boundingBoxListingsValue = expandedResponse as PartialProperty[];
          this.updatePropertyState(expandedResponse, updateStateList);
          this.propertyState.propertyCountValue = {
            totalRecord: res.data.RecordCount,
            receivedRecord: expandedResponse.length,
          };
        }
      });
  }

  getPropertiesInBoundingBox(boundingBox, filters, pagination, updateStateList) {
    // params.MaxPropertyLimit = 20000;
    if (updateStateList.includes('map')) {
      pagination.pageNumber = 1;
      pagination.pageSize = 20000;
      this.listState.isPaginatedResultValue = false;
      this.propertyState.searchApiProgressValue = true;

      // An Immediate Fix for Sorting in this API.
      // TODO: Support sorting list from backend
      // delete params.SortFields;
      // delete params.SortOrder;
    }
    if (updateStateList.includes('list')) this.propertyState.paginationApiProgressValue = true;
    this.publicRecordApi
      .getPropertiesInBoundingBoxWithFilters({ boundingBox, filters, ...pagination })
      .pipe(
        debounceTime(100),
        take(1),
        finalize(() => {
          if (updateStateList.includes('map')) this.propertyState.searchApiProgressValue = false;
          if (updateStateList.includes('list')) this.propertyState.paginationApiProgressValue = false;
        })
      )
      .subscribe((res) => {
        if (!res || !res.data || !res.data.Records || !res.data.Records.length) {
          this.updatePropertyState(null, updateStateList);
          this.propertyState.propertyCountValue = {
            totalRecord: null,
            receivedRecord: null,
          };
          this.propertyState.noPropertyViewValue = res.status;
        } else {
          const expandedResponse = this.expandMinifiedResponseFromApi(res.data.FieldNameMapping, res.data.Records);
          // this.propertyState.boundingBoxPropertiesValue = expandedResponse as PartialProperty[];
          this.updatePropertyState(expandedResponse, updateStateList);
          this.propertyState.propertyCountValue = {
            totalRecord: res.data.RecordCount,
            receivedRecord: expandedResponse.length,
          };
        }
      });
  }

  getPropertiesByGeo(search, filters, pagination, updateStateList) {
    // params.MaxPropertyLimit = 20000;
    this.publicRecordApi
      .getPropertiesByGeo({ search, filters, ...pagination })
      .pipe(
        debounceTime(100),
        take(1),
        finalize(() => {
          if (updateStateList.includes('list')) this.propertyState.paginationApiProgressValue = false;
        })
      )
      .subscribe((res) => {
        if (!res || !res.data || !res.data.Records || !res.data.Records.length) {
          this.updatePropertyState(null, updateStateList);
          this.propertyState.propertyCountValue = {
            totalRecord: null,
            receivedRecord: null,
          };
          this.propertyState.noPropertyViewValue = res.status;
        } else {
          const expandedResponse = this.expandMinifiedResponseFromApi(res.data.FieldNameMapping, res.data.Records);
          // this.propertyState.geoPropertiesValue = expandedResponse as PartialProperty[];
          this.updatePropertyState(expandedResponse, updateStateList);
          this.propertyState.propertyCountValue = {
            totalRecord: res.data.RecordCount,
            receivedRecord: expandedResponse.length,
          };
        }
      });
  }

  getAllPropertiesInBoundingBox(boundingBox, filters) {
    boundingBox.MaxPropertyLimit = 20000;
    boundingBox.PageSize = 20000;
    this.propertyState.searchApiProgressValue = true;
    this.propertyState.paginationApiProgressValue = true;
    let filteredPropertyApi: Observable<any>;
    if (this.getCategoryFromFilter(filters) == 'listing') {
      filteredPropertyApi = this.publicRecordApi.getListingsInBoundingBoxWithFilters({ boundingBox, filters });
    } else {
      filteredPropertyApi = this.publicRecordApi.getPropertiesInBoundingBoxWithFilters({ ...boundingBox, ...filters });
    }

    forkJoin({
      allProperties: this.publicRecordApi.getPropertiesInBoundingBoxWithFilters(boundingBox),
      filteredProperties: filteredPropertyApi,
    })
      .pipe(
        take(1),
        catchError(() => EMPTY),
        finalize(() => {
          this.propertyState.searchApiProgressValue = false;
          this.propertyState.paginationApiProgressValue = false;
        })
      )
      .subscribe(({ allProperties, filteredProperties }) => {
        if (!allProperties?.data?.Records?.length && !filteredProperties?.data?.Records?.length) {
          this.propertyState.mapClusterSetValue = null;
          this.propertyState.mapPropertySetValue = null;
          this.propertyState.listPropertySetValue = null;
          this.updatePropertyState(null, ['map', 'list']);
          this.propertyState.propertyCountValue = {
            totalRecord: null,
            receivedRecord: null,
          };
          this.propertyState.noPropertyViewValue = filteredProperties?.status;
        } else {
          let expandedAllPropertyResponse = [];
          let expandedFilteredPropertiesResponse = [];

          if (allProperties?.data?.Records?.length)
            expandedAllPropertyResponse = this.expandMinifiedResponseFromApi(
              allProperties.data.FieldNameMapping,
              allProperties.data.Records
            );

          if (filteredProperties?.data?.Records?.length)
            expandedFilteredPropertiesResponse = this.expandMinifiedResponseFromApi(
              filteredProperties.data.FieldNameMapping,
              filteredProperties.data.Records
            );

          this.updatePropertyState(expandedFilteredPropertiesResponse, ['list']);
          const allPropertySet = this.generateMapPropertySetFromPropertyList(expandedAllPropertyResponse);
          const filteredPropertySet = this.generateMapPropertySetFromPropertyList(expandedFilteredPropertiesResponse);
          const mergedMapSet = this.mergeFilteredAndNonFilteredPropertiesSet(filteredPropertySet, allPropertySet);
          this.propertyState.mapPropertySetValue = mergedMapSet;
          const mergedListSet = this.mergeFilteredAndNonFilteredPropertiesArray(
            expandedAllPropertyResponse,
            expandedFilteredPropertiesResponse
          );
          this.propertyState.listPropertySetValue = mergedListSet;
          this.propertyState.propertyCountValue = {
            totalRecord: filteredProperties?.data?.RecordCount || 0,
            receivedRecord: expandedFilteredPropertiesResponse.length || 0,
          };
        }
      });
  }

  /**
   * Expands the minified response received from the API using the field and record mapping
   */
  expandMinifiedResponseFromApi(
    fields: { [key: string]: string },
    recordList: Array<any>
  ): PartialProperty[] | ClusteredProperty[] {
    const invertedFilter = {};
    let expandedList = [];

    if (fields && Object.keys(fields)?.length && recordList?.length) {
      Object.keys(fields).forEach((key) => {
        invertedFilter[fields[key]] = key;
      });

      expandedList = recordList.map((record) => {
        const expandedRecord = {};
        Object.keys(record).forEach((key) => {
          expandedRecord[invertedFilter[key]] = record[key];
        });
        return expandedRecord;
      });
    }
    return expandedList;
  }

  isValidView(config: Array<string>, value: string) {
    return !config || !config.length || config.includes(value);
  }
  isValidMode(config: boolean, value: boolean) {
    return config === null || config == value;
  }
  isValidZoom(minZoom: number, maxZoom: number, value: number) {
    return (!minZoom || minZoom <= value) && (!maxZoom || maxZoom >= value);
  }
  isValidCategory(config: Array<string>, filterValue: Json) {
    if (!config || !config.length || config.includes('nonFiltered')) return true;
    if (!Object.keys(filterValue).includes('soldWithin') && !Object.keys(filterValue).includes('offMktWithin')) {
      return config.includes('listing');
    } else {
      return config.includes('nonListing');
    }
  }

  getCategoryFromFilter(filterValue): string {
    if (Object.keys(filterValue).includes('isForSale')) {
      return 'listing';
    } else {
      return 'nonListing';
    }
  }

  /**
   * Update the respective propertyStates based on the PublicRecordApi configuration
   */
  updatePropertyState(propertyList, stateList: Array<string>) {
    if (stateList.includes('map')) {
      if (propertyList) {
        const mapPropertySet = this.generateMapPropertySetFromPropertyList(propertyList);
        this.propertyState.mapPropertySetValue = mapPropertySet;
      } else {
        this.propertyState.mapPropertySetValue = null;
        this.propertyState.mapClusterSetValue = null;
      }
    }
    if (stateList.includes('list')) {
      if (propertyList) {
        const listPropertySet = this.generateListPropertySetFromPropertyList(propertyList);
        this.propertyState.listPropertySetValue = listPropertySet;
      } else {
        this.propertyState.listPropertySetValue = null;
      }
    }
  }

  /**
   * Generates a property set from the formatted API responses that will be used to generate
   * the cluster marker set.
   */
  generateMapClusterSetFromPropertyList(propertyList: ClusteredProperty[]): MapCluster {
    const mapClusterSet: MapCluster = {};
    propertyList.forEach((property) => {
      mapClusterSet[`${property.Latitude}|${property.Longitude}`] = property;
    });
    return mapClusterSet;
  }

  /**
   * Generates a property set from the formatted API responses that will be used to generate
   * the property marker set.
   */
  generateMapPropertySetFromPropertyList(propertyList: PartialProperty[]): MapProperty {
    const mapPropertySet: MapProperty = {};
    const favoritePMXPropertyIdList = this.favoriteState.favoritePropertyIdListValue || [];

    propertyList.forEach((property) => {
      property.filterStyleClass = this.getMarkerClassForProperty(property);
      property.isFavorite = favoritePMXPropertyIdList.includes(property.PMXPropertyId);
      if (mapPropertySet[`${property.Latitude}|${property.Longitude}`]) {
        mapPropertySet[`${property.Latitude}|${property.Longitude}`].propertyCount++;
        mapPropertySet[`${property.Latitude}|${property.Longitude}`].propertyList.push(property);
      } else {
        mapPropertySet[`${property.Latitude}|${property.Longitude}`] = {
          address: property.Address,
          propertyCount: 1,
          propertyPrice: property.Lst_ListPrice || property.CurrentAVMValue,
          latitude: property.Latitude,
          longitude: property.Longitude,
          filterStyleClass: property.filterStyleClass,
          propertyList: [property],
        };
      }
    });
    return mapPropertySet;
  }

  /**
   * Generates a property list from the formatted API responses that will be used to populate
   * the list-view or card view.
   */
  generateListPropertySetFromPropertyList(propertyList: PartialProperty[]): PartialProperty[] {
    const favoritePMXPropertyIdList = this.favoriteState.favoritePropertyIdListValue || [];

    return propertyList.map((property) => {
      property.filterStyleClass = this.getMarkerClassForProperty(property);
      property.isFavorite = favoritePMXPropertyIdList.includes(property.PMXPropertyId);
      return property;
    });
  }

  // Implements the logic to prioritize the marker color
  getMarkerClassForProperty(property: PartialProperty): string {
    let propertyFiltered = this.filterService.isBasePropertyFiltered(property);
    if (propertyFiltered.status) {
      if (propertyFiltered.filter.includes('distressed')) return 'distressed';
      if (propertyFiltered.filter.includes('hud')) return 'hud';
      if (propertyFiltered.filter.includes('listing')) return 'listing';
      if (propertyFiltered.filter.includes('sold')) return 'sold';
      return 'filtered';
    }
    return 'nonFiltered';
  }

  mergeFilteredAndNonFilteredPropertiesSet(filtered: MapProperty, nonFiltered: MapProperty) {
    if (nonFiltered && filtered) {
      const nonFilteredKeyList = Object.keys(nonFiltered);
      Object.keys(filtered).forEach((filteredKey) => {
        if (nonFilteredKeyList.includes(filteredKey)) {
          nonFiltered[filteredKey].propertyCount = filtered[filteredKey].propertyCount;
          nonFiltered[filteredKey].propertyPrice = filtered[filteredKey].propertyPrice;
          nonFiltered[filteredKey].filterStyleClass = filtered[filteredKey].filterStyleClass;
          nonFiltered[filteredKey].propertyList.unshift(...filtered[filteredKey].propertyList);
        } else {
          nonFiltered[filteredKey] = filtered[filteredKey];
        }
      });
    }
    return nonFiltered;
  }
  mergeFilteredAndNonFilteredPropertiesArray(filtered, nonFiltered) {
    const nonFilteredPMXIds = new Set(nonFiltered.map((item) => item.PMXPropertyId));
    // Filter out items from the filtered array that have PMXPropertyId already in nonFiltered
    const uniqueFiltered = filtered.filter((item) => !nonFilteredPMXIds.has(item.PMXPropertyId));
    // Merge the uniqueFiltered items into the nonFiltered array
    const mergedArray = [...nonFiltered, ...uniqueFiltered];

    return mergedArray;
  }
}
