import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, take, takeUntil } from 'rxjs/operators';
import { AppState } from 'src/app/app.state';
import { AgentContactService } from 'src/app/workspace/service/agent-contact.service';
import { AgentContactState } from 'src/app/workspace/state/agent-contact.state';
import { PaymentCalculatorState } from 'src/app/workspace/state/payment-calculator.state';
import { PropertyState } from 'src/app/workspace/state/property.state';
import { InsuranceAmountInput } from 'src/app/_shared/interface/deal-analysis.interface';
import { ActivityLoggerService } from 'src/app/_shared/service/activity-logger.service';
import { DealAnalysisService } from 'src/app/_shared/service/deal-analysis.service';
import { UserState } from 'src/app/_shared/state/user.state';
import constant from 'src/constant.json';

interface MortgageType {
  value: Number;
  viewValue: string;
}

@Component({
  selector: 'app-payment-calculator',
  templateUrl: './payment-calculator.component.html',
  styleUrls: ['./payment-calculator.component.scss'],
})
export class PaymentCalculatorComponent implements OnInit, OnDestroy {
  // @Input('homePrice') homePrice: number = 0;
  // @Input('propertyTax') propertyTax: number = 0;
  // @Input('hoaFees') hoaFees: Array<{ fee: number; recurrence: string }>;
  // @Input('landValue') landValue: number;
  // @Input('state') state: string;

  Window = window;

  mortgageTypes: Array<MortgageType> = [
    { viewValue: '30-Year Fixed', value: 30 },
    { viewValue: '20-Year Fixed', value: 20 },
    { viewValue: '15-Year Fixed', value: 15 },
    { viewValue: '10-Year Fixed', value: 10 },
  ];

  mortgagePaymentMonthly = 0;
  totalPaymentMonthly = 0;
  chartConfig: any = {};
  chartData: any;
  propertyTaxValue = 0;
  insuranceValue = 0;
  hoaFeeValue = 0;
  loanApplicationUrlRequestInProgress: boolean;
  paymentForm: FormGroup = this.formBuilder.group({
    homePrice: [],
    downPayment: [],
    downPaymentRate: [20],
    mortgageType: [this.mortgageTypes[0]],
    interestRate: [3.5],
    propertyTax: [0],
    propertyTaxRate: [0],
    insurance: [],
    insuranceRate: [],
    hoaFee: [],
  });

  formValues: any;
  functionCalled = true;
  onDestroyNotifier$ = new Subject();

  constructor(
    private formBuilder: FormBuilder,
    private dealAnalysisService: DealAnalysisService,
    private paymentCalculatorState: PaymentCalculatorState,
    public appState: AppState,
    private agentContactState: AgentContactState,
    public agentContactService: AgentContactService,
    private _changeDetectorRef: ChangeDetectorRef,
    private activityLoggerService: ActivityLoggerService,
    private userState: UserState,
    private propertyState: PropertyState
  ) {}

  ngOnInit(): void {
    this.updateChartData();
    this.paymentCalculatorState.homePrice$.pipe(takeUntil(this.onDestroyNotifier$)).subscribe((res) => {
      res = isFinite(res) ? Math.round(Number(res)) : 0;
      this.homePriceCtrl.patchValue(res);
      this.downPaymentCtrl.setValue(res ? (this.downPaymentRateCtrl.value / 100) * res : 0);
      this.paymentCalculatorState.propertyTax$.pipe(takeUntil(this.onDestroyNotifier$)).subscribe((propertTax) => {
        propertTax = isFinite(propertTax) ? Math.round(Number(propertTax)) : 0;
        this.propertyTaxValue = propertTax;
        this.propertyTaxCtrl.setValue(propertTax);
        this.propertyTaxRateCtrl.setValue(
          this.homePriceCtrl.value ? Math.round((propertTax / this.homePriceCtrl.value) * 10000) / 100 : 0,
          {
            emitEvent: false,
          }
        );
      });
      this.insuranceCtrl.setValue(
        this.getInsuranceAmount(
          res,
          this.paymentCalculatorState.landValue,
          this.paymentCalculatorState.state,
          this.insuranceCtrl.value
        )
      );
      this.insuranceValue = this.getInsuranceAmount(
        res,
        this.paymentCalculatorState.landValue,
        this.paymentCalculatorState.state,
        this.insuranceValue
      );
    });
    this.paymentCalculatorState.hoaFees$.pipe(takeUntil(this.onDestroyNotifier$)).subscribe((res: any) => {
      if (res && res[0]) {
        this.hoaFeeValue = res[0];
        this.hoaFeeCtrl.setValue(res[0]);
      }
    });

    this.paymentCalculatorState.landValue$.pipe(takeUntil(this.onDestroyNotifier$)).subscribe((res) => {
      res = isFinite(res) ? Math.round(Number(res)) : 0;
      this.insuranceValue = this.getInsuranceAmount(
        this.paymentCalculatorState.homePrice,
        res,
        this.paymentCalculatorState.state,
        this.insuranceValue
      );
      let insuranceAmt = this.getInsuranceAmount(
        this.paymentCalculatorState.homePrice,
        res,
        this.paymentCalculatorState.state,
        this.insuranceCtrl.value
      );
      this.insuranceCtrl.setValue(insuranceAmt);
      this.insuranceRateCtrl.setValue(
        this.insuranceCtrl.value ? Math.round((res / this.homePriceCtrl.value) * 10000) / 100 : 0,
        {
          emitEvent: false,
        }
      );
    });
    this.paymentCalculatorState.state$.pipe(takeUntil(this.onDestroyNotifier$)).subscribe((res) => {
      this.insuranceValue = this.getInsuranceAmount(
        this.paymentCalculatorState.homePrice,
        this.paymentCalculatorState.landValue,
        res,
        this.insuranceValue
      );
      this.insuranceCtrl.setValue(
        this.getInsuranceAmount(
          this.paymentCalculatorState.homePrice,
          this.paymentCalculatorState.landValue,
          res,
          this.insuranceCtrl.value
        )
      );
    });

    setTimeout(() => {
      this.formValues = {
        homePrice: this.paymentForm.get('homePrice').value,
        downPayment: this.paymentForm.get('downPayment').value,
        downPaymentRate: this.paymentForm.get('downPaymentRate').value,
        mortgageType: this.paymentForm.get('mortgageType').value,
        interestRate: this.paymentForm.get('interestRate').value,
      };
    }, 5000);

    this.paymentForm.valueChanges.pipe(debounceTime(100)).subscribe((res) => {
      const { monthlyMortgage } = this.dealAnalysisService.computeMonthlyMortgagePayment({
        uInterestRate: this.interestRateCtrl.value / 100,
        uLoanAmount: this.homePriceCtrl.value - this.downPaymentCtrl.value,
        uLoanTerm: this.mortgageTypeCtrl.value,
      });
      let monthlyMortgagePayment = isFinite(monthlyMortgage) ? Math.round(Number(monthlyMortgage)) : 0;
      this.mortgagePaymentMonthly = monthlyMortgagePayment;
      this.totalPaymentMonthly =
        this.mortgagePaymentMonthly + this.propertyTaxValue + this.insuranceValue + this.hoaFeeValue;

      // this.totalPaymentMonthly =
      //   this.mortgagePaymentMonthly + this.propertyTaxCtrl.value + this.insuranceCtrl.value + this.hoaFeeCtrl.value;

      this.updateChartData();
    });

    this.paymentForm.valueChanges.pipe(debounceTime(5000)).subscribe((res) => {
      if (
        (this.formValues.homeprice !== 0 && res.homePrice !== this.formValues.homePrice) ||
        res.downPayment !== this.formValues.downPayment ||
        res.downPaymentRate !== this.formValues.downPaymentRate ||
        res.mortgageType !== this.formValues.mortgageType ||
        res.interestRate !== this.formValues.interestRate
      ) {
        this.logger();
      }
    });

    this.subFormChange();
  }

  ngAfterViewInit() {
    // let address = this.paymentCalculatorState.propertyAddress;
  }

  logger() {
    if (this.appState.authTokenValue?.idToken && this.userState.userDetails?.roles?.includes('investor')) {
      if (this.functionCalled) {
        let address = {
          city: this.propertyState.activePropertyDetailsValue['basic'].City,
          state: this.propertyState.activePropertyDetailsValue['basic'].StateOrProvince,
          street: this.propertyState.activePropertyDetailsValue['basic'].StreetAddress,
          zip: this.propertyState.activePropertyDetailsValue['basic'].PostalCode,
        };
        this.logUserActivity(
          constant.LOGGER.propertyDetails.category,
          constant.LOGGER.propertyDetails.action.paymentCalculator.key,
          this.propertyState.activePropertyDetailsValue['basic'].PMXPropertyId,
          address
        );
        this.functionCalled = false;
      }
    }
  }

  // ngOnChanges(changes: SimpleChanges) {
  //   if (this.paymentForm) {
  //     if (changes.homePrice && changes.homePrice.currentValue !== changes.homePrice.previousValue) {
  //       this.homePriceCtrl.patchValue(changes.homePrice.currentValue);
  //       this.downPaymentRateCtrl.patchValue(20);
  //       this.insuranceCtrl.setValue(
  //         this.getInsuranceAmount(this.homePrice, this.landValue, this.state, this.insuranceCtrl.value)
  //       );
  //     }

  //     if (changes.propertyTax && changes.propertyTax.currentValue !== changes.propertyTax.previousValue)
  //       this.propertyTaxCtrl.setValue(changes.propertyTax.currentValue);

  //     if (changes.hoaFees && changes.hoaFees.currentValue !== changes.hoaFees.previousValue) {
  //       this.hoaFeeCtrl.setValue(this.getHoaFee(changes.hoaFees.currentValue));
  //     }

  //     if (changes.landValue && changes.landValue.currentValue !== changes.insuranceCtrllandValue.previousValue) {
  //       this.insuranceCtrl.setValue(
  //         this.getInsuranceAmount(this.homePrice, this.landValue, this.state, this.insuranceCtrl.value)
  //       );
  //     }

  //     if (changes.state && changes.state.currentValue !== changes.state.previousValue) {
  //       this.insuranceCtrl.setValue(
  //         this.getInsuranceAmount(this.homePrice, this.landValue, this.state, this.insuranceCtrl.value)
  //       );
  //     }
  //     this.updateChartData();
  //   }
  // }

  ngOnDestroy() {
    this.onDestroyNotifier$.next();
    this.onDestroyNotifier$.complete();
    this.mortgagePaymentMonthly = 0;
    this.totalPaymentMonthly = 0;
    this.chartConfig = {};
    this.chartData = [];
    this.propertyTaxValue = 0;
    this.insuranceValue = 0;
    this.hoaFeeValue = 0;
  }

  logUserActivity(category, action, PMXPropertyId, address) {
    let loggerParams = {
      category: category,
      action: action,
    };
    let inputData = {
      PMXPropertyId: PMXPropertyId,
      address: address,
    };
    this.activityLoggerService.logActivity(loggerParams, inputData);
  }

  preApprovedAction() {
    this.agentContactService.preApprovedAction();
    this.agentContactState.loanApplicationUrlRequestStatus$
      .pipe(take(2), takeUntil(this.onDestroyNotifier$))
      .subscribe((res) => {
        this.loanApplicationUrlRequestInProgress = res;
      });
    this.loanApplicationUrlRequestInProgress = false;
  }

  subFormChange() {
    this.paymentForm.patchValue({ mortgageType: this.mortgageTypes[0].value });

    this.homePriceCtrl.valueChanges.pipe(takeUntil(this.onDestroyNotifier$)).subscribe((res) => {
      this.downPaymentCtrl.setValue(res ? (this.downPaymentRateCtrl.value / 100) * res : 0);
      this.propertyTaxCtrl.setValue(res ? (this.propertyTaxRateCtrl.value / 100) * res : 0, { emitEvent: false });
      this.propertyTaxValue = res ? (this.propertyTaxRateCtrl.value / 100) * res : 0;
    });

    this.downPaymentCtrl.valueChanges.pipe(takeUntil(this.onDestroyNotifier$)).subscribe((res) => {
      this.downPaymentRateCtrl.setValue(
        this.homePriceCtrl.value ? Math.round((res / this.homePriceCtrl.value) * 10000) / 100 : 0,
        {
          emitEvent: false,
        }
      );
    });

    this.downPaymentRateCtrl.valueChanges.pipe(takeUntil(this.onDestroyNotifier$)).subscribe((res) => {
      this.downPaymentCtrl.setValue(this.homePriceCtrl.value ? (res / 100) * this.homePriceCtrl.value : 0);
    });

    this.propertyTaxCtrl.valueChanges.pipe(takeUntil(this.onDestroyNotifier$)).subscribe((res) => {
      this.propertyTaxValue = res;
      this.propertyTaxRateCtrl.setValue(
        this.homePriceCtrl.value ? Math.round((res / this.homePriceCtrl.value) * 10000) / 100 : 0,
        {
          emitEvent: false,
        }
      );
    });

    this.propertyTaxRateCtrl.valueChanges.pipe(takeUntil(this.onDestroyNotifier$)).subscribe((res) => {
      this.propertyTaxValue = this.homePriceCtrl.value ? (res / 100) * this.homePriceCtrl.value : 0;
      this.propertyTaxCtrl.setValue(this.homePriceCtrl.value ? (res / 100) * this.homePriceCtrl.value : 0, {
        emitEvent: false,
      });
    });

    this.insuranceCtrl.valueChanges.pipe(takeUntil(this.onDestroyNotifier$)).subscribe((res) => {
      this.insuranceValue = res;
      this.insuranceRateCtrl.setValue(
        this.homePriceCtrl.value ? Math.round((res / this.homePriceCtrl.value) * 10000) / 100 : 0,
        {
          emitEvent: false,
        }
      );
    });

    this.insuranceRateCtrl.valueChanges.pipe(takeUntil(this.onDestroyNotifier$)).subscribe((res) => {
      this.insuranceValue = this.homePriceCtrl.value ? (res / 100) * this.homePriceCtrl.value : 0;
      this.insuranceCtrl.setValue(this.homePriceCtrl.value ? (res / 100) * this.homePriceCtrl.value : 0, {
        emitEvent: false,
      });
    });

    this.hoaFeeCtrl.valueChanges.pipe(takeUntil(this.onDestroyNotifier$)).subscribe((res) => {
      this.hoaFeeValue = res;
      this.hoaFeeCtrl.setValue(res ? res : 0, {
        emitEvent: false,
      });
    });
  }

  getInsuranceAmount(uFairMarketValue, uLandValue, uState, uInsuranceAmount): number {
    if (uLandValue < uFairMarketValue) {
      const insuranceAmountInput: InsuranceAmountInput = {
        uInsuranceAmount: 0,
        uLandValue: uLandValue,
        uFairMarketValue: uFairMarketValue,
        uFairMarketValueYoYRatio: 0,
        uState: uState,
        uYear: 1,
        uPurchasePrice: uFairMarketValue,
      };
      const { insurance } = this.dealAnalysisService.computeInsuranceAmount(insuranceAmountInput);
      return insurance / 12;
    } else {
      let insurance = 0;
      return insurance;
    }
  }

  updateChartData() {
    this.chartConfig = {
      type: 'donut',
      showLegend: false,
      series: [
        {
          name: 'Payment',
          type: 'pie',
          data: [
            {
              name: 'Principal & Interest',
              y: this.mortgagePaymentMonthly,
              key: 'principalInterest',
              color: '#ff7b7b',
            },
            { name: 'Property Taxes', y: this.propertyTaxValue, key: 'taxes', color: '#ffc27d' },
            { name: 'Home Insurance', y: this.insuranceValue, key: 'insurance', color: '#99d671' },
            { name: 'HOA Fee', y: this.hoaFeeValue, key: 'hoa', color: '#56a5f5' },
          ],
          innerSize: '60%',
        },
      ],
    };
    this.chartData = [
      {
        name: 'Payment',
        type: 'pie',
        data: [
          {
            name: 'Principal & Interest',
            y: this.mortgagePaymentMonthly,
            key: 'principalInterest',
            color: '#ff7b7b',
          },
          { name: 'Property Taxes', y: this.propertyTaxValue, key: 'taxes', color: '#ffc27d' },
          { name: 'Home Insurance', y: this.insuranceValue, key: 'insurance', color: '#99d671' },
          { name: 'HOA Fee', y: this.hoaFeeValue, key: 'hoa', color: '#56a5f5' },
        ],
        innerSize: '60%',
        title: this.totalPaymentMonthly,
      },
    ];
    this._changeDetectorRef.detectChanges();
  }

  get homePriceCtrl() {
    return this.paymentForm.get('homePrice');
  }
  get downPaymentCtrl() {
    return this.paymentForm.get('downPayment');
  }
  get downPaymentRateCtrl() {
    return this.paymentForm.get('downPaymentRate');
  }
  get mortgageTypeCtrl() {
    return this.paymentForm.get('mortgageType');
  }
  get interestRateCtrl() {
    return this.paymentForm.get('interestRate');
  }
  get propertyTaxCtrl() {
    return this.paymentForm.get('propertyTax');
  }
  get propertyTaxRateCtrl() {
    return this.paymentForm.get('propertyTaxRate');
  }
  get insuranceCtrl() {
    return this.paymentForm.get('insurance');
  }
  get insuranceRateCtrl() {
    return this.paymentForm.get('insuranceRate');
  }
  get hoaFeeCtrl() {
    return this.paymentForm.get('hoaFee');
  }
}
