import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CmaState {
  private openCreateReportSheetSub$ = new BehaviorSubject<boolean>(false);
  openCreateReportSheet = this.openCreateReportSheetSub$.asObservable();

  public get openCreateReportSheetValue(): boolean {
    return this.openCreateReportSheetSub$.value;
  }
  public set openCreateReportSheetValue(status: boolean) {
    this.openCreateReportSheetSub$.next(status);
  }

  private searchAddressSub$ = new BehaviorSubject<any>(null);
  searchAddress$ = this.searchAddressSub$.asObservable();
  public get searchAddressValue() {
    return this.searchAddressSub$.getValue();
  }
  public set searchAddressValue(address: any) {
    this.searchAddressSub$.next(address);
  }

  private listPropertySetSub$ = new BehaviorSubject<any>(null);
  listPropertySet$ = this.listPropertySetSub$.asObservable();
  public get listPropertySetValue(): any {
    return this.listPropertySetSub$.value;
  }
  public set listPropertySetValue(propertyList: any) {
    this.listPropertySetSub$.next(propertyList);
  }

  private searchPropertyInput$ = new BehaviorSubject<any>(null);
  searchPropertyInput = this.searchPropertyInput$.asObservable();
  public get searchPropertyValue(): any {
    return this.searchPropertyInput$.value;
  }
  public set searchPropertyValue(propertyList: any) {
    this.searchPropertyInput$.next(propertyList);
  }

  private listPropertySetCount$ = new BehaviorSubject<any>(null);
  listPropertySetCountValue = this.listPropertySetCount$.asObservable();
  public get listPropertySetCount(): any {
    return this.listPropertySetCount$.value;
  }
  public set listPropertySetCount(propertyList: any) {
    this.listPropertySetCount$.next(propertyList);
  }

  private isLoadingCreateReport$ = new BehaviorSubject<boolean>(false);
  isLoadingCreateReportValue = this.isLoadingCreateReport$.asObservable();
  public get isLoadingCreateReport(): boolean {
    return this.isLoadingCreateReport$.value;
  }
  public set isLoadingCreateReport(status: boolean) {
    this.isLoadingCreateReport$.next(status);
  }

  private propertyDetailsCardInput$ = new BehaviorSubject<any>(null);
  propertyDetailsCardInput = this.propertyDetailsCardInput$.asObservable();
  public get propertyDetailsCardValue(): any {
    return this.propertyDetailsCardInput$.value;
  }
  public set propertyDetailsCardValue(propertyList: any) {
    this.propertyDetailsCardInput$.next(propertyList);
  }

  private showPropertyDetailsCardSub$ = new BehaviorSubject<boolean>(false);
  showPropertyDetailsCard$ = this.showPropertyDetailsCardSub$.asObservable();
  public get showPropertyDetailsCardValue(): boolean {
    return this.showPropertyDetailsCardSub$.value;
  }
  public set showPropertyDetailsCardValue(status: boolean) {
    this.showPropertyDetailsCardSub$.next(status);
  }

  private allPropertyReportsSub$ = new BehaviorSubject<boolean>(false);
  showPropertyReports$ = this.allPropertyReportsSub$.asObservable();
  public get allPropertyReportsValue(): boolean {
    return this.allPropertyReportsSub$.value;
  }
  public set allPropertyReportsValue(status: boolean) {
    this.allPropertyReportsSub$.next(status);
  }


  private cmaReportsSub$ = new BehaviorSubject<Array<any>>([]);
  cmaReports = this.cmaReportsSub$.asObservable();
  public get cmaReportsValue(): Array<any> {
    return this.cmaReportsSub$.value;
  }
  public set cmaReportsValue(data: Array<any>) {
    this.cmaReportsSub$.next(data);
  }

  private isLoadingReport$ = new BehaviorSubject<boolean>(false);
  isLoadingReportValue = this.isLoadingReport$.asObservable();
  public get isLoadingReport(): boolean {
    return this.isLoadingReport$.value;
  }
  public set isLoadingReport(status: boolean) {
    this.isLoadingReport$.next(status);
  }

  private loadingMessage$ = new BehaviorSubject<string>('Loading...');
  loadingMessageValue = this.isLoadingReport$.asObservable();
  public get loadingMessage(): string {
    return this.loadingMessage$.value;
  }
  public set loadingMessage(status: string) {
    this.loadingMessage$.next(status);
  }

  private filterValueSub$ = new BehaviorSubject<any>(null);
  filterValue = this.filterValueSub$.asObservable();
  public get filterValValue() {
    return this.filterValueSub$.getValue();
  }
  public set filterValValue(address: any) {
    this.filterValueSub$.next(address);
  }



  private cmaSellerPropertySub$ = new BehaviorSubject<any>(null);
  sellerProperty$ = this.cmaSellerPropertySub$.asObservable();
  public get sellerPropertyValue() {
    return this.cmaSellerPropertySub$.value;
  }
  public set sellerPropertyValue(data: any) {
    this.cmaSellerPropertySub$.next(data);
  }
}
